import React, { Component } from "react";
import { Storage } from "aws-amplify";
import $ from "jquery";
import Dropzone from "react-dropzone";
import { TimePicker, DatePicker } from "antd";
import moment from "moment";
import "antd/dist/antd.css";
import "../../antd_custom.css";
import axios from "axios";
import { DateFormate } from "../../utils/DataFormate";

export default class TimeCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFormat: "DD/MM/YYYY",
      format: "HH:mm",
      errormessage: "",
      isLoading: true,
      isUploading: false,
      previewLoading: false,
      onHold: false,
      SubView: "List",
      Update: false,
      ItemIndex: false,
      timecards: [],
      companies: [],
      formValid: false,
      CompanyValid: true,
      SubmitEmail: "",
      SubmitEmailValid: true,
      DeleteId: false,
      DeleteIndex: false,
      Preview: "",
      HideTravel: false,
      TravelFromHide: false,
      TravelToHide: false,
      InsertedId: "",

      Production: "",
      Activity: "",
      EmailAddress: "",
      UserGuid: false,
      Company: "",
      CompanyIndex: "0",
      CustomTimeCardFields: [],
      Dept: "",
      DeptValid: true,
      FileAttachments: [],
      EmailAttachments: [],
      EmailAttachmentsSize: 0, //size in MBs
      PayFrequency: "Weekly",
      PayEnding: "",
      PeriodEnding: "",
      PeriodEndingMoment: "",
      Position: "",
      PositionValid: true,
      Times: [],
      TotalHours: "0.00",
      Type: "Draft",

      DailyTimes: [],
      TimesCustomTimeFields: [],
      DayTimeCustomTimeFields: [],
      TimesTotalHours: "0.00",
      files: [],
      mrtFile: "default",

      PayasDefault: [
        { Flag: "CON", Value: "Worked" },
        { Flag: "NW", Value: "Not Worked" },
        { Flag: "SIC", Value: "Sick" },
        { Flag: "HOL", Value: "Holiday Pay" },
        { Flag: "TIL", Value: "Time in Lieu" },
        { Flag: "PUB", Value: "Public Holiday" },
        { Flag: "PHW", Value: "Pub Hol Worked" },
      ],

      Payas: [
        { Flag: "CON", Value: "Worked" },
        { Flag: "NW", Value: "Not Worked" },
        { Flag: "SIC", Value: "Sick" },
        { Flag: "HOL", Value: "Holiday Pay" },
        { Flag: "TIL", Value: "Time in Lieu" },
        { Flag: "PUB", Value: "Public Holiday" },
        { Flag: "PHW", Value: "Pub Hol Worked" },
      ],

      TotalMBDedDisabled: false,
      DailyTimesIndex: 0,
      DailyTimesCopyIndex: 0,
      DayTimeIndex: false,
      DayTimeCopyIndex: 0,
      DayTimeDateMoment: moment(),
      DayTimeDate: "",
      DayTimeDay: "",
      DayTimeFinish: moment("00:00", "HH:mm"),
      DayTimeFinishType: "00:00",
      DayTimeFinishMeal1: moment("00:00", "HH:mm"),
      DayTimeFinishMeal2: moment("00:00", "HH:mm"),
      DayTimeFinishMeal3: moment("00:00", "HH:mm"),
      DayTimeFinishMeal1Type: "00:00",
      DayTimeFinishMeal2Type: "00:00",
      DayTimeFinishMeal3Type: "00:00",
      DayTimeMB1ND: false,
      DayTimeMB2ND: false,
      DayTimeMB3ND: false,
      DayTimeNote: "",
      DayTimecustomNote: "",
      DayTimePayas: "0",
      DayTimeStart: moment("00:00", "HH:mm"),
      DayTimeStartType: "00:00",
      DayTimeStartMeal: moment("00:00", "HH:mm"),
      DayTimeStartMeal2: moment("00:00", "HH:mm"),
      DayTimeStartMeal3: moment("00:00", "HH:mm"),
      DayTimeStartMealType: "00:00",
      DayTimeStartMeal2Type: "00:00",
      DayTimeStartMeal3Type: "00:00",
      TimeStamp: "1523884860",
      DayTimeTotalHours: "0.00",
      DayTimeTotalMBDed: moment("00:00", "HH:mm"),
      DayTimeTotalMBDedType: "00:00",
      DayTimeTravel1: moment("00:00", "HH:mm"),
      DayTimeTravel2: moment("00:00", "HH:mm"),
      DayTimeTravel1Type: "00:00",
      DayTimeTravel2Type: "00:00",

      DayTimeMBDed1: "0.00",
      DayTimeMBDed2: "0.00",
      DayTimeMBDed3: "0.00",

      UserSignatureImage: false,
      UserSignatureUrl: false,
      SaveDayTimeError: "",
      inputReadOnly: true,
      allowEmpty: false,
      showNow: false,
      popupClassName: "timepicker",
      DayTimeCurrentIndex: false,

      timesheetImage: false,
      fileError: false,
      SysCoyGuid: false,
      minuteStep: localStorage.getItem("UserMinutsStep")
        ? parseInt(localStorage.getItem("UserMinutsStep"))
        : 1,
      TimeOption: localStorage.getItem("UserTimeOption")
        ? localStorage.getItem("UserTimeOption")
        : "DropList",
      calendarPeriodEndingMobile: false,
      calendarPeriodEndingWeb: false,
      timeSheetName: false,
      allowMealBreak: false, //
      disabledTypedSig: false, //
      requiredFeilds: {
        signature: true,
        department: false,
        position: false,
      },
      departments: [],
      ipv4: null,
      dt_api_url: "",
    };

    //this.validateForm = this.validateForm.bind(this);
    //this.handleChangeDayTimeFinish = this.handleChangeDayTimeFinish.bind(this);
    this.updateTotalHours = this.updateTotalHours.bind(this);
    this.disabledDate = this.disabledDate.bind(this);
  }

  async componentDidMount() {
    //console.log('abb sss: '+this.props.siteConfigs);
    if (this.props.location) {
      console.log("States =>", this.props.location);
    }
    this.setState({
      SubView: this.props.main.SubView,
      EmailAddress: localStorage.getItem("UserEmailAddress"),
      UserGuid: localStorage.getItem("UserGuid"),
    });

    try {
      const result = await this.timecards();
      //console.log("time cards=>", result.items);
      if (result.status) {
        this.setState({ timecards: result.result, isLoading: false }, () => {
          this.handleSortTimeCards();
        });
      }
    } catch (e) {
      console.log(e);
    }

    try {
      const result = await this.companies();
      //console.log('companies=>', result);
      if (result.status) {
        this.setState(
          { companies: result.result, items: result.result, isLoading: false },
          () => {
            if (
              this.state.companies.length > 0 &&
              this.state.SubView === "Add"
            ) {
              this.setDefaultCompany();
            }
          }
        );
        //this.setDefaultCompany();
      }
    } catch (e) {
      console.log("Get Companies: " + e);
    }
    /*
    try {
      var response = await this.props.postRequest(
        "/attachments/get-signature",
        {}
      );
      if (response.status) {
        axios
          .get(response.result.signature)
          .then((data) => {
            //console.log('Axios Get signature Result',data);
            this.setUserSignature(response.result.signature);
          })
          .catch((err) => {
            axios
              .get(response.result.typed_signature)
              .then((data) => {
                this.setUserSignature(response.result.typed_signature);
              })
              .catch((err) => {});
          });
      } else {
        console.log("/attachments/get-signature=>", response);
      }
    } catch (err) {
      console.log("/attachments/get-signature error=>", err);
    }*/

    //console.log('User Email: ', this.state.EmailAddress)
    $.getJSON("https://jsonip.com?callback=?", async (jsonip) => {
      this.setState({ ipv4: jsonip.ip });
    });

    try {
      var res = await this.props.getRequest("/config/get-dt-api-url");
      if (res.status) {
        this.setState({ dt_api_url: res.dt_api_url });
      }
    } catch (err) {
      console.log("/config/get-dt-api-url error=>", err);
    }
  }

  async getUserSignature() {
    try {
      var response = await this.props.postRequest(
        "/attachments/get-signature",
        {}
      );
      if (response.status) {
        try {
          await axios.get(response.result.signature);
          this.setUserSignature(response.result.signature);
          return response.result.signature;
        } catch (e) {
          if (this.state.disabledTypedSig) {
            return "";
          } else {
            try {
              await axios.get(response.result.typed_signature);
              this.setUserSignature(response.result.typed_signature);
              return response.result.typed_signature;
            } catch (e) {
              return "";
            }
          }
        }
      } else {
        return "";
      }
    } catch (err) {
      console.log("/attachments/get-signature error=>", err);
      return "";
    }
  }

  async setUserSignature(url) {
    let response = await fetch(url);
    let data = await response.blob();
    let metadata = {
      type: "image/jpeg",
    };
    let file = new File([data], "Signature.jpg", metadata);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    var that = this;

    reader.onload = function () {
      that.setState({ UserSignatureImage: reader.result });
    };
  }

  getDiff(start, end) {
    var startTimeMB1 = moment(start, "HH:mm");
    var endTimeMB1 = moment(end, "HH:mm");
    var duration = moment.duration(endTimeMB1.diff(startTimeMB1));
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var timeFormate =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);
    return timeFormate;
  }
  /*async setUserSignature(){
    API.post("users", "/signature/get-signature-image",{
      body:{
        "bucket":process.env.REACT_APP_S3_BUCKET,
        "key":"private/"+this.state.UserGuid+"/SignatureFile.jpg"
      }
    }).then(async (res)=>{
      //console.log('Get signature Result API',res);
      if(res.status){
        this.setState({ UserSignatureImage: "data:image/jpeg;base64,"+res.item});
      }
    }).catch(err=>{

      console.log("get signature error API=>",err);
      API.post("users", "/signature/get-signature-image",{
        body:{
          "bucket":process.env.REACT_APP_S3_BUCKET,
          "key":"private/"+this.state.UserGuid+"/SignatureFileTyped.jpg"
        }
      }).then(res=>{
        console.log('Get signature typed Result API',res);
        this.setState({ UserSignatureImage: "data:image/jpeg;base64,"+res.item});
      }).catch(err=>{
        console.log("get signature error API=>",err)
      });

    });
  }*/

  async handleSortTimeCards() {
    this.state.timecards.sort(function (a, b) {
      var nameA = a.PeriodEnding,
        nameB = b.PeriodEnding;
      if (nameA > nameB)
        //sort string descending
        return -1;
      if (nameA < nameB) return 1;
      return 0; //default return value (no sorting)
    });

    this.setState({ timecards: this.state.timecards });
  }

  /*getUser() {
    return API.get("users", `/users/${"HGJGJGJUYGJUGSJY"}`);
  }*/

  async onDrop(acceptedFiles, rejectedFiles) {
    if (rejectedFiles.length > 0) {
      this.setState({
        fileError: "Only jpg, png and pdf file types are allowed.",
      });
    } else {
      this.setState({ isLoading: true });
      this.setState({ fileError: false });

      var selected_files = this.state.files;
      //var EmailAttachments = [];
      acceptedFiles.forEach((file) => {
        //selected_files.push(file);
        var attachment = false;
        var ext = file.name.split(".").pop();
        var file_name =
          new Date().getTime() + "-" + file.name.replace(/ /g, "-");
        //var key = "documents/"+file_name;
        var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        //console.log(file_name); return false;
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          //console.log("base 64 on load=>", reader.result.replace(/^data:.+;base64,/, ''));
          attachment = {
            filename: file_name,
            content: reader.result.replace(/^data:.+;base64,/, ""),
            encoding: "base64",
            size: sizeInMB,
          };
          try {
            var EmailAttachmentsSize = (
              parseFloat(this.state.EmailAttachmentsSize) + parseFloat(sizeInMB)
            ).toFixed(2);
            const upload_path = `documents/${localStorage.getItem(
              "UserGuid"
            )}/${file_name}`;
            const content_type = file.type;
            const upload_response = await this.props.postRequest(
              "/attachments/upload",
              {
                upload_path: upload_path,
                content_type: content_type,
                base64Data: reader.result,
              }
            );
            if (upload_response.status) {
              var uploadedObj = {
                ContentType: file.type,
                DocDesc: "Timecard Document",
                DocumentType: ext.toUpperCase(),
                Encrypted: "No",
                filename: file_name,
                PWHash: "",
                Salt: "GYUFYDTFKHFYV",
                Timestamp: new Date().getTime(),
                url: upload_path,
              };
              this.state.FileAttachments.push(uploadedObj);
              this.setState({
                FileAttachments: this.state.FileAttachments,
                EmailAttachmentsSize: EmailAttachmentsSize,
              });
              if (attachment) {
                this.state.EmailAttachments.push(attachment);
              }
              this.setState({ isLoading: false });
            } else {
              console.log("Upload document response=>", upload_response);
              this.setState({
                isLoading: false,
                fileError: "Error in uploading try again.",
              });
            }
          } catch (err) {
            console.log("Create document err=>", err);
            this.setState({
              isLoading: false,
              fileError: "Error in uploading try again.",
            });
          }
        };

        /*        Storage.put(key, file)
            .then (result => {
                var EmailAttachmentsSize = (parseFloat(this.state.EmailAttachmentsSize) + parseFloat(sizeInMB)).toFixed(2);
                //console.log(result);
                var uploadedObj = {
                  "ContentType": file.type,
                  "DocDesc": "Timecard Document",
                  "DoumentType": ext.toUpperCase(),
                  "Encrypted": "No",
                  "filename": file_name,
                  "PWHash": "a7e7ef%^%*&(7ke834",
                  "Salt": "HFHHGVHJBJB",
                  "Timestamp": new Date().getTime(),
                  "url": result.key
                }
                this.state.FileAttachments.push(uploadedObj);
                this.setState({ FileAttachments: this.state.FileAttachments, EmailAttachmentsSize: EmailAttachmentsSize });
                if(attachment){
                  this.state.EmailAttachments.push(attachment);
                }
                this.setState({isLoading: false});
                //console.log(this.state.FileAttachments);

        }).catch(err => {
          this.setState({isLoading: false});
          console.log(err);
        });*/
      });
    }

    //var selected_files = files;
    /*this.setState({
      files : selected_files
    });*/
    //console.log(this.state.files);
    //setTimeout(function() { this.handleUploadFiles; }.bind(this),1000);
  }

  handleDeleteAttachment = (index) => async (event) => {
    //alert(index);
    var FileAttachments = this.state.FileAttachments;
    var EmailAttachments = this.state.EmailAttachments;
    var EmailAttachmentsSize = this.state.EmailAttachmentsSize;
    var EmailAttachmentIndex = EmailAttachments.findIndex(function (item, i) {
      return item.filename === FileAttachments[index].filename;
    });
    var EmailAttachment = EmailAttachments[EmailAttachmentIndex];
    //delete FileAttachments[index];
    if (EmailAttachment.size) {
      EmailAttachmentsSize = (
        parseFloat(EmailAttachmentsSize) - parseFloat(EmailAttachment.size)
      ).toFixed(2);
    }
    FileAttachments.splice(index, 1);
    EmailAttachments.splice(EmailAttachmentIndex, 1);
    this.setState({ FileAttachments: FileAttachments });
    this.setState({
      EmailAttachments: EmailAttachments,
      EmailAttachmentsSize: EmailAttachmentsSize,
    });
  };

  handleCalendar = (element) => (event) => {
    console.log("eleme=>", element);
    $(element + " div input").click();
  };

  toggleCalendar = (element, ev) => (event) => {
    if (ev === "blur") {
      this.setState({ [element]: false });
    } else {
      this.setState({ [element]: !this.state[element] });
    }
  };
  /*
  handleUploadFiles = event => {
    this.setState({ isUploading: true });
    var files = this.state.files;
    var total_files = files.length;
    var files_count = 0;
    files.forEach(file => {
      //console.log(file); return false;
      files_count++;

      var ext = file.name.split('.').pop();
      var file_name = new Date().getTime()+'-'+file.name+'.'+ext;
      var key = "timecards/"+file_name;
        Storage.put(key, file)
            .then (result => {
              var uploadedObj = {
                "ContentType": file.type,
                "DocDesc": "Timecard Doccument",
                "DoumentType": ext.toUpperCase(),
                "Encrypted": "No",
                "filename": file.name,
                "PWHash": "a7e7ef%^%*&(7ke834",
                "Salt": "HFHHGVHJBJB",
                "Timestamp": new Date().getTime(),
                "url": result.key
              }
              //console.log(uploadedObj);
              this.state.FileAttachments.push(uploadedObj);
              
              if(files_count === total_files)
                this.setState({ isUploading: false });

        }).catch(err => console.log(err));
    });

    //this.setState({ isUploading: false });

  }*/

  handleCustomTimeCardFields = (index) => async (event) => {
    var CustomTimeCardFields = this.state.CustomTimeCardFields;

    if (CustomTimeCardFields[index].Type === "Checkbox") {
      CustomTimeCardFields[index].Value = CustomTimeCardFields[index].Value
        ? 0
        : 1;
      this.setState({ CustomTimeCardFields: CustomTimeCardFields });
    }

    if (CustomTimeCardFields[index].Type === "CheckBox") {
      CustomTimeCardFields[index].Value = CustomTimeCardFields[index].Value
        ? 0
        : 1;
      this.setState({ CustomTimeCardFields: CustomTimeCardFields });
    }

    if (CustomTimeCardFields[index].Type === "Entry") {
      CustomTimeCardFields[index].Value =
        event.target.value !== "" ? event.target.value : null;
      this.setState({ CustomTimeCardFields: CustomTimeCardFields });
    }

    if (CustomTimeCardFields[index].Type === "Text") {
      CustomTimeCardFields[index].Value =
        event.target.value !== "" ? event.target.value : null;
      this.setState({ CustomTimeCardFields: CustomTimeCardFields });
    }

    if (CustomTimeCardFields[index].Type === "DropList") {
      CustomTimeCardFields[index].Value =
        event.target.value !== "" ? event.target.value : null;
      this.setState({ CustomTimeCardFields: CustomTimeCardFields });
    }
  };

  handleTimesCustomTimeFields = (index) => async (event) => {
    var TimesCustomTimeFields = this.state.DayTimeCustomTimeFields;

    if (TimesCustomTimeFields[index].Type === "Checkbox") {
      $(event.target).parent().parent().removeClass("field_required");
      TimesCustomTimeFields[index].Value = TimesCustomTimeFields[index].Value
        ? 0
        : 1;
      this.setState({ DayTimeCustomTimeFields: TimesCustomTimeFields });
    }

    if (TimesCustomTimeFields[index].Type === "CheckBox") {
      $(event.target).parent().parent().removeClass("field_required");
      TimesCustomTimeFields[index].Value = TimesCustomTimeFields[index].Value
        ? 0
        : 1;
      this.setState({ DayTimeCustomTimeFields: TimesCustomTimeFields });
    }

    if (TimesCustomTimeFields[index].Type === "Entry") {
      $(event.target).parent().parent().removeClass("field_required");
      TimesCustomTimeFields[index].Value =
        event.target.value !== "" ? event.target.value : null;
      this.setState({ DayTimeCustomTimeFields: TimesCustomTimeFields });
    }

    if (TimesCustomTimeFields[index].Type === "Text") {
      $(event.target).parent().parent().removeClass("field_required");
      TimesCustomTimeFields[index].Value =
        event.target.value !== "" ? event.target.value : null;
      this.setState({ DayTimeCustomTimeFields: TimesCustomTimeFields });
    }

    if (TimesCustomTimeFields[index].Type === "DropList") {
      $(event.target).parent().parent().removeClass("field_required");
      TimesCustomTimeFields[index].Value =
        event.target.value !== "" ? event.target.value : null;
      this.setState({ DayTimeCustomTimeFields: TimesCustomTimeFields });
    }
  };

  setDefaultCompany = async (event) => {
    var company =
      this.state.companies.length > 0 ? this.state.companies[0] : false;
    console.log("this.state.companies", this.state.companies);
    //console.log("Default Company=>",this.state.companies[0]);
    if (company !== false && company.SysCoyGuid) {
      this.setState({ isLoading: true });
      var comp = [
        {
          Guid: company.Guid ? company.Guid : "",
          Name: company.Name ? company.Name : "",
          Payas: this.state.Payas,
          Production: company.Production ? company.Production : "",
        },
      ];

      try {
        const item = await this.getSysCopmany(company.SysCoyGuid);
        if (item.status) {
          //console.log("item.result=>", item.result);
          //console.log("Default sys Company=>",item.result);
          this.setState(
            {
              CustomTimeCardFields: item.result.CustomFields
                ? item.result.CustomFields
                : [],
              TimesCustomTimeFields: item.result.CustomTimeFields
                ? item.result.CustomTimeFields
                : [],
              HideTravel: item.result.HideTravel === 1 ? true : false,
              TravelToHide: item.result.TravelToHide
                ? item.result.TravelToHide
                : false,
              TravelFromHide: item.result.TravelFromHide
                ? item.result.TravelFromHide
                : false,
              mrtFile: item.result.Timesheet
                ? item.result.Timesheet
                : "default",
              Payas: item.result.Payas
                ? item.result.Payas
                : this.state.PayasDefault,
              timeSheetName: item.result.timeSheetName
                ? item.result.timeSheetName
                : false,
              allowMealBreak: item.result.allowMealBreak
                ? item.result.allowMealBreak
                : false, //
              disabledTypedSig: item.result.disabledTypedSig
                ? item.result.disabledTypedSig
                : false, //
              requiredFeilds: item.result.requiredFeilds
                ? item.result.requiredFeilds
                : {
                    signature: true,
                    department: false,
                    position: false,
                  },
              departments: item.result.departments
                ? item.result.departments
                : [],
              CompanyIndex: "0",
              Company: comp,
              Production: company.Production ? company.Production : "",
              Dept: company.Dept ? company.Dept : "",
              Position: company.Position ? company.Position : "",
              PayFrequency: company.PayFreq ? company.PayFreq : "Weekly",
              PayEnding: company.PayEnding ? company.PayEnding : "Sunday",
              SubmitEmail: company.EmailAddress ? company.EmailAddress : "",
              SysCoyGuid: company.SysCoyGuid
                ? company.SysCoyGuid
                : false,
              isLoading: false,
            },
            () => {
              this.validateField("Company", comp, "CompanyValid");
            }
          );
        } else {
          console.log("Error in fatching Syscompany: Invalid ID");
          this.setState({ isLoading: false });
        }
      } catch (e) {
        console.log("Error in fatching Syscompany: " + e);
        this.setState({ isLoading: false });
      }
    } else {
      //this.setState({CustomTimeCardFields: [], TimesCustomTimeFields: [], HideTravel: false, TravelFromHide: false, TravelToHide: false, Payas: this.state.PayasDefault, mrtFile: "default"});
      if (company) {
        var comp = [
          {
            Guid: company.Guid ? company.Guid : "",
            Name: company.Name ? company.Name : "",
            Payas: this.state.Payas,
            Production: company.Production ? company.Production : "",
          },
        ];

        this.setState(
          {
            CompanyIndex: "0",
            Company: comp,
            Production: company.Production ? company.Production : "",
            Dept: company.Dept ? company.Dept : "",
            Position: company.Position ? company.Position : "",
            PayFrequency: company.PayFreq ? company.PayFreq : "Weekly",
            PayEnding: company.PayEnding ? company.PayEnding : "Sunday",
            SubmitEmail: company.EmailAddress ? company.EmailAddress : "",
            SysCoyGuid: company.SysCoyGuid ? company.SysCoyGuid : false,
            timeSheetName: false,
            allowMealBreak: false, //
            disabledTypedSig: false, //
            requiredFeilds: {
              signature: true,
              department: false,
              position: false,
            },
            departments: [],
          },
          () => {
            this.validateField("Company", comp, "CompanyValid");
          }
        );
      }
    }
  };

  handleChange = async (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const ValidStateName = name + "Valid";
    this.setState({ [ValidStateName]: true });
    if (name === "CompanyIndex") {
      this.setState({ isLoading: true });
      if (value === "") {
        //console.log(this.state.companies[value]);
        var company = this.state.companies[value];
        var comp = "";
        this.setState(
          {
            isLoading: false,
            PeriodEnding: "",
            PeriodEndingMoment: "",
            DailyTimes: [],
            [name]: value,
            Company: comp,
            Production: "",
            Dept: "",
            Position: "",
            PayFrequency: "Weekly",
            PayEnding: "",
            SubmitEmail: "",
            CustomTimeCardFields: [],
            TimesCustomTimeFields: [],
            HideTravel: false,
            TravelFromHide: false,
            TravelToHide: false,
            mrtFile: "default",
            TotalHours: "00:00",
          },
          () => {
            this.validateField("Company", comp, "CompanyValid");
          }
        );
      } else {
        var company = this.state.companies[value];
        //console.log(this.state.companies[value]);
        var comp = [
          {
            Guid: company.Guid ? company.Guid : "",
            Name: company.Name ? company.Name : "",
            Payas: this.state.Payas,
            Production: company.Production ? company.Production : "",
          },
        ];

        if (company.SysCoyGuid) {
          try {
            const item = await this.getSysCopmany(company.SysCoyGuid);
            if (item.status) {
              //console.log(item.result);
              this.setState({
                CustomTimeCardFields: item.result.CustomFields
                  ? item.result.CustomFields
                  : [],
                TimesCustomTimeFields: item.result.CustomTimeFields
                  ? item.result.CustomTimeFields
                  : [],
                HideTravel: item.result.HideTravel === 1 ? true : false,
                TravelToHide: item.result.TravelToHide
                  ? item.result.TravelToHide
                  : false,
                TravelFromHide: item.result.TravelFromHide
                  ? item.result.TravelFromHide
                  : false,
                mrtFile: item.result.Timesheet
                  ? item.result.Timesheet
                  : "default",
                Payas: item.result.Payas
                  ? item.result.Payas
                  : this.state.PayasDefault,
                timeSheetName: item.result.timeSheetName
                  ? item.result.timeSheetName
                  : false,
                allowMealBreak: item.result.allowMealBreak
                  ? item.result.allowMealBreak
                  : false, //
                disabledTypedSig: item.result.disabledTypedSig
                  ? item.result.disabledTypedSig
                  : false, //
                requiredFeilds: item.result.requiredFeilds
                  ? item.result.requiredFeilds
                  : {
                      signature: true,
                      department: false,
                      position: false,
                    },
                departments: item.result.departments
                  ? item.result.departments
                  : [],
                TotalHours: "00:00",
                SysCoyGuid: item.result.Guid ? item.result.Guid : false,
              });
            } else {
              console.log("Error in fatching Syscompany: Invalid ID");
            }
          } catch (e) {
            console.log("Error in fatching Syscompany: " + e);
          }
        } else {
          this.setState({
            CustomTimeCardFields: [],
            TimesCustomTimeFields: [],
            HideTravel: false,
            TravelFromHide: false,
            TravelToHide: false,
            mrtFile: "default",
            Payas: this.state.PayasDefault,
            timeSheetName: false,
            allowMealBreak: false, //
            disabledTypedSig: false, //
            requiredFeilds: {
              signature: true,
              department: false,
              position: false,
            },
            departments: [],
            TotalHours: "00:00",
            SysCoyGuid: false,
          });
        }
        //console.log(comp);
        this.setState(
          {
            [name]: value !== "" ? value : null,
            Company: comp,
            Production: company.Production ? company.Production : "",
            Dept: company.Dept ? company.Dept : "",
            Position: company.Position ? company.Position : "",
            PayFrequency: company.PayFreq ? company.PayFreq : "Weekly",
            PayEnding: company.PayEnding ? company.PayEnding : "Sunday",
            SubmitEmail: company.EmailAddress ? company.EmailAddress : "",
            isLoading: false,
            PeriodEndingMoment: "",
            PeriodEnding: "",
            DailyTimes: [],
          },
          () => {
            this.validateField("Company", comp, "CompanyValid");
          }
        );
      }
    } else if (name === "SubmitEmail") {
      this.setState({ [name]: value !== "" ? value : null }, () => {
        this.validateSubmitEmail(value);
      });
    } else if (name === "Dept" || name === "Position") {
      //console.log("=>name", name);
      //console.log("=>value", value);
      this.setState({ [name]: value !== "" ? value : "" });
    } else {
      this.setState({ [name]: value !== "" ? value : null }, () => {
        this.validateField(name, value, ValidStateName);
      });
    }
  };

  handleChangeDayTime = (event) => {
    this.setState({ SaveDayTimeError: "" });
    const name = event.target.name;
    const value = event.target.value;
    //alert(name);
    if (
      name === "DayTimeMB1ND" ||
      name === "DayTimeMB2ND" ||
      name === "DayTimeMB3ND"
    ) {
      this.setState({ [name]: event.target.checked, isLoading: true });
      setTimeout(
        function () {
          this.updateMealBreakTime();
        }.bind(this),
        1000
      );
    } else {
      /*else if(name === 'DayTimeDate'){
      var d = new Date(value);
      var weekday = new Array(7);
      weekday[0] = "Sunday";
      weekday[1] = "Monday";
      weekday[2] = "Tuesday";
      weekday[3] = "Wednesday";
      weekday[4] = "Thursday";
      weekday[5] = "Friday";
      weekday[6] = "Saturday";
      
      this.setState({[name]: value, DayTimeDay:weekday[d.getDay()] });
    }*/
      /*else if(name === 'DayTimeFinish'){
      var WorkingHoure = parseFloat(this.state.DayTimeFinish - this.state.DayTimeStart).toFixed(1);
      //console.log(parseFloat(WorkingHoure).toFixed(2));
      this.setState({[name]: value, DayTimeTotalHours: WorkingHoure });
    }*/
      this.setState({ [name]: value !== "" ? value : null });
    }
  };

  validateField(fieldName, value, ValidStateName) {
    //debugger;
    let valid = true;

    if (value.length === 0) {
      valid = false;
    }

    this.setState({ [ValidStateName]: valid }, this.validateForm);
  }

  validateForm() {
    this.setState({ formValid: this.state.CompanyValid });
  }

  validateSubmitEmail() {
    let valid = true;

    if (this.state.SubmitEmail === null) {
      valid = false;
    }

    this.setState({ SubmitEmailValid: valid });
  }

  handleSubmitSuccessModal = async (event) => {
    event.preventDefault();

    $("#SubmitSuccessModalClose").click();
    this.clearTimeCardStats();
  };

  handleSubmitEmail = async (event) => {
    //console.log(this.state.InsertedId)
    //event.preventDefault();
    this.setState({ isLoading: true });
    //debugger;
    let timeSheetNameStr = this.state.timeSheetName;
    let time_sheet_name = timeSheetNameStr;
    if (timeSheetNameStr.length > 0) {
      let word = /\{(.*?)\}/g;

      var placeHolderArray = [];
      for (
        let m = word.exec(timeSheetNameStr);
        m;
        m = word.exec(timeSheetNameStr)
      ) {
        placeHolderArray.push(m[1]);
      }
      console.log(placeHolderArray);
      placeHolderArray.map((tempItem, index) => {
        // {Department}-{Position}-{LastName}-{FirstName}-{PayFrequency}-{PayEnding}-{PeriodEnding}
        let valueAgainstKey = null;
        if (tempItem.toUpperCase() === "DEPARTMENT") {
          valueAgainstKey = this.state.Dept;
        } else if (tempItem.toUpperCase() === "POSITION") {
          valueAgainstKey = this.state.Position;
        } else if (tempItem.toUpperCase() === "LASTNAME") {
          valueAgainstKey = localStorage.getItem("UserLastName");
        } else if (tempItem.toUpperCase() === "FIRSTNAME") {
          valueAgainstKey = localStorage.getItem("UserFirstName");
        } else if (tempItem.toUpperCase() === "PAYFREQUENCY") {
          valueAgainstKey = this.state.PayFrequency;
        } else if (tempItem.toUpperCase() === "PAYENDING") {
          valueAgainstKey = this.state.PayEnding;
        } else if (tempItem.toUpperCase() === "PERIODENDING") {
          valueAgainstKey = DateFormate(this.state.PeriodEnding, "DDMMMMYYYY");
        } else if (tempItem.toUpperCase() === "TOTALHOURS") {
          valueAgainstKey = this.state.TotalHours;
        } else {
          valueAgainstKey = "";
        }

        var replaceKey = "{" + tempItem + "}";
        console.log("replaceKey", replaceKey);
        time_sheet_name = time_sheet_name
          .split(replaceKey)
          .join(valueAgainstKey);
      });
    }

    if (this.state.SubmitEmailValid && this.state.SubmitEmail.length > 0) {
      //var DailyTimes = this.state.DailyTimes;
      //var DailyTimesLenght = DailyTimes.length;
      //var LastDailyTimes = [];
      var file_name =
        new Date().getTime() + "-" + this.state.InsertedId + ".pdf";
      var key =
        "documents/" + localStorage.getItem("UserGuid") + "/" + file_name;
      var SubmitEmail = this.state.SubmitEmail;

      var docItem = {
        Company: this.state.Company[0].Name,
        ContentType: "application/pdf",
        DocDesc: "Crew Timesheet",
        DocumentType: "PDF",
        Encrypted: "No",
        Filename: file_name,
        Production: this.state.Production,
        PWHash: "f56e46w#3fefew",
        Salt: "GYUFYDTFKHFYV",
        url: file_name,
        TimecardGuid: this.state.InsertedId,
        DocType: "Timecard",
      };

      var DailyTimesLenght = this.state.DailyTimes.length;
      var PreviewDailyTimes = [];

      if (DailyTimesLenght > 0) {
        for (var i = 0; i < DailyTimesLenght; i++) {
          var DTime = {
            Date: "",
            Day: "",
            Finish: "00:00",
            FinishMeal1: "00:00",
            FinishMeal2: "00:00",
            FinishMeal3: "00:00",
            MB1ND: "0",
            MB2ND: "0",
            MB3ND: "0",
            Note: "",
            Payas: "",
            Start: "00:00",
            StartMeal: "00:00",
            StartMeal2: "0:00",
            StartMeal3: "00:00",
            TimeStamp: "1523884860",
            TotalHours: "00:00",
            TotalMBDed: "00:00",
            TotalMB1: "00:00",
            TotalMB2: "00:00",
            TotalMB3: "00:00",
            Travel1: "00:00",
            Travel2: "00:00",
          };
          DTime.CustomTimeFields = this.state.DailyTimes[i].CustomTimeFields
            ? this.state.DailyTimes[i].CustomTimeFields
            : [];
          DTime.Date = this.state.DailyTimes[i].Date
            ? moment(new Date(this.state.DailyTimes[i].Date * 1000)).format(
                "DD/MM/YYYY"
              )
            : "";
          DTime.Day = this.state.DailyTimes[i].Day
            ? this.state.DailyTimes[i].Day
            : "";
          DTime.Finish = this.state.DailyTimes[i].Finish
            ? this.state.DailyTimes[i].Finish
            : "00:00";
          DTime.FinishMeal1 = this.state.DailyTimes[i].FinishMeal1
            ? this.state.DailyTimes[i].FinishMeal1
            : "00:00";
          DTime.FinishMeal2 = this.state.DailyTimes[i].FinishMeal2
            ? this.state.DailyTimes[i].FinishMeal2
            : "00:00";
          DTime.FinishMeal3 = this.state.DailyTimes[i].FinishMeal3
            ? this.state.DailyTimes[i].FinishMeal3
            : "00:00";
          DTime.MB1ND = this.state.DailyTimes[i].MB1ND
            ? this.state.DailyTimes[i].MB1ND
            : "0";
          DTime.MB2ND = this.state.DailyTimes[i].MB2ND
            ? this.state.DailyTimes[i].MB2ND
            : "0";
          DTime.MB3ND = this.state.DailyTimes[i].MB3ND
            ? this.state.DailyTimes[i].MB3ND
            : "0";
          DTime.Note = this.state.DailyTimes[i].Note
            ? this.state.DailyTimes[i].Note
            : "";
          DTime.Payas = this.state.DailyTimes[i].Payas
            ? this.state.DailyTimes[i].Payas
            : "";
          DTime.Start = this.state.DailyTimes[i].Start
            ? this.state.DailyTimes[i].Start
            : "00:00";
          DTime.StartMeal = this.state.DailyTimes[i].StartMeal
            ? this.state.DailyTimes[i].StartMeal
            : "00:00";
          DTime.StartMeal2 = this.state.DailyTimes[i].StartMeal2
            ? this.state.DailyTimes[i].StartMeal2
            : "00:00";
          DTime.StartMeal3 = this.state.DailyTimes[i].StartMeal3
            ? this.state.DailyTimes[i].StartMeal3
            : "00:00";
          DTime.TimeStamp = this.state.DailyTimes[i].TimeStamp
            ? this.state.DailyTimes[i].TimeStamp
            : "";
          DTime.TotalHours = this.state.DailyTimes[i].TotalHours
            ? this.state.DailyTimes[i].TotalHours
            : "00:00";
          DTime.TotalMBDed = this.state.DailyTimes[i].TotalMBDed
            ? this.state.DailyTimes[i].TotalMBDed
            : "00:00";
          DTime.TotalMB1 = this.getDiff(
            this.state.DailyTimes[i].StartMeal,
            this.state.DailyTimes[i].FinishMeal1
          );
          DTime.TotalMB2 = this.getDiff(
            this.state.DailyTimes[i].StartMeal2,
            this.state.DailyTimes[i].FinishMeal2
          );
          DTime.TotalMB3 = this.getDiff(
            this.state.DailyTimes[i].StartMeal3,
            this.state.DailyTimes[i].FinishMeal3
          );
          DTime.Travel1 = this.state.DailyTimes[i].Travel1
            ? this.state.DailyTimes[i].Travel1
            : "";
          DTime.Travel2 = this.state.DailyTimes[i].Travel2
            ? this.state.DailyTimes[i].Travel2
            : "";
          PreviewDailyTimes.push(DTime);
        }
      }

      var Times = [
        {
          //"CustomTimeFields":this.state.TimesCustomTimeFields,
          DailyTimes: PreviewDailyTimes,
          TotalHours: this.state.TimesTotalHours,
        },
      ];

      var UserFirstName = localStorage.getItem("UserFirstName")
        ? localStorage.getItem("UserFirstName")
        : "";
      var UserLastName = localStorage.getItem("UserLastName")
        ? localStorage.getItem("UserLastName")
        : "";
      var subject =
        UserLastName +
        " " +
        UserFirstName +
        " " +
        moment(new Date(this.state.PeriodEnding)).format("DDMMMMYYYY") +
        " " +
        " " +
        this.state.Dept +
        " " +
        this.state.Position +
        " " +
        this.state.Production;

      try {
        /*var signature = await this.getUserSignature();

        if (this.state.requiredFeilds.signature === true && signature === "") {
          if (this.state.disabledTypedSig) {
            alert("Draw signature is required.");
          } else {
            alert("Signature is required.");
          }
          this.setState({ onHold: false, previewLoading: false });
          return false;
        }*/

        const item = {
          mrtFile: this.state.mrtFile,
          status: true,
          items: [
            {
              UserFirstName: localStorage.getItem("UserFirstName")
                ? localStorage.getItem("UserFirstName")
                : "",
              UserLastName: localStorage.getItem("UserLastName")
                ? localStorage.getItem("UserLastName")
                : "",
              Signature: this.state.UserSignatureUrl,
              CreatedDate: moment(new Date()).format("DD/MM/YYYY"),
              Activity: this.state.Activity,
              Company: this.state.Company
                ? [
                    {
                      Guid: this.state.Company[0].Guid,
                      Name: this.state.Company[0].Name
                        ? this.state.Company[0].Name
                        : "",
                      Payas: this.state.Payas ? this.state.Payas : "",
                      Production: this.state.Production
                        ? this.state.Production
                        : null,
                    },
                  ]
                : [],

              CustomTimeCardFields: this.state.CustomTimeCardFields,
              Dept: this.state.Dept ? this.state.Dept : "",
              FileAttachments: this.state.FileAttachments,
              PayFrequency: this.state.PayFrequency,
              PeriodEnding: this.state.PeriodEnding,
              Position: this.state.Position ? this.state.Position : "",
              EmailTo: this.state.SubmitEmail,
              IPAddress: this.state.ipv4,
              Times: Times,
              TotalHours: this.state.TotalHours,
              Type: this.state.SubView === "Submit" ? "submitted" : "Draft",
            },
          ],
        };

        const response = await this.createPreview(item);

        //const base64Data = new Buffer(response.pdfData, 'base64');

        const userEmail = this.state.EmailAddress;

        /*if(response.htmlData){
            try{
              const responseImage = await this.createImage({"htmlString" : response.htmlData});
              console.log("convert html to image Response=>"+responseImage);
              if(responseImage.status){
                this.setState({timesheetImage: responseImage.base64Image});
              }else{
                  this.setState({timesheetImage: false});
              }  
            }catch (er) {
              console.log("convert html to image Error=>"+er);
              this.setState({timesheetImage: false});
            }
          }*/

        try {
          this.setState({ timesheetImage: response.pdfData });
          const upload_response = await this.props.postRequest(
            "/attachments/upload",
            {
              upload_path: key,
              content_type: "application/pdf",
              base64Data: response.pdfData,
            }
          );
          if (upload_response.status) {
            this.createDocuments(docItem);
          } else {
            console.log("Uplod document response=>", upload_response);
          }
        } catch (err) {
          console.log("Uplod document err=>", err);
        }

        var EmailData = {
          to: SubmitEmail + "," + userEmail,
          subject: subject,
          message: "Timecard attached",
          time_sheet_name: time_sheet_name,
          Preview: response.pdfData,
          AttachmentDocs: this.state.EmailAttachments,
        };

        try {
          //console.log(EmailData);
          const response = await this.sendEmail(EmailData);

          //console.log('email',response); return false;
          this.setState({ isLoading: false, SubmitEmailValid: false });
        } catch (e) {
          console.log("Email " + e);
          this.setState({ isLoading: false, SubmitEmailValid: false });
        }
        //return false;
      } catch (e) {
        console.log("Preview Error: " + e);
        this.setState({ isLoading: false, SubmitEmailValid: false });
      }
    } else {
      this.setState({ isLoading: false, SubmitEmailValid: false });
    }
  };

  handlePreview = async (event) => {
    event.preventDefault();

    if (
      this.state.requiredFeilds.department === true &&
      this.state.Dept.length === 0
    ) {
      this.setState({ DeptValid: false });
      alert("Please select Department");
      return false;
    }

    if (
      this.state.requiredFeilds.position === true &&
      this.state.Position.length === 0
    ) {
      this.setState({ PositionValid: false });
      alert("Please add Position");
      return false;
    }

    if (this.state.TotalHours < "00:00") {
      alert("Total hours should not negative ");
      return false;
    }

    this.setState({ onHold: true, previewLoading: true });

    var customValidation = true;
    $(".custom-timecard-field")
      .filter("[required]:visible")
      .each(function (i, requiredField) {
        if ($(requiredField).attr("type") === "checkbox") {
          if ($(requiredField).is(":checked")) {
            $(requiredField).parent().parent().removeClass("field_required");
          } else {
            customValidation = false;
            $(requiredField).parent().parent().addClass("field_required");
          }
        } else {
          if ($(requiredField).val() === "") {
            customValidation = false;
            $(requiredField).parent().parent().addClass("field_required");
          } else {
            $(requiredField).parent().parent().removeClass("field_required");
          }
        }
      });

    if (this.state.formValid && customValidation) {
      var DailyTimesLenght = this.state.DailyTimes.length;
      var PreviewDailyTimes = [];
      //console.log("DailyTimes=>", this.state.DailyTimes); return false;
      if (DailyTimesLenght > 0) {
        for (var i = 0; i < DailyTimesLenght; i++) {
          var DTime = {
            Date: "",
            Day: "",
            Finish: "00:00",
            FinishMeal1: "00:00",
            FinishMeal2: "00:00",
            FinishMeal3: "00:00",
            MB1ND: "0",
            MB2ND: "0",
            MB3ND: "0",
            Note: "",
            Payas: "",
            Start: "00:00",
            StartMeal: "00:00",
            StartMeal2: "0:00",
            StartMeal3: "00:00",
            TimeStamp: "1523884860",
            TotalHours: "00:00",
            TotalMBDed: "00:00",
            TotalMB1: "00:00",
            TotalMB2: "00:00",
            TotalMB3: "00:00",
            Travel1: "00:00",
            Travel2: "00:00",
          };
          DTime.CustomTimeFields = this.state.DailyTimes[i].CustomTimeFields
            ? this.state.DailyTimes[i].CustomTimeFields
            : [];
          DTime.Date = this.state.DailyTimes[i].Date
            ? moment(new Date(this.state.DailyTimes[i].Date * 1000)).format(
                "DD/MM/YYYY"
              )
            : "";
          DTime.Day = this.state.DailyTimes[i].Day
            ? this.state.DailyTimes[i].Day
            : "";
          DTime.Finish = this.state.DailyTimes[i].Finish
            ? this.state.DailyTimes[i].Finish
            : "00:00";
          DTime.FinishMeal1 = this.state.DailyTimes[i].FinishMeal1
            ? this.state.DailyTimes[i].FinishMeal1
            : "00:00";
          DTime.FinishMeal2 = this.state.DailyTimes[i].FinishMeal2
            ? this.state.DailyTimes[i].FinishMeal2
            : "00:00";
          DTime.FinishMeal3 = this.state.DailyTimes[i].FinishMeal3
            ? this.state.DailyTimes[i].FinishMeal3
            : "00:00";
          DTime.MB1ND = this.state.DailyTimes[i].MB1ND
            ? this.state.DailyTimes[i].MB1ND
            : "0";
          DTime.MB2ND = this.state.DailyTimes[i].MB2ND
            ? this.state.DailyTimes[i].MB2ND
            : "0";
          DTime.MB3ND = this.state.DailyTimes[i].MB3ND
            ? this.state.DailyTimes[i].MB3ND
            : "0";
          DTime.Note = this.state.DailyTimes[i].Note
            ? this.state.DailyTimes[i].Note
            : "";
          DTime.Payas = this.state.DailyTimes[i].Payas
            ? this.state.DailyTimes[i].Payas
            : "";
          DTime.Start = this.state.DailyTimes[i].Start
            ? this.state.DailyTimes[i].Start
            : "00:00";
          DTime.StartMeal = this.state.DailyTimes[i].StartMeal
            ? this.state.DailyTimes[i].StartMeal
            : "00:00";
          DTime.StartMeal2 = this.state.DailyTimes[i].StartMeal2
            ? this.state.DailyTimes[i].StartMeal2
            : "00:00";
          DTime.StartMeal3 = this.state.DailyTimes[i].StartMeal3
            ? this.state.DailyTimes[i].StartMeal3
            : "00:00";
          DTime.TimeStamp = this.state.DailyTimes[i].TimeStamp
            ? this.state.DailyTimes[i].TimeStamp
            : "";
          DTime.TotalHours = this.state.DailyTimes[i].TotalHours
            ? this.state.DailyTimes[i].TotalHours
            : "00:00";
          DTime.TotalMBDed = this.state.DailyTimes[i].TotalMBDed
            ? this.state.DailyTimes[i].TotalMBDed
            : "00:00";
          DTime.TotalMB1 = this.getDiff(
            this.state.DailyTimes[i].StartMeal,
            this.state.DailyTimes[i].FinishMeal1
          );
          DTime.TotalMB2 = this.getDiff(
            this.state.DailyTimes[i].StartMeal2,
            this.state.DailyTimes[i].FinishMeal2
          );
          DTime.TotalMB3 = this.getDiff(
            this.state.DailyTimes[i].StartMeal3,
            this.state.DailyTimes[i].FinishMeal3
          );
          DTime.Travel1 = this.state.DailyTimes[i].Travel1
            ? this.state.DailyTimes[i].Travel1
            : "";
          DTime.Travel2 = this.state.DailyTimes[i].Travel2
            ? this.state.DailyTimes[i].Travel2
            : "";
          PreviewDailyTimes.push(DTime);
        }
      }
      //      console.log(PreviewDailyTimes);
      //return false;
      var Times = [
        {
          //"CustomTimeFields":this.state.TimesCustomTimeFields,
          DailyTimes: PreviewDailyTimes,
          TotalHours: this.state.TimesTotalHours,
        },
      ];
      //console.log(this.state.Times); return false;

      try {
        var signature = await this.getUserSignature();
        console.log("signature=>", signature);
        if (this.state.requiredFeilds.signature === true && signature === "") {
          if (this.state.disabledTypedSig) {
            alert("Draw signature is required.");
          } else {
            alert("Signature is required.");
          }
          this.setState({ onHold: false, previewLoading: false });
          return false;
        }

        var pdfWindow = window.open();
        pdfWindow.document
          .open()
          .write(
            "<html><head><title>Preview</title></head><body style='margin:0; text-align: center;'><h1 class='previewLoading'>Loading.....</h1></body></html>"
          );

        const item = {
          mrtFile: this.state.mrtFile,
          status: true,
          items: [
            {
              UserFirstName: localStorage.getItem("UserFirstName")
                ? localStorage.getItem("UserFirstName")
                : "",
              UserLastName: localStorage.getItem("UserLastName")
                ? localStorage.getItem("UserLastName")
                : "",
              Signature: signature,
              CreatedDate: moment(new Date()).format("DD/MM/YYYY"),
              Activity: this.state.Activity,
              Company: this.state.Company
                ? [
                    {
                      Guid: this.state.Company[0].Guid,
                      Name: this.state.Company[0].Name
                        ? this.state.Company[0].Name
                        : "",
                      Payas: this.state.Payas ? this.state.Payas : "",
                      Production: this.state.Production
                        ? this.state.Production
                        : null,
                    },
                  ]
                : [],

              CustomTimeCardFields: this.state.CustomTimeCardFields,
              Dept: this.state.Dept ? this.state.Dept : "",
              FileAttachments: this.state.FileAttachments,
              PayFrequency: this.state.PayFrequency,
              PeriodEnding: this.state.PeriodEnding,
              //"Pos": this.state.Pos,
              Position: this.state.Position ? this.state.Position : "",
              EmailTo: this.state.SubmitEmail,
              IPAddress: this.state.ipv4,
              //"Times": this.state.Times,
              Times: Times,
              TotalHours: this.state.TotalHours,
              Type: this.state.SubView === "Submit" ? "submitted" : "Draft",
            },
          ],
        };
        //console.log(item); return false;

        const response = await this.createPreview(item);
        //console.log("Preview=> ",response); return false;

        if (response.pdfData) {
          pdfWindow.document
            .open()
            .write(
              "<html><head><title>Preview</title></head><body style='margin:0; text-align: center;'><style>body{margin:0;} .previewLoading { display:none; } </style><iframe width='100%'  height='1000' style='border:0;' src='data:application/pdf;base64, " +
                encodeURI(response.pdfData) +
                "'></iframe></body></html>"
            );
        }
        //pdfWindow.write("<html><head></head><body style='margin:0;'><iframe width='100%'  height='1000' style='border:0;' src='data:application/pdf;base64, " + encodeURI(response.pdfData)+"'></iframe></iframe></body></html>")
        //$("#pdf-content").html("<html><head></head><body style='margin:0;'><iframe width='100%'  height='1000' style='border:0;' src='data:application/pdf;base64, " + encodeURI(response.pdfData)+"'></iframe></iframe></body></html>")
        //$("#modalFullScreenBtn").click();
        //this.setState({Preview: "data:application/pdf;base64, " + encodeURI(response.pdfData) });
        //console.log(response);
      } catch (e) {
        console.log("Preview Errors:" + e);
      }

      this.setState({ onHold: false, previewLoading: false });
    } else {
      if (!this.state.formValid) {
        this.setState({ CompanyValid: false });
      }
      this.setState({ onHold: false, previewLoading: false });
    }
  };

  handleSubmitView = async (event) => {
    event.preventDefault();

    if (this.state.companies.length === 0) {
      alert("You need to create a company before submitting a timesheet");
      return false;
    }

    if (!this.state.Company) {
      alert("Please select a production before submitting your timesheet");
      return false;
    }

    if (this.state.DailyTimes.length === 0) {
      alert("Please select period ending date.");
      return false;
    }

    if (parseFloat(this.state.EmailAttachmentsSize) > 4.5) {
      alert("Attachments size increasing the limit of 5 MB");
      return false;
    }

    if (
      this.state.requiredFeilds.department === true &&
      this.state.Dept.length === 0
    ) {
      this.setState({ DeptValid: false });
      alert("Please select Department");
      return false;
    }

    if (
      this.state.requiredFeilds.position === true &&
      this.state.Position.length === 0
    ) {
      this.setState({ PositionValid: false });
      alert("Please add Position");
      return false;
    }

    if (this.state.TotalHours < "00:00") {
      alert("Total hours should not negative ");
      return false;
    }

    this.setState({ isLoading: true });

    var customValidation = true;
    $(".custom-timecard-field")
      .filter("[required]:visible")
      .each(function (i, requiredField) {
        if ($(requiredField).attr("type") === "checkbox") {
          if ($(requiredField).is(":checked")) {
            $(requiredField).parent().parent().removeClass("field_required");
          } else {
            customValidation = false;
            $(requiredField).parent().parent().addClass("field_required");
          }
        } else {
          if ($(requiredField).val() === "") {
            customValidation = false;
            $(requiredField).parent().parent().addClass("field_required");
          } else {
            $(requiredField).parent().parent().removeClass("field_required");
          }
        }
      });

    if (!this.state.formValid || !customValidation) {
      alert("Please fill all required fields before submitting your timesheet");
    }

    if (this.state.formValid && customValidation) {
      this.setState({
        isLoading: false,
        SubView: "Submit",
        SubmitEmailValid: true,
      });
    } else {
      if (!this.state.formValid) {
        this.setState({ CompanyValid: false });
      }
      this.setState({ isLoading: false });
    }
  };

  /*
  handleSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading: true });
    //await this.getTimesheetImage();

    var customValidation = true;
    $('.custom-timecard-field').filter('[required]:visible').each(function(i, requiredField){
     
     if($(requiredField).attr('type') === 'checkbox'){
        if($(requiredField).is(':checked')){
            $(requiredField).parent().parent().removeClass('field_required');
        } else {
              customValidation = false;
              $(requiredField).parent().parent().addClass('field_required');
        }

      }else{

        if($(requiredField).val() === ""){
                customValidation = false;
                $(requiredField).parent().parent().addClass('field_required');
        }else{
              $(requiredField).parent().parent().removeClass('field_required');
        }

      }
    });

    if(this.state.formValid && customValidation){

      var Times = this.state.Times;
      Times[this.state.DailyTimesIndex] = {CustomTimeFields:this.state.TimesCustomTimeFields, DailyTimes: this.state.DailyTimes, TotalHours: this.state.TimesTotalHours};
      
      const item = {
          "Activity": this.state.Activity,
          "Company": this.state.Company? [{
            "Guid": this.state.Company[0].Guid,
            "Name": this.state.Company[0].Name? this.state.Company[0].Name : '',
            "Payas": this.state.Payas? this.state.Payas : '',
            "Production": this.state.Production? this.state.Production : null
          }] : [],
          
          "CustomTimeCardFields": this.state.CustomTimeCardFields,
          "Dept": this.state.Dept? this.state.Dept : '',
          "FileAttachments": this.state.FileAttachments,
          "PayFrequency" : this.state.PayFrequency,
          "PeriodEnding": this.state.PeriodEnding,
          "Position": this.state.Position? this.state.Position : '',
          "Times": Times,   
          "TotalHours": this.state.TotalHours,
          "Type": this.state.SubView === 'Submit'? "submitted" : "Draft"
      }

      if(this.state.SubView === 'Submit'){
        if(!this.state.SubmitEmailValid && this.state.SubmitEmail.length === 0){
          this.setState({isLoading: false });
          return false;
        }
      }
//console.log('continue');
      try {

        if(!this.state.Update){
          const response = await this.createTimeCard(item);
          
          if(response.status){
            this.setState({InsertedId: response.result.Guid });
            if(this.state.SubView === 'Submit'){
              const items = this.state.timecards;
              items.push(response.result);
              this.setState({ timecards: items});
              await this.handleSubmitEmail();
              this.Digitaltimes_insert(response);
              $('#SubmitSuccessModalBtn').click();
            
            }else{
              
              const items = this.state.timecards;
              items.push(response.result);
              this.setState({ timecards: items});
              console.log('Successfully saved.');
              this.clearTimeCardStats();
            
            }

            //this.Digitaltimes_insert(response);
            this.saveActivity("Timecard has been created");
            
          }
        }else{
          const response = await this.updateTimeCard(item);
          if(response.status){
            var timecards = this.state.timecards;
            this.setState({InsertedId: response.result.Guid });
            if(this.state.SubView === 'Submit'){
              await this.handleSubmitEmail();
              delete timecards[this.state.ItemIndex];
              this.setState({timecards: timecards});
              this.Digitaltimes_insert(response);
              $('#SubmitSuccessModalBtn').click();
            }else{
              timecards[this.state.ItemIndex] = response.result;
              this.setState({timecards: timecards});
              console.log('Successfully updated.');
              this.clearTimeCardStats();
            }
            
          }
        }

      }catch (e) {
          //alert(e);
          console.log("Timecaed "+e);
      }
      this.setState({isLoading: false });

    }else{
      if(!this.state.formValid){
        this.setState({CompanyValid:false});
      }
      this.setState({isLoading: false});
    }
    
  }*/
/*
  Digitaltimes_insert = async (timecard) => {
    let timecarddata = timecard.result;
    if (timecarddata.UserGuid) {
      var body = {
        tenant: this.state.SysCoyGuid,
        userid: timecarddata.UserGuid,
      };

      var dates_match = [];
      timecarddata.Times[0].DailyTimes.map((value) => {
        dates_match.push(
          moment(new Date(value.Date * 1000)).format("YYYY-MM-DD")
        );
      });

      var body1 = {
        tenant: this.state.SysCoyGuid,
        dates: dates_match,
      };

      var that = this;
      let Employee = [];
      let freelanceuser = false;
      let ADTimes = [];
      let complete_group = "";
      let current_approver = "No user";
      let approve_status = "Draft";
      let group_guid = null;
      var tmp_app = [];

      await $.ajax({
        url: this.state.dt_api_url + "/freelanceemployee",
        crossDomain: true,
        contentType: "application/json",
        data: JSON.stringify(body),
        dataType: "json",
        method: "post",
        success: function (result) {
          Employee = result;
        },
      });

      if (Employee.length > 0) {
        let finalguids = [];
        console.log(dates_match);
        await $.ajax({
          url: this.state.dt_api_url + "/getadlistbydate",
          crossDomain: true,
          contentType: "application/json",
          data: JSON.stringify(body1),
          dataType: "json",
          method: "post",
          success: function (result) {
            finalguids = result;
          },
        });

        let array_to_send = [];
        finalguids.map((value) => {
          array_to_send.push(value.Guid);
        });
        console.log(array_to_send);
        let body_getadtimes = {
          EmployeeGuid: Employee[0].Guid,
          adlistGuid: array_to_send,
        };

        await $.ajax({
          url: this.state.dt_api_url + "/getadtimesbyadlistguid",
          crossDomain: true,
          contentType: "application/json",
          data: JSON.stringify(body_getadtimes),
          dataType: "json",
          method: "post",
          success: function (result) {
            ADTimes = result;
          },
        });

        if (Employee[0].ApproverGroup) {
          let body_group = {
            Guid: Employee[0].ApproverGroup,
          };
          await $.ajax({
            url: this.state.dt_api_url + "/getgroupbyname",
            crossDomain: true,
            contentType: "application/json",
            data: JSON.stringify(body_group),
            dataType: "json",
            method: "post",
            success: function (result) {
              if (result.Item) {
                complete_group = result.Item;
              }
            },
          });

          if (complete_group !== "") {
            let body_addgroup = {
              ApproverSetup: complete_group.ApproverSetup,
              ApproverGroup: complete_group.ApproverGroup,
              GroupName: complete_group.GroupName,
              TenantGuid: this.state.SysCoyGuid,
            };
            await $.ajax({
              url: this.state.dt_api_url + "/appgroupfortimecard",
              crossDomain: true,
              contentType: "application/json",
              data: JSON.stringify(body_addgroup),
              dataType: "json",
              method: "post",
              success: function (result) {
                if (result) {
                  if (result.Approvers.length > 0) {
                    result.Approvers.map((value) => {
                      tmp_app.push(value.approver_id);
                    });
                  }
                  group_guid = result.Guid;
                }
              },
            });
          }
        }
      } else {
        const user_re = await this.props.getRequest(`/users`);
        if (user_re.status) {
          freelanceuser = user_re.result;
          ADTimes = [];
          console.log("user", freelanceuser);
        } else {
          console.log(user_re);
        }
      }

      let finaldata = {
        approver_chain: tmp_app,
        group_guid: group_guid,
        current_approver: current_approver,
        approve_status: approve_status,
        ChequeNumber: null,
        PayslipNote: null,
        WorkState: null,
        PayWeek: null,
        ChartSort: null,
        ChartCode: null,
        Flags: null,
        InternalNotes: null,
        PaymentFlags: null,
        PeriodEndingDate: null,
        ADTimes: ADTimes,
        PRLTimes: timecarddata,
        PayTimes: timecarddata,
        Batch: 1,
        Employee: Employee,
        freelanceuser: freelanceuser,
        TenantGuid: this.state.SysCoyGuid,
        comments: [],
        changes: [],
        activities: [
          {
            date: moment(Date.now()).format("l h:mma"),
            msg: "Timecard created by Freelance Portal.",
          },
        ],
        senderid: null,
        timesheetImage: this.state.timesheetImage
          ? "data:application/pdf;base64," + this.state.timesheetImage
          : false,
        freelanceuser_signature: this.state.UserSignatureImage
          ? this.state.UserSignatureImage
          : null,
        freelanceuser_email: this.state.EmailAddress
          ? this.state.EmailAddress
          : null,
      };
      console.log("finaldata=>", finaldata);
      console.log("freelanceuser=>", freelanceuser);

      if (Employee.length > 0 || freelanceuser) {
        await $.ajax({
          url: this.state.dt_api_url + "/createprlemp",
          crossDomain: true,
          contentType: "application/json",
          data: JSON.stringify(finaldata),
          dataType: "json",
          method: "post",
          success: function (result) {
            console.log(result);
          },
        });
      }
    } else {
      console.log("No time Card");
    }
  };*/


  Digitaltimes_insert = async (timecard) => {
    let timecarddata = timecard.result;
    if (timecarddata.UserGuid) {

      var that = this;
      let Employee = [];
      let freelanceuser = false;
      let ADTimes = [];
      let complete_group = "";
      let current_approver = "No user";
      let approve_status = "Draft";
      let group_guid = null;
      var tmp_app = [];

      const user_re = await this.props.getRequest(`/users`);
      if (user_re.status) {
        freelanceuser = user_re.result;
        ADTimes = [];
        console.log("user", freelanceuser);
      } else {
        console.log(user_re);
      }

      let finaldata = {
        approver_chain: tmp_app,
        group_guid: group_guid,
        current_approver: current_approver,
        approve_status: approve_status,
        ChequeNumber: null,
        PayslipNote: null,
        WorkState: null,
        PayWeek: null,
        ChartSort: null,
        ChartCode: null,
        Flags: null,
        InternalNotes: null,
        PaymentFlags: null,
        PeriodEndingDate: null,
        ADTimes: ADTimes,
        PRLTimes: timecarddata,
        PayTimes: timecarddata,
        Batch: 1,
        Employee: Employee,
        freelanceuser: freelanceuser,
        TenantGuid: this.state.SysCoyGuid,
        comments: [],
        changes: [],
        activities: [
          {
            date: moment(Date.now()).format("l h:mma"),
            msg: "Timecard created by Freelance Portal.",
          },
        ],
        senderid: null,
        timesheetImage: this.state.timesheetImage
          ? "data:application/pdf;base64," + this.state.timesheetImage
          : false,
        freelanceuser_signature: this.state.UserSignatureImage
          ? this.state.UserSignatureImage
          : null,
        freelanceuser_email: this.state.EmailAddress
          ? this.state.EmailAddress
          : null,
      };

      if (freelanceuser) {
        await $.ajax({
          url: this.state.dt_api_url + "/createprlemp",
          crossDomain: true,
          contentType: "application/json",
          data: JSON.stringify(finaldata),
          dataType: "json",
          method: "post",
          success: function (result) {
            console.log(result);
          },
        });
      }
    } else {
      console.log("No time Card");
    }
  };

  handleSubmit = async (event) => {
    debugger;
    event.preventDefault();
    if (this.state.companies.length === 0) {
      alert("You need to create a company before saving a timesheet");
      return false;
    }
    if (!this.state.Company) {
      alert("Please select a production before saving your timesheet");
      return false;
    }
    if (parseFloat(this.state.EmailAttachmentsSize) > 4.5) {
      alert("Attachments size increasing the limit of 5 MB");
      return false;
    }

    if (this.state.DailyTimes.length === 0) {
      alert("Please select period ending date.");
      return false;
    }

    if (
      this.state.requiredFeilds.department === true &&
      this.state.Dept.length === 0
    ) {
      this.setState({ DeptValid: false });
      alert("Please select Department");
      return false;
    }

    if (
      this.state.requiredFeilds.position === true &&
      this.state.Position.length === 0
    ) {
      this.setState({ PositionValid: false });
      alert("Please add Position");
      return false;
    }

    if (this.state.TotalHours < "00:00") {
      alert("Total hours should not negative ");
      return false;
    }

    this.setState({ isLoading: true });
    //console.log("timesheetImage=>", timesheetImage);
    var customValidation = true;
    $(".custom-timecard-field")
      .filter("[required]:visible")
      .each(function (i, requiredField) {
        if ($(requiredField).attr("type") === "checkbox") {
          if ($(requiredField).is(":checked")) {
            $(requiredField).parent().parent().removeClass("field_required");
          } else {
            customValidation = false;
            $(requiredField).parent().parent().addClass("field_required");
          }
        } else {
          if ($(requiredField).val() === "") {
            customValidation = false;
            $(requiredField).parent().parent().addClass("field_required");
          } else {
            $(requiredField).parent().parent().removeClass("field_required");
          }
        }
      });

    if (!this.state.formValid || !customValidation) {
      alert("Please fill all required fields before saving your timesheet");
    }

    if (this.state.formValid && customValidation) {
      var Times = this.state.Times;
      Times[this.state.DailyTimesIndex] = {
        CustomTimeFields: this.state.TimesCustomTimeFields,
        DailyTimes: this.state.DailyTimes,
        TotalHours: this.state.TimesTotalHours,
      };

      const item = {
        Activity: this.state.Activity,
        Company: this.state.Company
          ? [
              {
                Guid: this.state.Company[0].Guid,
                Name: this.state.Company[0].Name
                  ? this.state.Company[0].Name
                  : "",
                Payas: this.state.Payas ? this.state.Payas : "",
                Production: this.state.Production
                  ? this.state.Production
                  : null,
              },
            ]
          : [],

        CustomTimeCardFields: this.state.CustomTimeCardFields,
        Dept: this.state.Dept ? this.state.Dept : "",
        FileAttachments: this.state.FileAttachments,
        PayFrequency: this.state.PayFrequency,
        PeriodEnding: this.state.PeriodEnding,
        Position: this.state.Position ? this.state.Position : "",
        Times: Times,
        TotalHours: this.state.TotalHours,
        Type: this.state.SubView === "Submit" ? "submitted" : "Draft",
        SysCoyGuid: this.state.SysCoyGuid,
      };
      //console.log("timecard item sent=>", item); return false;
      if (this.state.SubView === "Submit") {
        if (
          !this.state.SubmitEmailValid &&
          this.state.SubmitEmail.length === 0
        ) {
          this.setState({ isLoading: false });
          return false;
        }
      }
      //console.log('continue');
      try {
        var signature = await this.getUserSignature();

        if (
          this.state.requiredFeilds.signature === true &&
          signature === "" &&
          this.state.SubView === "Submit"
        ) {
          if (this.state.disabledTypedSig) {
            alert("Draw signature is required.");
          } else {
            alert("Signature is required.");
          }
          this.setState({
            isLoading: false,
            onHold: false,
            previewLoading: false,
          });
          return false;
        }
        this.setState({ UserSignatureUrl: signature });
        if (!this.state.Update) {
          const response = await this.createTimeCard(item);
          if (response.status) {
            this.setState({ InsertedId: response.result.Guid });
            if (this.state.SubView === "Submit") {
              const items = this.state.timecards;
              items.push(response.result);
              this.setState({ timecards: items });
              await this.handleSubmitEmail();
              console.log("this.state.SysCoyGuid=>",this.state.SysCoyGuid);
              if (this.state.SysCoyGuid) {
                setTimeout(
                  function () {
                    this.Digitaltimes_insert(response);
                  }.bind(this),
                  3000
                );
              }
              $("#SubmitSuccessModalBtn").click();
            } else {
              const items = this.state.timecards;
              items.push(response.result);
              this.setState({ timecards: items });
              console.log("Successfully saved.");
              this.clearTimeCardStats();
            }
            //this.Digitaltimes_insert(response);
            this.saveActivity("Timecard has been created");
          } else {
            console.log("Timecard save error=>", response);
            alert("Error accure please contact support.");
          }
        } else {
          const response = await this.updateTimeCard(item);
          if (response.status) {
            var timecards = this.state.timecards;
            this.setState({ InsertedId: response.result.Guid });
            if (this.state.SubView === "Submit") {
              await this.handleSubmitEmail();
              delete timecards[this.state.ItemIndex];
              this.setState({ timecards: timecards });
              console.log("this.state.SysCoyGuid=>",this.state.SysCoyGuid);
              if (this.state.SysCoyGuid) {
                setTimeout(
                  function () {
                    this.Digitaltimes_insert(response);
                  }.bind(this),
                  3000
                );
              }
              $("#SubmitSuccessModalBtn").click();
            } else {
              timecards[this.state.ItemIndex] = response.result;
              this.setState({ timecards: timecards });
              console.log("Successfully updated.");
              this.clearTimeCardStats();
            }
          } else {
            console.log("Timecard save error=>", response);
            alert("Error accure please contact support.");
          }
        }
      } catch (e) {
        //alert(e);
        console.log("Timecard save error=>", e);
        alert("Error accure please contact support.");
      }
      this.setState({ isLoading: false });
    } else {
      if (!this.state.formValid) {
        this.setState({ CompanyValid: false });
      }
      this.setState({ isLoading: false });
    }
  };

  findCompany(needle) {
    var companies = this.state.companies;
    for (var i = 0; i < companies.length; i++) {
      // look for the entry with a matching `code` value
      if (companies[i].Guid === needle) {
        // we found it
        return i;
      }
    }
    return false;
  }

  async setMrtFile(SysCoyGuid) {
    try {
      const item = await this.getSysCopmany(SysCoyGuid);
      if (item.status) {
        //console.log(item.result);
        this.setState({
          mrtFile: item.result.Timesheet ? item.result.Timesheet : "default",
          timeSheetName: item.result.timeSheetName
            ? item.result.timeSheetName
            : false,
          allowMealBreak: item.result.allowMealBreak
            ? item.result.allowMealBreak
            : false,
          disabledTypedSig: item.result.disabledTypedSig
            ? item.result.disabledTypedSig
            : false,
          requiredFeilds: item.result.requiredFeilds
            ? item.result.requiredFeilds
            : {
                signature: true,
                department: false,
                position: false,
              },
          departments: item.result.departments ? item.result.departments : [],
        });
      } else {
        this.setState({
          mrtFile: "default",
          timeSheetName: false,
          allowMealBreak: false,
          disabledTypedSig: false,
          requiredFeilds: {
            signature: true,
            department: false,
            position: false,
          },
          departments: [],
        });
        console.log("Error in fatching Syscompany: Invalid ID");
      }
    } catch (e) {
      console.log("Set Mrt File: " + e);
    }
  }

  async setEmailFileAttachments() {
    //console.log("FileAttachments=>", this.state.FileAttachments);
    this.state.FileAttachments.forEach(async (doc) => {
      this.setState({ isLoading: true });
      //console.log("email doc=>", doc);
      var that = this;
      var fetch_response = await this.props.postRequest("/attachments/fetch", {
        upload_path: doc.url,
      });
      if (fetch_response.status) {
        let response = await fetch(fetch_response.result);
        let data = await response.blob();
        let metadata = {
          type: doc.ContentType,
        };
        let file = new File([data], doc.filename, metadata);
        var sizeInMB = (file.size / (1024 * 1024)).toFixed(2);
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          var EmailAttachmentsSize = (
            parseFloat(that.state.EmailAttachmentsSize) + parseFloat(sizeInMB)
          ).toFixed(2);
          that.setState({ EmailAttachmentsSize: EmailAttachmentsSize });
          //console.log("base 64 on load=>", reader.result.replace(/^data:.+;base64,/, ''));
          that.state.EmailAttachments.push({
            filename: doc.filename,
            content: reader.result.replace(/^data:.+;base64,/, ""),
            encoding: "base64",
            size: sizeInMB,
          });
        };
        that.setState({ isLoading: false });
      } else {
        console.log(fetch_response);
        this.setState({ isLoading: false });
      }
    });
  }

  handleUpdate = (Id, index) => async (event) => {
    //alert(Id);
    //var currentDayDate = Math.floor(new Date().getTime()/1000);
    //console.log(currentDayDate);
    const item = this.state.timecards[index];
    /*var LastDayDate = item.Times[0].DailyTimes[6].Date;
    if(currentDayDate > LastDayDate){
      alert('yes');
    }else{
      alert('no');
    }*/
    //console.log("Update item=>",item); return false;
    var formValid = false;
    var company = "";
    var production = "";
    var SubmitEmail = "";
    var PayEnding = "Sunday";
    var cindex = "";
    var payas = this.state.PayasDefault;
    var citem = item.Company[0];

    if (item.Company.length > 0) {
      var cindex = await this.findCompany(citem.Guid);

      if (this.state.companies.length > 0) {
        if (cindex === false) {
          //var citem = this.state.companies[cindex];
          //console.log("selected company=>", citem); return false;
          //console.log(citem);
          var company = [
            {
              Guid: citem.Guid,
              Name: citem.Name,
              Payas: citem.Payas,
              Production: citem.Production,
            },
          ];

          this.setState({ mrtFile: "default", SubmitEmailValid: false });

          var payas = citem.Payas ? citem.Payas : this.state.PayasDefault;
          var production = citem.Production ? citem.Production : "";
          var formValid = true;
          var SubmitEmail = "";
          var SysCoyGuid = false;
        } else {
          //console.log("selected company=>", this.state.companies[cindex]); return false;
          if (this.state.companies[cindex].SysCoyGuid) {
            this.setMrtFile(this.state.companies[cindex].SysCoyGuid);
          } else {
            this.setState({ mrtFile: "default" });
          }
          //var citem = this.state.companies[cindex];
          //console.log(citem);
          var company = [
            {
              Guid: citem.Guid,
              Name: citem.Name,
              Payas: citem.Payas,
              Production: citem.Production,
            },
          ];
          //console.log(company);
          var payas = citem.Payas ? citem.Payas : this.state.PayasDefault;
          var production = citem.Production ? citem.Production : "";
          var formValid = true;
          var SubmitEmail = this.state.companies[cindex].EmailAddress
            ? this.state.companies[cindex].EmailAddress
            : "";
          var PayEnding = this.state.companies[cindex].PayEnding
            ? this.state.companies[cindex].PayEnding
            : "";
          var SysCoyGuid = this.state.companies[cindex].SysCoyGuid;
        }
      }
    }

    var DailyTimesIndex = item.Times.length - 1;
    var DailyTimes = item.Times[DailyTimesIndex].DailyTimes
      ? item.Times[DailyTimesIndex].DailyTimes
      : [];
    //console.log(item.Times);
    this.setState(
      {
        SubView: "Add",
        EmailAttachmentsSize: 0,
        SubmitEmail: SubmitEmail,
        formValid: formValid,
        Update: item.Guid ? item.Guid : "",
        ItemIndex: index,
        DailyTimesIndex: DailyTimesIndex,
        DailyTimesCopyIndex: DailyTimesIndex,
        DailyTimes: DailyTimes,
        TimesCustomTimeFields: item.Times[DailyTimesIndex].CustomTimeFields
          ? item.Times[DailyTimesIndex].CustomTimeFields
          : [],
        TimesTotalHours: item.Times[DailyTimesIndex].TotalHours
          ? item.Times[DailyTimesIndex].TotalHours
          : "0.00",
        Production: production,
        Activity: item.Activity ? item.Activity : "",
        CompanyIndex: cindex,
        Company: company,
        CompanyValid: true,
        CustomTimeCardFields: item.CustomTimeCardFields
          ? item.CustomTimeCardFields
          : [],
        Dept: item.Dept ? item.Dept : "",
        FileAttachments: item.FileAttachments ? item.FileAttachments : "",
        PayFrequency: item.PayFrequency ? item.PayFrequency : "Weekly",
        PeriodEndingMoment: item.PeriodEnding
          ? moment(
              String(new Date(item.PeriodEnding).getDate()).padStart(2, 0) +
                "/" +
                String(new Date(item.PeriodEnding).getMonth() + 1).padStart(
                  2,
                  0
                ) +
                "/" +
                new Date(item.PeriodEnding).getFullYear(),
              this.state.dateFormat
            )
          : "",
        PeriodEnding: item.PeriodEnding
          ? new Date(item.PeriodEnding).getFullYear() +
            "-" +
            String(new Date(item.PeriodEnding).getMonth() + 1).padStart(2, 0) +
            "-" +
            String(new Date(item.PeriodEnding).getDate()).padStart(2, 0)
          : "",
        //Pos: item.Pos? item.Pos : "",
        Position: item.Position ? item.Position : "",
        Times: item.Times ? item.Times : "",
        TotalHours: item.TotalHours ? item.TotalHours : "",
        Type: item.Type ? item.Type : "",
        PayEnding: PayEnding,
        Payas: payas,
        SysCoyGuid: SysCoyGuid,
      },
      () => {
        this.setEmailFileAttachments();
      }
    );
  };

  handleDeleteBtn = (id, index) => async (event) => {
    this.setState({ DeleteId: id, DeleteIndex: index });
    $("#exampleModalDeleteBtn").click();
  };

  handleDelete = async (event) => {
    //alert(this.state.DeleteId);
    try {
      const item = await this.deleteTimeCard();
      if (item.status) {
        //alert('Successfully Deleted.');
        var items = this.state.timecards;
        delete items[this.state.DeleteIndex];
        this.setState({
          DeleteId: false,
          DeleteIndex: false,
          timecards: items,
        });
      } else {
        console.log("Ooopsss....");
      }
    } catch (e) {
      console.log("Delete Timecard " + e);
    }
    $("#exampleModalDeleteClose").click();
  };

  handleChangePayasInline = (index) => async (event) => {
    const value = event.target.value;
    //console.log("payas value=>", value);
    let day = this.state.DailyTimes[index];
    let DailyTimes = this.state.DailyTimes;
    //console.log(day.Day);
    var dayTimeObj = {
      CustomTimeFields: day.CustomTimeFields,
      Date: day.Date,
      Day: day.Day,
      Finish: day.Finish,
      FinishMeal1: day.FinishMeal1,
      FinishMeal2: day.FinishMeal2,
      FinishMeal3: day.FinishMeal3,
      MB1ND: day.MB1ND,
      MB2ND: day.MB2ND,
      MB3ND: day.MB3ND,
      Note: day.Note,
      customNote: day.Note,
      Payas: value,
      Start: day.Start,
      StartMeal: day.StartMeal,
      StartMeal2: day.StartMeal2,
      StartMeal3: day.StartMeal3,
      TimeStamp: day.TimeStamp ? day.TimeStamp : new Date().getTime() / 1000,
      TotalHours: day.TotalHours,
      TotalMBDed: day.TotalMBDed,
      Travel1: day.Travel1,
      Travel2: day.Travel2,
    };
    DailyTimes[index] = dayTimeObj;

    console.log("DailyTimes obj=>", dayTimeObj);
    this.setState({ DailyTimes: DailyTimes });
  };

  handleChangeNoteInline = (index) => async (event) => {
    const value = event.target.value;
    //const index = event.target.getAttribute('data-index');
    let day = this.state.DailyTimes[index];
    let DailyTimes = this.state.DailyTimes;
    console.log(day.Day);
    var customNote = null;
    if (value !== "") {
      day.Note = value.replace(day.Payas + " ", "");
      if (day.Payas === "0" && this.state.Payas[0]) {
        day.Payas = this.state.Payas[0].Value;
      }
    } else {
      day.Note = null;
    }

    var dayTimeObj = {
      CustomTimeFields: day.CustomTimeFields,
      Date: day.Date,
      Day: day.Day,
      Finish: day.Finish,
      FinishMeal1: day.FinishMeal1,
      FinishMeal2: day.FinishMeal2,
      FinishMeal3: day.FinishMeal3,
      MB1ND: day.MB1ND,
      MB2ND: day.MB2ND,
      MB3ND: day.MB3ND,
      Note: day.Note,
      customNote: day.Note ? day.Payas + " " + day.Note : null,
      Payas: day.Payas,
      Start: day.Start,
      StartMeal: day.StartMeal,
      StartMeal2: day.StartMeal2,
      StartMeal3: day.StartMeal3,
      TimeStamp: day.TimeStamp ? day.TimeStamp : new Date().getTime() / 1000,
      TotalHours: day.TotalHours,
      TotalMBDed: day.TotalMBDed,
      Travel1: day.Travel1,
      Travel2: day.Travel2,
    };

    DailyTimes[index] = dayTimeObj;

    //console.log('DailyTimes obj=>', dayTimeObj);
    this.setState({ DailyTimes: DailyTimes });
  };

  handleSaveDayTimeInline = async (event) => {
    var index = this.state.DayTimeIndex;
    var customValidation = true;

    if (customValidation === true) {
      let DailyTimes = this.state.DailyTimes;
      //console.log(day.Day);

      var dayTimeObj = {
        CustomTimeFields: this.state.DayTimeCustomTimeFields,
        Date: this.state.DayTimeDate
          ? new Date(this.state.DayTimeDate).getTime() / 1000
          : "",
        Day: this.state.DayTimeDay,
        Finish: this.state.DayTimeFinish._i,
        FinishMeal1: this.state.DayTimeFinishMeal1.format("HH:mm"),
        FinishMeal2: this.state.DayTimeFinishMeal2.format("HH:mm"),
        FinishMeal3: this.state.DayTimeFinishMeal3.format("HH:mm"),
        MB1ND: this.state.DayTimeMB1ND ? "1" : "0",
        MB2ND: this.state.DayTimeMB2ND ? "1" : "0",
        MB3ND: this.state.DayTimeMB3ND ? "1" : "0",
        Note: this.state.DayTimeNote,
        customNote:
          this.state.DayTimePayas +
          " " +
          (this.state.DayTimeNote ? this.state.DayTimeNote : ""),
        Payas: this.state.DayTimePayas,
        Start: this.state.DayTimeStart._i,
        StartMeal: this.state.DayTimeStartMeal.format("HH:mm"),
        StartMeal2: this.state.DayTimeStartMeal2.format("HH:mm"),
        StartMeal3: this.state.DayTimeStartMeal3.format("HH:mm"),
        TimeStamp: new Date().getTime() / 1000,
        TotalHours: this.state.DayTimeTotalHours,
        TotalMBDed: this.state.DayTimeTotalMBDed.format("HH:mm"),
        Travel1: this.state.DayTimeTravel1.format("HH:mm"),
        Travel2: this.state.DayTimeTravel2.format("HH:mm"),
      };

      DailyTimes[index] = dayTimeObj;

      //console.log('DailyTimes onj=>', dayTimeObj);
      //this.setState({DailyTimes: DailyTimes, DayTimeCustomTimeFields: []});

      this.updateTotalHours();
    }
  };

  handleDayTimeInline = (index, SubView) => async (event) => {
    //alert(index);
    console.log("handleDayTimeInline Index", index);
    if (index !== this.state.DayTimeIndex) {
      this.setState({ DayTimeCustomTimeFields: [] });
      let day = this.state.DailyTimes[index];
      console.log("day =>", day);
      //console.log(moment(day.Finish, this.state.format));
      var CustomTimeFields = day.CustomTimeFields
        ? day.CustomTimeFields
        : JSON.parse(JSON.stringify(this.state.TimesCustomTimeFields));

      this.setState({
        SaveDayTimeError: "",
        SubView: SubView,
        DayTimeIndex: index,
        DayTimeCopyIndex: index,
        DayTimeDateMoment: day.Date
          ? moment(this.getDateFormate(day.Date, 2), this.state.dateFormat)
          : "",
        DayTimeDate: day.Date ? this.getDateFormate(day.Date, 1) : "",
        DayTimeDay: day.Day,
        DayTimeFinish: day.Finish
          ? moment(day.Finish, this.state.format)
          : moment("00:00", this.state.format),
        DayTimeFinishMeal1: day.FinishMeal1
          ? moment(day.FinishMeal1, this.state.format)
          : moment("00:00", this.state.format),
        DayTimeFinishMeal2: day.FinishMeal2
          ? moment(day.FinishMeal2, this.state.format)
          : moment("00:00", this.state.format),
        DayTimeFinishMeal3: day.FinishMeal3
          ? moment(day.FinishMeal3, this.state.format)
          : moment("00:00", this.state.format),
        DayTimeMB1ND: day.MB1ND === "0" ? false : true,
        DayTimeMB2ND: day.MB2ND === "0" ? false : true,
        DayTimeMB3ND: day.MB3ND === "0" ? false : true,
        DayTimeNote: day.Note ? day.Note : null,
        DayTimePayas: day.Payas ? day.Payas : "0",
        DayTimeStart: day.Start
          ? moment(day.Start, this.state.format)
          : moment("00:00", this.state.format),
        DayTimeStartMeal: day.StartMeal
          ? moment(day.StartMeal, this.state.format)
          : moment("00:00", this.state.format),
        DayTimeStartMeal2: day.StartMeal2
          ? moment(day.StartMeal2, this.state.format)
          : moment("00:00", this.state.format),
        DayTimeStartMeal3: day.StartMeal3
          ? moment(day.StartMeal3, this.state.format)
          : moment("00:00", this.state.format),
        DayTimeTimeStamp: day.TimeStamp,
        DayTimeTotalHours: day.TotalHours ? day.TotalHours : "00:00",
        DayTimeTotalMBDed: day.TotalMBDed
          ? moment(day.TotalMBDed, this.state.format)
          : moment("00:00", this.state.format),
        DayTimeTravel1: day.Travel1
          ? moment(day.Travel1, this.state.format)
          : moment("00:00", this.state.format),
        DayTimeTravel2: day.Travel2
          ? moment(day.Travel2, this.state.format)
          : moment("00:00", this.state.format),
        DayTimeCustomTimeFields: CustomTimeFields,
      });

      setTimeout(
        function () {
          console.log(this.state.DayTimeCustomTimeFields);
          this.updateTotalMBDedDisabledState();
        }.bind(this),
        1000
      );
    }
  };

  handleDayTime = (index, SubView) => async (event) => {
    //alert(index);
    console.log("handleDayTime Index", index);
    this.setState({ DayTimeCustomTimeFields: [] });
    let day = this.state.DailyTimes[index];
    console.log("day =>", day);
    //console.log(moment(day.Finish, this.state.format));
    var CustomTimeFields = day.CustomTimeFields
      ? day.CustomTimeFields
      : JSON.parse(JSON.stringify(this.state.TimesCustomTimeFields));

    this.setState({
      SaveDayTimeError: "",
      SubView: SubView,
      DayTimeIndex: index,
      DayTimeCopyIndex: index,
      DayTimeDateMoment: day.Date
        ? moment(this.getDateFormate(day.Date, 2), this.state.dateFormat)
        : "",
      DayTimeDate: day.Date ? this.getDateFormate(day.Date, 1) : "",
      DayTimeDay: day.Day,
      DayTimeFinish: day.Finish
        ? moment(day.Finish, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeFinishType: day.Finish
        ? moment(day.Finish, this.state.format).format(this.state.format)
        : "00:00",
      DayTimeFinishMeal1: day.FinishMeal1
        ? moment(day.FinishMeal1, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeFinishMeal2: day.FinishMeal2
        ? moment(day.FinishMeal2, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeFinishMeal3: day.FinishMeal3
        ? moment(day.FinishMeal3, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeFinishMeal1Type: day.FinishMeal1
        ? moment(day.FinishMeal1, this.state.format).format(this.state.format)
        : "00:00",
      DayTimeFinishMeal2Type: day.FinishMeal2
        ? moment(day.FinishMeal2, this.state.format).format(this.state.format)
        : "00:00",
      DayTimeFinishMeal3Type: day.FinishMeal3
        ? moment(day.FinishMeal3, this.state.format).format(this.state.format)
        : "00:00",
      DayTimeMB1ND: day.MB1ND === "0" ? false : true,
      DayTimeMB2ND: day.MB2ND === "0" ? false : true,
      DayTimeMB3ND: day.MB3ND === "0" ? false : true,
      DayTimecustomNote: day.customNote ? day.customNote : null,
      DayTimeNote: day.Note ? day.Note : null,
      DayTimePayas: day.Payas ? day.Payas : "0",
      DayTimeStart: day.Start
        ? moment(day.Start, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeStartType: day.Start
        ? moment(day.Start, this.state.format).format(this.state.format)
        : "00:00",
      DayTimeStartMeal: day.StartMeal
        ? moment(day.StartMeal, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeStartMeal2: day.StartMeal2
        ? moment(day.StartMeal2, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeStartMeal3: day.StartMeal3
        ? moment(day.StartMeal3, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeStartMealType: day.StartMeal
        ? moment(day.StartMeal, this.state.format).format(this.state.format)
        : "00:00",
      DayTimeStartMeal2Type: day.StartMeal2
        ? moment(day.StartMeal2, this.state.format).format(this.state.format)
        : "00:00",
      DayTimeStartMeal3Type: day.StartMeal3
        ? moment(day.StartMeal3, this.state.format).format(this.state.format)
        : "00:00",
      DayTimeTimeStamp: day.TimeStamp,
      DayTimeTotalHours: day.TotalHours ? day.TotalHours : "00:00",
      DayTimeTotalMBDed: day.TotalMBDed
        ? moment(day.TotalMBDed, this.state.format)
        : moment("00:00", this.state.format),

      DayTimeTotalMBDedType: day.TotalMBDed
        ? moment(day.TotalMBDed, this.state.format).format(this.state.format)
        : "00:00",
      DayTimeTravel1: day.Travel1
        ? moment(day.Travel1, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeTravel2: day.Travel2
        ? moment(day.Travel2, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeTravel1Type: day.Travel1
        ? moment(day.Travel1, this.state.format).format(this.state.format)
        : "00:00",
      DayTimeTravel2Type: day.Travel2
        ? moment(day.Travel2, this.state.format).format(this.state.format)
        : "00:00",
      DayTimeCustomTimeFields: CustomTimeFields,
    });

    setTimeout(
      function () {
        console.log(this.state.DayTimeCustomTimeFields);
        this.updateTotalMBDedDisabledState();
      }.bind(this),
      1000
    );

    if (SubView === "Add") $("#WeekTimesModalCenterBtn").click();
  };

  handleSaveDayTime = (index, SubView) => async (event) => {
    //alert(index);
    //alert(index);
    //console.log(this.state.DayTimeFinish._i); return false;
    var customValidation = true;
    $(".custom-time-field")
      .filter("[required]:visible")
      .each(function (i, requiredField) {
        if ($(requiredField).attr("type") === "checkbox") {
          if ($(requiredField).is(":checked")) {
            $(requiredField).parent().parent().removeClass("field_required");
          } else {
            customValidation = false;
            $(requiredField).parent().parent().addClass("field_required");
          }
        } else {
          if ($(requiredField).val() === "") {
            customValidation = false;
            $(requiredField).parent().parent().addClass("field_required");
          } else {
            $(requiredField).parent().parent().removeClass("field_required");
          }
        }
      });

    this.setState({ SaveDayTimeError: "" });

    if (this.state.DayTimePayas !== "0" && customValidation === true) {
      let DailyTimes = this.state.DailyTimes;

      var dayTimeObj = {
        CustomTimeFields: this.state.DayTimeCustomTimeFields,
        Date: this.state.DayTimeDate
          ? new Date(this.state.DayTimeDate).getTime() / 1000
          : "",
        Day: this.state.DayTimeDay,
        Finish: this.state.DayTimeFinish._i,
        FinishMeal1: this.state.DayTimeFinishMeal1.format("HH:mm"),
        FinishMeal2: this.state.DayTimeFinishMeal2.format("HH:mm"),
        FinishMeal3: this.state.DayTimeFinishMeal3.format("HH:mm"),
        MB1ND: this.state.DayTimeMB1ND ? "1" : "0",
        MB2ND: this.state.DayTimeMB2ND ? "1" : "0",
        MB3ND: this.state.DayTimeMB3ND ? "1" : "0",
        customNote:
          this.state.DayTimePayas +
          " " +
          (this.state.DayTimeNote ? this.state.DayTimeNote : ""),
        Note: this.state.DayTimeNote ? this.state.DayTimeNote : null,
        Payas: this.state.DayTimePayas,
        Start: this.state.DayTimeStart._i,
        StartMeal: this.state.DayTimeStartMeal.format("HH:mm"),
        StartMeal2: this.state.DayTimeStartMeal2.format("HH:mm"),
        StartMeal3: this.state.DayTimeStartMeal3.format("HH:mm"),
        TimeStamp: new Date().getTime() / 1000,
        TotalHours: this.state.DayTimeTotalHours,
        TotalMBDed: this.state.DayTimeTotalMBDed.format("HH:mm"),
        Travel1: this.state.DayTimeTravel1.format("HH:mm"),
        Travel2: this.state.DayTimeTravel2.format("HH:mm"),
      };

      DailyTimes[index] = dayTimeObj;

      console.log("saveDayTIme =>", DailyTimes);
      this.setState({ DailyTimes: DailyTimes });

      this.updateTotalHours();

      if (SubView === "Add") {
        $("#WeekTimesModalCenterClose").click();
      } else {
        this.setState({ SubView: SubView });
      }
    } else {
      if (this.state.DayTimePayas === "0") {
        this.setState({ SaveDayTimeError: "Payas is required." });
      }
    }
  };

  handleRequiredElements() {
    console.log($(":input[required]:visible"));
  }

  clearTimeCardStats() {
    this.setState({
      DailyTimesIndex: 0,
      DailyTimesCopyIndex: 0,
      SubView: "List",
      Update: false,
      ItemIndex: false,
      DailyTimes: [],
      TimesCustomTimeFields: [],
      DayTimeCustomTimeFields: [],
      TimesTotalHours: "0.00",
      files: [],
      Production: "",
      Activity: "",
      Company: "",
      CustomTimeCardFields: [],
      Dept: "",
      FileAttachments: [],
      EmailAttachments: [],
      PayFrequency: "Weekly",
      PeriodEndingMoment: "",
      PeriodEnding: "",
      Position: "",
      Times: [],
      TotalHours: "0.00",
      Type: "Draft",
      InsertedId: "",
      PayEnding: "",
    });
  }
  handlePeriodEnding(value, dateString) {
    //console.log(value);
    //console.log(value.format('MM/DD/YYYY'));
    this.setState({
      PeriodEndingMoment: value,
      PeriodEnding: value.format("YYYY-MM-DD"),
      TotalHours: "00:00",
      calendarPeriodEndingMobile: false,
      calendarPeriodEndingWeb: false,
    });
    setTimeout(
      function () {
        this.setState({ DailyTimes: this.setDailyTimes() });
      }.bind(this),
      1000
    );
  }

  handleDayTimeDate(value, dateString) {
    var d = new Date(dateString);
    var weekday = new Array(7);
    weekday[0] = "Sunday";
    weekday[1] = "Monday";
    weekday[2] = "Tuesday";
    weekday[3] = "Wednesday";
    weekday[4] = "Thursday";
    weekday[5] = "Friday";
    weekday[6] = "Saturday";

    this.setState({
      DayTimeDateMoment: value,
      DayTimeDate: dateString,
      DayTimeDay: weekday[d.getDay()],
    });
  }

  handleChangeDayTimeTravel1(time, timeString) {
    console.log("handleChangeDayTimeTravel1 time=>", time);
    console.log("handleChangeDayTimeTravel1 timeString=>", timeString);

    var Travel1Duration = moment.duration(timeString, "HH:mm");
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    console.log(
      "handleChangeDayTimeTravel1 Travel1Duration=>",
      Travel1Duration
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");

    if (startTime > endTime) {
      endTime.add(24, "hours");
    }

    if (this.state.TotalMBDedDisabled) {
      if (!this.state.DayTimeMB1ND) {
        var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
        var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
        if (startTimeMB1 > endTimeMB1) {
          endTimeMB1.add(24, "hours");
        }
        var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
        endTime.subtract(durationMB1);
        //console.log(durationMB1);
      }

      if (!this.state.DayTimeMB2ND) {
        var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
        var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
        if (startTimeMB2 > endTimeMB2) {
          endTimeMB2.add(24, "hours");
        }
        var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
        endTime.subtract(durationMB2);
      }

      if (!this.state.DayTimeMB3ND) {
        var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
        var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
        if (startTimeMB3 > endTimeMB3) {
          endTimeMB3.add(24, "hours");
        }
        var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
        endTime.subtract(durationMB3);
      }
    } else {
      var TotalMBDedDuration = moment.duration(
        this.state.DayTimeTotalMBDed.format("HH:mm"),
        "HH:mm"
      );
      //console.log(TotalMBDedDuration);
      endTime.subtract(TotalMBDedDuration);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    this.setState({
      DayTimeTravel1: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
    });
  }

  handleChangeDayTimeTravel2(time, timeString) {
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(timeString, "HH:mm");
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");
    if (startTime > endTime) {
      endTime.add(24, "hours");
    }

    if (this.state.TotalMBDedDisabled) {
      if (!this.state.DayTimeMB1ND) {
        var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
        var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
        if (startTimeMB1 > endTimeMB1) {
          endTimeMB1.add(24, "hours");
        }
        var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
        endTime.subtract(durationMB1);
        //console.log(durationMB1);
      }

      if (!this.state.DayTimeMB2ND) {
        var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
        var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
        if (startTimeMB2 > endTimeMB2) {
          endTimeMB2.add(24, "hours");
        }
        var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
        endTime.subtract(durationMB2);
      }

      if (!this.state.DayTimeMB3ND) {
        var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
        var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
        if (startTimeMB3 > endTimeMB3) {
          endTimeMB3.add(24, "hours");
        }
        var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
        endTime.subtract(durationMB3);
      }
    } else {
      var TotalMBDedDuration = moment.duration(
        this.state.DayTimeTotalMBDed.format("HH:mm"),
        "HH:mm"
      );
      //console.log(TotalMBDedDuration);
      endTime.subtract(TotalMBDedDuration);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    this.setState({
      DayTimeTravel2: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
    });
  }

  handleChangeDayTimeType = (type) => async (event) => {
    const value = event.target.value;
    if (type === "Start") {
      this.setState({
        DayTimeStartType: value,
      });
    }
    if (type === "Finish") {
      this.setState({
        DayTimeFinishType: value,
      });
    }
    if (type === "TotalMBDed") {
      this.setState({
        DayTimeTotalMBDedType: value,
      });
    }
    if (type === "Travel1") {
      this.setState({
        DayTimeTravel1Type: value,
      });
    }
    if (type === "Travel2") {
      this.setState({
        DayTimeTravel2Type: value,
      });
    }
    if (type === "StartMeal") {
      this.setState({
        DayTimeStartMealType: value,
      });
    }
    if (type === "FinishMeal1") {
      this.setState({
        DayTimeFinishMeal1Type: value,
      });
    }
    if (type === "StartMeal2") {
      this.setState({
        DayTimeStartMeal2Type: value,
      });
    }
    if (type === "FinishMeal2") {
      this.setState({
        DayTimeFinishMeal2Type: value,
      });
    }
    if (type === "StartMeal3") {
      this.setState({
        DayTimeStartMeal3Type: value,
      });
    }
    if (type === "FinishMeal3") {
      this.setState({
        DayTimeFinishMeal3Type: value,
      });
    }
  };

  updateTimeType = (type) => async (event) => {
    var value = event.target.value;
    var isValid = true;
    if (value.length > 5) {
      isValid = false;
    } else {
      let timeParts = value.split(/[:,.]/);
      console.log(timeParts);
      if (timeParts.length > 2) {
        isValid = false;
      } else if (timeParts.length === 1) {
        value = ("0000" + value).slice(-4);
        value = value.substring(0, 2) + ":" + value.substring(2, value.length);
      } else {
        value =
          ("00" + timeParts[0]).slice(-2) +
          ":" +
          ("00" + timeParts[1]).slice(-2);
        //value = value.substring(0, 2) + ":" + value.substring(2, value.length);
      }
    }
    //console.log("update value=>", value);
    //moment("2014-12-13 asdasd","YYYY-MM-DD LT", true).isValid()
    //console.log("Time is valid=>",moment(value, this.state.format, true).isValid());
    if (!moment(value, this.state.format, true).isValid() || !isValid) {
      value = "00:00";
      alert("Time string is not valid.");
    }
    //console.log("Update Time=>",moment(value, this.state.format));
    if (type === "Start") {
      this.setState({
        DayTimeStartType: value,
      });
      this.handleChangeDayTimeStart(moment(value, this.state.format), value);
    } else if (type === "Finish") {
      this.setState({
        DayTimeFinishType: value,
      });
      this.handleChangeDayTimeFinish(moment(value, this.state.format), value);
    } else if (type === "TotalMBDed") {
      this.setState({
        DayTimeTotalMBDedType: value,
      });
      this.handleChangeDayTimeTotalMBDed(
        moment(value, this.state.format),
        value
      );
    } else if (type === "Travel1") {
      this.setState({
        DayTimeTravel1Type: value,
      });
      this.handleChangeDayTimeTravel1(moment(value, this.state.format), value);
    } else if (type === "Travel2") {
      this.setState({
        DayTimeTravel2Type: value,
      });
      this.handleChangeDayTimeTravel2(moment(value, this.state.format), value);
    } else if (type === "StartMeal") {
      this.setState({
        DayTimeStartMealType: value,
      });
      this.handleChangeDayTimeStartMeal(
        moment(value, this.state.format),
        value
      );
    } else if (type === "FinishMeal1") {
      this.setState({
        DayTimeFinishMeal1Type: value,
      });
      this.handleChangeDayTimeFinishMeal1(
        moment(value, this.state.format),
        value
      );
    } else if (type === "StartMeal2") {
      this.setState({
        DayTimeStartMeal2Type: value,
      });
      this.handleChangeDayTimeStartMeal2(
        moment(value, this.state.format),
        value
      );
    } else if (type === "FinishMeal2") {
      this.setState({
        DayTimeFinishMeal2Type: value,
      });
      this.handleChangeDayTimeFinishMeal2(
        moment(value, this.state.format),
        value
      );
    } else if (type === "StartMeal3") {
      this.setState({
        DayTimeStartMeal3Type: value,
      });
      this.handleChangeDayTimeStartMeal3(
        moment(value, this.state.format),
        value
      );
    } else if (type === "FinishMeal3") {
      this.setState({
        DayTimeFinishMeal3Type: value,
      });
      this.handleChangeDayTimeFinishMeal3(
        moment(value, this.state.format),
        value
      );
    }
  };

  handleChangeDayTimeTypeInline = (index, type) => async (event) => {
    const value = event.target.value;
    let day = this.state.DailyTimes[index];
    let DailyTimes = this.state.DailyTimes;
    //console.log(day.Day);
    var dayTimeObj = {
      CustomTimeFields: day.CustomTimeFields,
      Date: day.Date,
      Day: day.Day,
      Finish: type === "Finish" ? value : day.Finish,
      FinishMeal1: day.FinishMeal1,
      FinishMeal2: day.FinishMeal2,
      FinishMeal3: day.FinishMeal3,
      MB1ND: day.MB1ND,
      MB2ND: day.MB2ND,
      MB3ND: day.MB3ND,
      Note: day.Note,
      customNote: day.Note,
      Payas: day.Payas,
      Start: type === "Start" ? value : day.Start,
      StartMeal: day.StartMeal,
      StartMeal2: day.StartMeal2,
      StartMeal3: day.StartMeal3,
      TimeStamp: day.TimeStamp ? day.TimeStamp : new Date().getTime() / 1000,
      TotalHours: day.TotalHours,
      TotalMBDed: type === "TotalMBDed" ? value : day.TotalMBDed,
      Travel1: day.Travel1,
      Travel2: day.Travel2,
    };
    DailyTimes[index] = dayTimeObj;

    console.log("DailyTimes obj=>", dayTimeObj);
    this.setState({ DailyTimes: DailyTimes });
  };

  updateTimeTypeInline = (index, type) => async (event) => {
    var value = event.target.value;
    var isValid = true;
    if (value.length > 5) {
      isValid = false;
    } else {
      let timeParts = value.split(/[:,.]/);
      console.log(timeParts);
      if (timeParts.length > 2) {
        isValid = false;
      } else if (timeParts.length === 1) {
        value = ("0000" + value).slice(-4);
        value = value.substring(0, 2) + ":" + value.substring(2, value.length);
      } else {
        value =
          ("00" + timeParts[0]).slice(-2) +
          ":" +
          ("00" + timeParts[1]).slice(-2);
        //value = value.substring(0, 2) + ":" + value.substring(2, value.length);
      }
    }
    //console.log("update value=>", value);
    //moment("2014-12-13 asdasd","YYYY-MM-DD LT", true).isValid()
    //console.log("Time is valid=>",moment(value, this.state.format, true).isValid());
    if (!moment(value, this.state.format, true).isValid() || !isValid) {
      value = "00:00";
      alert("Time string is not valid.");
    }
    //console.log("Update Time=>",moment(value, this.state.format));
    if (type === "Start") {
      this.handleChangeDayTimeStartInline(
        moment(value, this.state.format),
        value
      );
    } else if (type === "Finish") {
      this.handleChangeDayTimeFinishInline(
        moment(value, this.state.format),
        value
      );
    } else if (type === "TotalMBDed") {
      this.handleChangeDayTimeTotalMBDedInline(
        moment(value, this.state.format),
        value
      );
    }
  };

  handleChangeDayTimeStartInline(time, timeString) {
    //this.state.DayTimePayas
    if (this.state.DayTimePayas === "0" && this.state.Payas[0]) {
      this.state.DayTimePayas = this.state.Payas[0].Value;
    }
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(timeString, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");

    if (startTime > endTime) {
      endTime.add(24, "hours");
    }

    if (this.state.TotalMBDedDisabled) {
      if (!this.state.DayTimeMB1ND) {
        var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
        var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
        if (startTimeMB1 > endTimeMB1) {
          endTimeMB1.add(24, "hours");
        }
        var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
        endTime.subtract(durationMB1);
        //console.log(durationMB1);
      }

      if (!this.state.DayTimeMB2ND) {
        var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
        var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
        if (startTimeMB2 > endTimeMB2) {
          endTimeMB2.add(24, "hours");
        }
        var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
        endTime.subtract(durationMB2);
      }

      if (!this.state.DayTimeMB3ND) {
        var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
        var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
        if (startTimeMB3 > endTimeMB3) {
          endTimeMB3.add(24, "hours");
        }
        var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
        endTime.subtract(durationMB3);
      }
    } else {
      var TotalMBDedDuration = moment.duration(
        this.state.DayTimeTotalMBDed.format("HH:mm"),
        "HH:mm"
      );
      //console.log(TotalMBDedDuration);
      endTime.subtract(TotalMBDedDuration);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    this.setState({
      DayTimeStart: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
    });
    setTimeout(
      function () {
        this.handleSaveDayTimeInline();
      }.bind(this),
      1000
    );
  }

  handleChangeDayTimeFinishInline(time, timeString) {
    if (this.state.DayTimePayas === "0" && this.state.Payas[0]) {
      this.state.DayTimePayas = this.state.Payas[0].Value;
    }
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(timeString, "HH:mm");

    if (startTime > endTime) {
      endTime.add(24, "hours");
    }

    if (this.state.TotalMBDedDisabled) {
      if (!this.state.DayTimeMB1ND) {
        var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
        var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
        if (startTimeMB1 > endTimeMB1) {
          endTimeMB1.add(24, "hours");
        }
        var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
        endTime.subtract(durationMB1);
        //console.log(durationMB1);
      }

      if (!this.state.DayTimeMB2ND) {
        var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
        var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
        if (startTimeMB2 > endTimeMB2) {
          endTimeMB2.add(24, "hours");
        }
        var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
        endTime.subtract(durationMB2);
      }

      if (!this.state.DayTimeMB3ND) {
        var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
        var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
        if (startTimeMB3 > endTimeMB3) {
          endTimeMB3.add(24, "hours");
        }
        var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
        endTime.subtract(durationMB3);
      }
    } else {
      var TotalMBDedDuration = moment.duration(
        this.state.DayTimeTotalMBDed.format("HH:mm"),
        "HH:mm"
      );
      //console.log(TotalMBDedDuration);
      endTime.subtract(TotalMBDedDuration);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    this.setState({
      DayTimeFinish: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
    });
    setTimeout(
      function () {
        this.handleSaveDayTimeInline();
      }.bind(this),
      1000
    );
  }

  handleChangeDayTimeTotalMBDedInline(time, timeString) {
    if (this.state.DayTimePayas === "0" && this.state.Payas[0]) {
      this.state.DayTimePayas = this.state.Payas[0].Value;
    }
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");
    if (startTime > endTime) {
      endTime.add(24, "hours");
    }
    var TotalMBDedDuration = moment.duration(timeString, "HH:mm");
    endTime.subtract(TotalMBDedDuration);

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);

    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours>=0?hours:-hours).padStart(2, 0) + ":" + String(minutes>=0?minutes:-minutes).padStart(2, 0);

    this.setState({
      DayTimeTotalMBDed: moment(timeString, this.state.format),
      DayTimeTotalHours: (hours>=0&&minutes>=0)?DayTimeTotalHours:"-"+DayTimeTotalHours,
    });
    setTimeout(
      function () {
        this.handleSaveDayTimeInline();
      }.bind(this),
      1000
    );
  }

  handleChangeDayTimeStart(time, timeString) {
    if (this.state.DayTimePayas === "0" && this.state.Payas[0]) {
      this.state.DayTimePayas = this.state.Payas[0].Value;
    }
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(timeString, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");

    if (startTime > endTime) {
      endTime.add(24, "hours");
    }

    if (this.state.TotalMBDedDisabled) {
      if (!this.state.DayTimeMB1ND) {
        var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
        var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
        if (startTimeMB1 > endTimeMB1) {
          endTimeMB1.add(24, "hours");
        }
        var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
        endTime.subtract(durationMB1);
        //console.log(durationMB1);
      }

      if (!this.state.DayTimeMB2ND) {
        var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
        var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
        if (startTimeMB2 > endTimeMB2) {
          endTimeMB2.add(24, "hours");
        }
        var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
        endTime.subtract(durationMB2);
      }

      if (!this.state.DayTimeMB3ND) {
        var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
        var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
        if (startTimeMB3 > endTimeMB3) {
          endTimeMB3.add(24, "hours");
        }
        var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
        endTime.subtract(durationMB3);
      }
    } else {
      var TotalMBDedDuration = moment.duration(
        this.state.DayTimeTotalMBDed.format("HH:mm"),
        "HH:mm"
      );
      //console.log(TotalMBDedDuration);
      endTime.subtract(TotalMBDedDuration);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    this.setState({
      DayTimeStart: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
    });
  }

  handleChangeDayTimeFinish(time, timeString) {
    if (this.state.DayTimePayas === "0" && this.state.Payas[0]) {
      this.state.DayTimePayas = this.state.Payas[0].Value;
    }
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(timeString, "HH:mm");

    if (startTime > endTime) {
      endTime.add(24, "hours");
    }

    if (this.state.TotalMBDedDisabled) {
      if (!this.state.DayTimeMB1ND) {
        var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
        var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
        if (startTimeMB1 > endTimeMB1) {
          endTimeMB1.add(24, "hours");
        }
        var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
        endTime.subtract(durationMB1);
        //console.log(durationMB1);
      }

      if (!this.state.DayTimeMB2ND) {
        var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
        var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
        if (startTimeMB2 > endTimeMB2) {
          endTimeMB2.add(24, "hours");
        }
        var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
        endTime.subtract(durationMB2);
      }

      if (!this.state.DayTimeMB3ND) {
        var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
        var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
        if (startTimeMB3 > endTimeMB3) {
          endTimeMB3.add(24, "hours");
        }
        var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
        endTime.subtract(durationMB3);
      }
    } else {
      var TotalMBDedDuration = moment.duration(
        this.state.DayTimeTotalMBDed.format("HH:mm"),
        "HH:mm"
      );
      //console.log(TotalMBDedDuration);
      endTime.subtract(TotalMBDedDuration);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    this.setState({
      DayTimeFinish: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
    });
  }

  handleChangeDayTimeStartMeal(time, timeString) {
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");
    if (startTime > endTime) {
      endTime.add(24, "hours");
    }
    var TotalMBDed = moment.duration("00:00", "HH:mm");

    var startTimeMB1 = moment(time, "HH:mm");
    var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
    if (startTimeMB1 > endTimeMB1) {
      endTimeMB1.add(24, "hours");
    }
    var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
    TotalMBDed.add(durationMB1);

    var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
    var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
    if (startTimeMB2 > endTimeMB2) {
      endTimeMB2.add(24, "hours");
    }
    var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
    TotalMBDed.add(durationMB2);

    var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
    var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
    if (startTimeMB3 > endTimeMB3) {
      endTimeMB3.add(24, "hours");
    }
    var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
    TotalMBDed.add(durationMB3);

    if (!this.state.DayTimeMB1ND) {
      endTime.subtract(durationMB1);
    }

    if (!this.state.DayTimeMB2ND) {
      endTime.subtract(durationMB2);
    }

    if (!this.state.DayTimeMB3ND) {
      endTime.subtract(durationMB3);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    var hoursmbded = parseInt(TotalMBDed.asHours(), 10);
    var minutesmbded = parseInt(TotalMBDed.asMinutes(), 10) % 60;
    var DayTimeTotalMBDed =
      String(hoursmbded).padStart(2, 0) +
      ":" +
      String(minutesmbded).padStart(2, 0);

    this.setState({
      DayTimeStartMeal: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
      DayTimeTotalMBDed: moment(DayTimeTotalMBDed, this.state.format),
    });
    setTimeout(
      function () {
        this.updateTotalMBDedDisabledState();
      }.bind(this),
      1000
    );
  }

  handleChangeDayTimeFinishMeal1(time, timeString) {
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");
    if (startTime > endTime) {
      endTime.add(24, "hours");
    }
    var TotalMBDed = moment.duration("00:00", "HH:mm");

    var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
    var endTimeMB1 = moment(time, "HH:mm");
    if (startTimeMB1 > endTimeMB1) {
      endTimeMB1.add(24, "hours");
    }
    var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
    TotalMBDed.add(durationMB1);

    var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
    var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
    if (startTimeMB2 > endTimeMB2) {
      endTimeMB2.add(24, "hours");
    }
    var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
    TotalMBDed.add(durationMB2);

    var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
    var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
    if (startTimeMB3 > endTimeMB3) {
      endTimeMB3.add(24, "hours");
    }
    var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
    TotalMBDed.add(durationMB3);

    if (!this.state.DayTimeMB1ND) {
      endTime.subtract(durationMB1);
    }

    if (!this.state.DayTimeMB2ND) {
      endTime.subtract(durationMB2);
    }

    if (!this.state.DayTimeMB3ND) {
      endTime.subtract(durationMB3);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    var hoursmbded = parseInt(TotalMBDed.asHours(), 10);
    var minutesmbded = parseInt(TotalMBDed.asMinutes(), 10) % 60;
    var DayTimeTotalMBDed =
      String(hoursmbded).padStart(2, 0) +
      ":" +
      String(minutesmbded).padStart(2, 0);

    this.setState({
      DayTimeFinishMeal1: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
      DayTimeTotalMBDed: moment(DayTimeTotalMBDed, this.state.format),
    });
    setTimeout(
      function () {
        this.updateTotalMBDedDisabledState();
      }.bind(this),
      1000
    );
  }

  handleChangeDayTimeStartMeal2(time, timeString) {
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");
    if (startTime > endTime) {
      endTime.add(24, "hours");
    }
    var TotalMBDed = moment.duration("00:00", "HH:mm");

    var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
    var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
    if (startTimeMB1 > endTimeMB1) {
      endTimeMB1.add(24, "hours");
    }
    var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
    TotalMBDed.add(durationMB1);

    var startTimeMB2 = moment(time, "HH:mm");
    var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
    if (startTimeMB2 > endTimeMB2) {
      endTimeMB2.add(24, "hours");
    }
    var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
    TotalMBDed.add(durationMB2);

    var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
    var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
    if (startTimeMB3 > endTimeMB3) {
      endTimeMB3.add(24, "hours");
    }
    var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
    TotalMBDed.add(durationMB3);

    if (!this.state.DayTimeMB1ND) {
      endTime.subtract(durationMB1);
    }

    if (!this.state.DayTimeMB2ND) {
      endTime.subtract(durationMB2);
    }

    if (!this.state.DayTimeMB3ND) {
      endTime.subtract(durationMB3);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    var hoursmbded = parseInt(TotalMBDed.asHours(), 10);
    var minutesmbded = parseInt(TotalMBDed.asMinutes(), 10) % 60;
    var DayTimeTotalMBDed =
      String(hoursmbded).padStart(2, 0) +
      ":" +
      String(minutesmbded).padStart(2, 0);

    this.setState({
      DayTimeStartMeal2: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
      DayTimeTotalMBDed: moment(DayTimeTotalMBDed, this.state.format),
    });
    setTimeout(
      function () {
        this.updateTotalMBDedDisabledState();
      }.bind(this),
      1000
    );
  }

  handleChangeDayTimeFinishMeal2(time, timeString) {
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");
    if (startTime > endTime) {
      endTime.add(24, "hours");
    }
    var TotalMBDed = moment.duration("00:00", "HH:mm");

    var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
    var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
    if (startTimeMB1 > endTimeMB1) {
      endTimeMB1.add(24, "hours");
    }
    var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
    TotalMBDed.add(durationMB1);

    var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
    var endTimeMB2 = moment(time, "HH:mm");
    if (startTimeMB2 > endTimeMB2) {
      endTimeMB2.add(24, "hours");
    }
    var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
    TotalMBDed.add(durationMB2);

    var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
    var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
    if (startTimeMB3 > endTimeMB3) {
      endTimeMB3.add(24, "hours");
    }
    var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
    TotalMBDed.add(durationMB3);

    if (!this.state.DayTimeMB1ND) {
      endTime.subtract(durationMB1);
    }

    if (!this.state.DayTimeMB2ND) {
      endTime.subtract(durationMB2);
    }

    if (!this.state.DayTimeMB3ND) {
      endTime.subtract(durationMB3);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    var hoursmbded = parseInt(TotalMBDed.asHours(), 10);
    var minutesmbded = parseInt(TotalMBDed.asMinutes(), 10) % 60;
    var DayTimeTotalMBDed =
      String(hoursmbded).padStart(2, 0) +
      ":" +
      String(minutesmbded).padStart(2, 0);

    this.setState({
      DayTimeFinishMeal2: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
      DayTimeTotalMBDed: moment(DayTimeTotalMBDed, this.state.format),
    });
    setTimeout(
      function () {
        this.updateTotalMBDedDisabledState();
      }.bind(this),
      1000
    );
  }

  handleChangeDayTimeStartMeal3(time, timeString) {
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");
    if (startTime > endTime) {
      endTime.add(24, "hours");
    }
    var TotalMBDed = moment.duration("00:00", "HH:mm");

    var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
    var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
    if (startTimeMB1 > endTimeMB1) {
      endTimeMB1.add(24, "hours");
    }
    var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
    TotalMBDed.add(durationMB1);

    var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
    var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
    if (startTimeMB2 > endTimeMB2) {
      endTimeMB2.add(24, "hours");
    }
    var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
    TotalMBDed.add(durationMB2);

    var startTimeMB3 = moment(time, "HH:mm");
    var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
    if (startTimeMB3 > endTimeMB3) {
      endTimeMB3.add(24, "hours");
    }
    var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
    TotalMBDed.add(durationMB3);

    if (!this.state.DayTimeMB1ND) {
      endTime.subtract(durationMB1);
    }

    if (!this.state.DayTimeMB2ND) {
      endTime.subtract(durationMB2);
    }

    if (!this.state.DayTimeMB3ND) {
      endTime.subtract(durationMB3);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    var hoursmbded = parseInt(TotalMBDed.asHours(), 10);
    var minutesmbded = parseInt(TotalMBDed.asMinutes(), 10) % 60;
    var DayTimeTotalMBDed =
      String(hoursmbded).padStart(2, 0) +
      ":" +
      String(minutesmbded).padStart(2, 0);

    this.setState({
      DayTimeStartMeal3: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
      DayTimeTotalMBDed: moment(DayTimeTotalMBDed, this.state.format),
    });
    setTimeout(
      function () {
        this.updateTotalMBDedDisabledState();
      }.bind(this),
      1000
    );
  }

  handleChangeDayTimeFinishMeal3(time, timeString) {
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");
    if (startTime > endTime) {
      endTime.add(24, "hours");
    }
    var TotalMBDed = moment.duration("00:00", "HH:mm");

    var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
    var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
    if (startTimeMB1 > endTimeMB1) {
      endTimeMB1.add(24, "hours");
    }
    var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
    TotalMBDed.add(durationMB1);

    var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
    var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
    if (startTimeMB2 > endTimeMB2) {
      endTimeMB2.add(24, "hours");
    }
    var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
    TotalMBDed.add(durationMB2);

    var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
    var endTimeMB3 = moment(time, "HH:mm");
    if (startTimeMB3 > endTimeMB3) {
      endTimeMB3.add(24, "hours");
    }
    var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
    TotalMBDed.add(durationMB3);

    if (!this.state.DayTimeMB1ND) {
      endTime.subtract(durationMB1);
    }

    if (!this.state.DayTimeMB2ND) {
      endTime.subtract(durationMB2);
    }

    if (!this.state.DayTimeMB3ND) {
      endTime.subtract(durationMB3);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    var hoursmbded = parseInt(TotalMBDed.asHours(), 10);
    var minutesmbded = parseInt(TotalMBDed.asMinutes(), 10) % 60;
    var DayTimeTotalMBDed =
      String(hoursmbded).padStart(2, 0) +
      ":" +
      String(minutesmbded).padStart(2, 0);

    this.setState({
      DayTimeFinishMeal3: moment(timeString, this.state.format),
      DayTimeTotalHours: DayTimeTotalHours,
      DayTimeTotalMBDed: moment(DayTimeTotalMBDed, this.state.format),
    });
    setTimeout(
      function () {
        this.updateTotalMBDedDisabledState();
      }.bind(this),
      1000
    );
  }

  handleChangeDayTimeTotalMBDed(time, timeString) {
    if (this.state.DayTimePayas === "0" && this.state.Payas[0]) {
      this.state.DayTimePayas = this.state.Payas[0].Value;
    }
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");
    if (startTime > endTime) {
      endTime.add(24, "hours");
    }
    var TotalMBDedDuration = moment.duration(timeString, "HH:mm");
    endTime.subtract(TotalMBDedDuration);

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);

    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours>=0?hours:-hours).padStart(2, 0) + ":" + String(minutes>=0?minutes:-minutes).padStart(2, 0);

    this.setState({
      DayTimeTotalMBDed: moment(timeString, this.state.format),
      DayTimeTotalHours: (hours>=0&&minutes>=0)?DayTimeTotalHours:"-"+DayTimeTotalHours,
    });
  }

  updateMealBreakTime() {
    var Travel1Duration = moment.duration(
      this.state.DayTimeTravel1.format("HH:mm"),
      "HH:mm"
    );
    var Travel2Duration = moment.duration(
      this.state.DayTimeTravel2.format("HH:mm"),
      "HH:mm"
    );
    var startTime = moment(this.state.DayTimeStart, "HH:mm");
    var endTime = moment(this.state.DayTimeFinish, "HH:mm");
    if (startTime > endTime) {
      endTime.add(24, "hours");
    }
    var TotalMBDed = moment.duration("00:00", "HH:mm");

    var startTimeMB1 = moment(this.state.DayTimeStartMeal, "HH:mm");
    var endTimeMB1 = moment(this.state.DayTimeFinishMeal1, "HH:mm");
    if (startTimeMB1 > endTimeMB1) {
      endTimeMB1.add(24, "hours");
    }
    var durationMB1 = moment.duration(endTimeMB1.diff(startTimeMB1));
    if (!this.state.DayTimeMB1ND) {
      TotalMBDed.add(durationMB1);
    }

    var startTimeMB2 = moment(this.state.DayTimeStartMeal2, "HH:mm");
    var endTimeMB2 = moment(this.state.DayTimeFinishMeal2, "HH:mm");
    if (startTimeMB2 > endTimeMB2) {
      endTimeMB2.add(24, "hours");
    }
    var durationMB2 = moment.duration(endTimeMB2.diff(startTimeMB2));
    if (!this.state.DayTimeMB2ND) {
      TotalMBDed.add(durationMB2);
    }

    var startTimeMB3 = moment(this.state.DayTimeStartMeal3, "HH:mm");
    var endTimeMB3 = moment(this.state.DayTimeFinishMeal3, "HH:mm");
    if (startTimeMB3 > endTimeMB3) {
      endTimeMB3.add(24, "hours");
    }
    var durationMB3 = moment.duration(endTimeMB3.diff(startTimeMB3));
    if (!this.state.DayTimeMB3ND) {
      TotalMBDed.add(durationMB3);
    }

    if (!this.state.DayTimeMB1ND) {
      endTime.subtract(durationMB1);
    }

    if (!this.state.DayTimeMB2ND) {
      endTime.subtract(durationMB2);
    }

    if (!this.state.DayTimeMB3ND) {
      endTime.subtract(durationMB3);
    }

    var duration = moment.duration(endTime.diff(startTime));
    duration.add(Travel1Duration);
    duration.add(Travel2Duration);
    //console.log(duration);
    var hours = parseInt(duration.asHours(), 10);
    var minutes = parseInt(duration.asMinutes(), 10) % 60;
    var DayTimeTotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    var hoursmbded = parseInt(TotalMBDed.asHours(), 10);
    var minutesmbded = parseInt(TotalMBDed.asMinutes(), 10) % 60;
    var DayTimeTotalMBDed =
      String(hoursmbded).padStart(2, 0) +
      ":" +
      String(minutesmbded).padStart(2, 0);

    this.setState({
      DayTimeTotalHours: DayTimeTotalHours,
      DayTimeTotalMBDed: moment(DayTimeTotalMBDed, this.state.format),
      isLoading: false,
    });
  }

  updateTotalHours() {
    var TotalTime = moment.duration("00:00", "HH:mm");
    //console.log(TotalTime);
    if (this.state.DailyTimes.length > 0) {
      var items = this.state.DailyTimes;
      for (var key in items) {
        if (items.hasOwnProperty(key)) {
          var item = items[key];
          //console.log(item.TotalHours);
          var DailyTotalTime = moment.duration(item.TotalHours, "HH:mm");
          TotalTime.add(DailyTotalTime);
          //console.log(DailyTotalTime);
        }
      }
    }
    var hours = parseInt(TotalTime.asHours(), 10);
    var minutes = parseInt(TotalTime.asMinutes(), 10) % 60;
    var TotalHours =
      String(hours>=0?hours:-hours).padStart(2, 0) + ":" + String(minutes>=0?minutes:-minutes).padStart(2, 0);

    this.setState({ TotalHours: (hours>=0&&minutes>=0)? TotalHours : "-"+TotalHours});
  }

  updateTotalMBDedDisabledState() {
    //TotalMBDedDisabled
    if (
      this.state.DayTimeStartMeal.format("HH:mm") === "00:00" &&
      this.state.DayTimeStartMeal2.format("HH:mm") === "00:00" &&
      this.state.DayTimeStartMeal3.format("HH:mm") === "00:00" &&
      this.state.DayTimeFinishMeal1.format("HH:mm") === "00:00" &&
      this.state.DayTimeFinishMeal2.format("HH:mm") === "00:00" &&
      this.state.DayTimeFinishMeal3.format("HH:mm") === "00:00"
    ) {
      this.setState({ TotalMBDedDisabled: false });
    } else {
      this.setState({ TotalMBDedDisabled: true });
    }
  }

  calculateTotalHours(DailyTimes) {
    var TotalTime = moment.duration("00:00", "HH:mm");
    for (var prop in DailyTimes) {
      if (DailyTimes.hasOwnProperty(prop)) {
        var item = DailyTimes[prop];
        var DailyTotalTime = moment.duration(item.TotalHours, "HH:mm");
        TotalTime.add(DailyTotalTime);
      }
    }
    var hours = parseInt(TotalTime.asHours(), 10);
    var minutes = parseInt(TotalTime.asMinutes(), 10) % 60;
    var TotalHours =
      String(hours).padStart(2, 0) + ":" + String(minutes).padStart(2, 0);

    return TotalHours;
  }

  timecards() {
    return this.props.getRequest(`/timecards/list`);
  }

  getTimeCard(id) {
    return this.props.getRequest(`/timecards/get/${id}`);
  }

  createTimeCard(item) {
    return this.props.postRequest(`/timecards/create`, item);
  }

  createDocuments(item) {
    return this.props.postRequest("/documents/create", item);
  }

  updateTimeCard(item) {
    return this.props.postRequest(
      `/timecards/update/${this.state.Update}`,
      item
    );
  }

  deleteTimeCard() {
    return this.props.postRequest(`/timecards/delete/${this.state.DeleteId}`);
  }

  companies() {
    return this.props.getRequest(`/companies/list`);
  }

  getSysCopmany(id) {
    return this.props.getRequest(`/syscompany/get/${id}`);
  }

  createPreview(item) {
    return this.props.postRequest("/preview/pdf", item);
  }

  createImage(item) {
    return this.props.postRequest("/html-to-image", item);
  }

  sendEmail(item) {
    return this.props.postRequest("/email", item);
  }

  getDateFormate(date, formate) {
    var datestring = date * 1000;
    if (formate === 1) {
      return (
        new Date(datestring).getFullYear() +
        "-" +
        String(new Date(datestring).getMonth() + 1).padStart(2, 0) +
        "-" +
        String(new Date(datestring).getDate()).padStart(2, 0)
      );
    }
    if (formate === 2) {
      return (
        String(new Date(datestring).getDate()).padStart(2, 0) +
        "/" +
        String(new Date(datestring).getMonth() + 1).padStart(2, 0) +
        "/" +
        new Date(datestring).getFullYear()
      );
    }
    return date;
  }

  handleSubView = (element, clear) => async (event) => {
    event.preventDefault();
    console.log("SubView=>", element);
    if (element === "ListMobile") {
      var saveConfirm = window.confirm("Would you like to Save?");
      if (saveConfirm === true) {
        $("#SaveTimeCardMobile").click();
        return false;
      }
      element = "List";
      this.setState({ SubView: element, isLoading: false });
    } else {
      this.setState({ SubView: element, isLoading: false });
    }

    if (clear === "Yes") {
      var company = "";
      var production = "";
      var index = "0";

      this.setState({
        EmailAttachmentsSize: 0,
        DailyTimesIndex: 0,
        DailyTimesCopyIndex: 0,
        SubView: element,
        formValid: false,
        Update: false,
        ItemIndex: false,
        DailyTimes: [],
        TimesCustomTimeFields: [],
        TimesTotalHours: "0.00",
        files: [],
        Production: production,
        Activity: "",
        CompanyIndex: index,
        Company: "",
        CompanyValid: true,
        CustomTimeCardFields: [],
        Dept: "",
        FileAttachments: [],
        EmailAttachments: [],
        PayFrequency: "Weekly",
        PeriodEndingMoment: "",
        PeriodEnding: "",
        Position: "",
        Times: [],
        TotalHours: "00:00",
        Type: "Draft",
        InsertedId: "",
        PayEnding: "",
        mrtFile: "default",
      });
      //console.log("Sub view=>", element);
      if (this.state.companies.length > 0 && element === "Add") {
        /*var item = this.state.companies[0];
          var company = [
            {
              "Guid": item.Guid,
              "Name": item.Name,
              "Payas": this.state.Payas,
              "Production": item.Production
            }
          ]
          var production = '';*/
        this.setDefaultCompany();
      }
      //console.log(comp);

      const result = await this.timecards();
      //console.log(result.items);
      if (result.status) {
        this.setState({ timecards: result.result }, () => {
          this.handleSortTimeCards();
        });
      }
    }
  };

  setDailyTimes() {
    var subtractDays =
      this.state.PayFrequency === "Monthly"
        ? 29
        : this.state.PayFrequency === "Fortnightly"
        ? 13
        : this.state.PayFrequency === "4 Weekly"
        ? 27
        : 6;
    var PeriodEnding = moment(this.state.PeriodEnding);
    var PeriodStarting = moment(this.state.PeriodEnding).subtract(
      subtractDays,
      "days"
    );

    var DailyTimes = [];
    var LastDailyTimes = [];
    while (PeriodStarting <= PeriodEnding) {
      var CustomTimeFields = JSON.parse(
        JSON.stringify(this.state.TimesCustomTimeFields)
      );
      DailyTimes.push({
        CustomTimeFields: CustomTimeFields,
        Date: Math.floor(PeriodStarting.valueOf() / 1000),
        Day: PeriodStarting.format("dddd"),
        Finish: "00:00",
        FinishMeal1: "00:00",
        FinishMeal2: "00:00",
        FinishMeal3: "00:00",
        MB1ND: "0",
        MB2ND: "0",
        MB3ND: "0",
        Note: null,
        customNote: null,
        Payas: "0",
        Start: "00:00",
        StartMeal: "00:00",
        StartMeal2: "00:00",
        StartMeal3: "00:00",
        TimeStamp: new Date().getTime() / 1000,
        TotalHours: "00:00",
        TotalMBDed: "00:00",
        Travel1: "00:00",
        Travel2: "00:00",
      });
      PeriodStarting.add(1, "days");
    }
    //console.log("DailyTimes=>", DailyTimes);
    return DailyTimes;
  }

  handleCopyYesterdaysTimes = (index) => async (event) => {
    if (index === 0) {
      index = this.state.DailyTimes.length - 1;
    } else {
      index = index - 1;
    }
    let day = this.state.DailyTimes[index];
    let CustomTimeFields = JSON.parse(JSON.stringify(day.CustomTimeFields));
    //console.log(CustomTimeFields); return false;
    this.setState({
      DayTimeCopyIndex: index,
      DayTimeFinish: day.Finish
        ? moment(day.Finish, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeFinishMeal1: day.FinishMeal1
        ? moment(day.FinishMeal1, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeFinishMeal2: day.FinishMeal2
        ? moment(day.FinishMeal2, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeFinishMeal3: day.FinishMeal3
        ? moment(day.FinishMeal3, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeMB1ND: day.MB1ND === "0" ? false : true,
      DayTimeMB2ND: day.MB2ND === "0" ? false : true,
      DayTimeMB3ND: day.MB3ND === "0" ? false : true,
      DayTimecustomNote: day.customNote ? day.customNote : null,
      DayTimeNote: day.Note ? day.Note : null,
      DayTimeCustomTimeFields: CustomTimeFields,
      DayTimePayas: day.Payas,
      DayTimeStart: day.Start
        ? moment(day.Start, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeStartMeal: day.StartMeal
        ? moment(day.StartMeal, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeStartMeal2: day.StartMeal2
        ? moment(day.StartMeal2, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeStartMeal3: day.StartMeal3
        ? moment(day.StartMeal3, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeTotalHours: day.TotalHours ? day.TotalHours : "00:00",
      DayTimeTotalMBDed: day.TotalMBDed
        ? moment(day.TotalMBDed, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeTravel1: day.Travel1
        ? moment(day.Travel1, this.state.format)
        : moment("00:00", this.state.format),
      DayTimeTravel2: day.Travel2
        ? moment(day.Travel2, this.state.format)
        : moment("00:00", this.state.format),
    });

    setTimeout(
      function () {
        this.updateTotalMBDedDisabledState();
      }.bind(this),
      1000
    );
  };

  findTimecard(needle) {
    var timecards = this.state.timecards;
    //console.log("find timecards=>", timecards);
    for (var i = 0; i <= timecards.length - 1; i++) {
      // look for the entry with a matching `code` value
      if (
        timecards[i].Company.length > 0 &&
        timecards[i].Company[0].Guid === needle &&
        timecards[i].Guid !== this.state.Update
      ) {
        //console.log("PeriodEndingMoment=>",this.state.PeriodEndingMoment.format('YYYY-MM-DD'));
        var PeriodEnding = moment(new Date(timecards[i].PeriodEnding)).format(
          "YYYY-MM-DD"
        );
        if (PeriodEnding < this.state.PeriodEndingMoment.format("YYYY-MM-DD")) {
          // we found it
          return timecards[i];
        }
      }
    }
    return 0;
  }

  handleCopyLastWeekTimes = (index) => async (event) => {
    if (this.state.DailyTimes.length === 0) {
      alert("Please select period ending date.");
      return false;
    }

    if (index === 0) {
      var TimecardGuid = 0;
    } else {
      var TimecardGuid = this.state.Times[index].Guid;
    }

    if (this.state.Company) {
      var CompanyGuid = this.state.Company[0].Guid;
      var Timecard = this.findTimecard(CompanyGuid);
      //console.log("find card result",Timecard);
      if (Timecard) {
        var LastWeekTimes = Timecard.Times[0].DailyTimes;
        var CurrentWeekTimes = this.state.DailyTimes;

        if (LastWeekTimes.length === CurrentWeekTimes.length) {
          for (var i = 0; i < LastWeekTimes.length; i++) {
            CurrentWeekTimes[i].Finish = LastWeekTimes[i].Finish;
            CurrentWeekTimes[i].FinishMeal1 = LastWeekTimes[i].FinishMeal1;
            CurrentWeekTimes[i].FinishMeal2 = LastWeekTimes[i].FinishMeal2;
            CurrentWeekTimes[i].FinishMeal3 = LastWeekTimes[i].FinishMeal3;
            CurrentWeekTimes[i].MB1ND = LastWeekTimes[i].MB1ND;
            CurrentWeekTimes[i].MB2ND = LastWeekTimes[i].MB2ND;
            CurrentWeekTimes[i].MB3ND = LastWeekTimes[i].MB3ND;
            CurrentWeekTimes[i].Note = LastWeekTimes[i].Note;
            CurrentWeekTimes[i].Payas = LastWeekTimes[i].Payas;
            CurrentWeekTimes[i].Start = LastWeekTimes[i].Start;
            CurrentWeekTimes[i].StartMeal = LastWeekTimes[i].StartMeal;
            CurrentWeekTimes[i].StartMeal2 = LastWeekTimes[i].StartMeal2;
            CurrentWeekTimes[i].StartMeal3 = LastWeekTimes[i].StartMeal3;
            CurrentWeekTimes[i].TotalHours = LastWeekTimes[i].TotalHours;
            CurrentWeekTimes[i].TotalMBDed = LastWeekTimes[i].TotalMBDed;
            CurrentWeekTimes[i].Travel1 = LastWeekTimes[i].Travel1;
            CurrentWeekTimes[i].Travel2 = LastWeekTimes[i].Travel2;
            CurrentWeekTimes[i].CustomTimeFields =
              LastWeekTimes[i].CustomTimeFields;
          }

          setTimeout(
            function () {
              this.setState({
                DailyTimes: CurrentWeekTimes,
                TotalHours: Timecard.TotalHours,
              });
            }.bind(this),
            1000
          );
        } else {
          console.log("Company frequency does not match.");
        }
        //console.log(Timecard);
      } else {
        alert("No previous timecard exists for this company.");
      }
    } else {
      console.log("Error: Please select company");
    }

    return false;

    if (this.state.Times.length > 0) {
      if (index === 0) {
        index = this.state.Times.length - 1;
      } else {
        index = index - 1;
      }
      //console.log(this.state.Times);
      var DailyTimes = this.state.DailyTimes;
      var LastWeekDailyTimes = this.state.Times[index].DailyTimes;
      //console.log(LastWeekDailyTimes);
      for (var i = 0; i < LastWeekDailyTimes.length; i++) {
        DailyTimes[i].Finish = LastWeekDailyTimes[i].Finish;
        DailyTimes[i].FinishMeal1 = LastWeekDailyTimes[i].FinishMeal1;
        DailyTimes[i].FinishMeal2 = LastWeekDailyTimes[i].FinishMeal2;
        DailyTimes[i].FinishMeal3 = LastWeekDailyTimes[i].FinishMeal3;
        DailyTimes[i].MB1ND = LastWeekDailyTimes[i].MB1ND;
        DailyTimes[i].MB2ND = LastWeekDailyTimes[i].MB2ND;
        DailyTimes[i].MB3ND = LastWeekDailyTimes[i].MB3ND;
        DailyTimes[i].Note = LastWeekDailyTimes[i].Note;
        DailyTimes[i].Payas = LastWeekDailyTimes[i].Payas;
        DailyTimes[i].Start = LastWeekDailyTimes[i].Start;
        DailyTimes[i].StartMeal = LastWeekDailyTimes[i].StartMeal;
        DailyTimes[i].StartMeal2 = LastWeekDailyTimes[i].StartMeal2;
        DailyTimes[i].StartMeal3 = LastWeekDailyTimes[i].StartMeal3;
        DailyTimes[i].TotalHours = LastWeekDailyTimes[i].TotalHours;
        DailyTimes[i].TotalMBDed = LastWeekDailyTimes[i].TotalMBDed;
        DailyTimes[i].Travel1 = LastWeekDailyTimes[i].Travel1;
        DailyTimes[i].Travel2 = LastWeekDailyTimes[i].Travel2;
      }
      var TimesCustomTimeFields = this.state.Times[index].TimesCustomTimeFields;
      var TimesTotalHours = this.state.Times[index].TimesTotalHours;
      //console.log(LastWeekDailyTimes);
      this.setState({
        //DailyTimesCopyIndex: index,
        DailyTimes: DailyTimes,
        TimesCustomTimeFields: TimesCustomTimeFields
          ? TimesCustomTimeFields
          : [],
        TimesTotalHours: TimesTotalHours ? TimesTotalHours : "0.00",
      });
      setTimeout(
        function () {
          this.updateTotalHours();
        }.bind(this),
        1000
      );
    }
  };

  disabledDate(current) {
    //var d = current.format('dddd');
    if (this.state.PayEnding) {
      if (current) {
        //console.log(current.format('dddd'));
        return this.state.PayEnding !== current.format("dddd");
      }
    } else {
      // Can not select days before today and today
      //return current && current < moment().endOf('day');
      return false;
    }
    //console.log(this.state.PayEnding);
  }

  createActivitylog(item) {
    return this.props.postRequest(`/activity-log/create`, item);
  }

  async saveActivity(Description) {
    const item = {
      Description: Description,
      IPAddress: "192.11.98.9",
      Type: "COMPANY",
    };
    try {
      const response = await this.createActivitylog(item);
      console.log("Add Activity Response:" + response);
    } catch (e) {
      console.log("Add Activity Error:" + e);
    }
  }

  renderAttachments() {
    return this.state.FileAttachments.map((f, i) => (
      <div
        className="attachment_list"
        href={f.url}
        key={f.filename}
        target="_blank"
      >
        {f.filename.split(".").slice(0, -1).join(".")}
        <span
          className="attachment_delete"
          onClick={this.handleDeleteAttachment(i)}
        >
          x
        </span>
      </div>
    ));
  }

  renderPayasDropdown() {
    return this.state.Payas.map((obj, i) => (
      <option key={i} value={obj.Value}>
        {obj.Value}
      </option>
    ));
  }

  renderCompanyDropdown() {
    return this.state.companies.map((obj, i) => (
      <option key={i} value={i}>
        {obj.Name}
      </option>
    ));
  }

  renderDepartmentDropdown() {
    //debugger;
    console.log("this.state.departments", this.state.departments);
    return this.state.departments.map((obj, i) => (
      <option key={i} value={obj.name}>
        {obj.name}
      </option>
    ));
  }

  handleCustomTimeFields = (event) => {
    const name = event.target.name;
    const value = event.target.value;
  };

  renderDropdown(values) {
    return values.split("|").map((obj, i) => (
      <option key={i} value={obj}>
        {obj}
      </option>
    ));
  }

  renderCustomTimeFields(view) {
    var TimesCustomTimeFields = this.state.DayTimeCustomTimeFields;

    var row = "";
    if (view === "mobile") {
      row = TimesCustomTimeFields.map(
        function (obj, i) {
          if (obj.Type === "Checkbox" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-xs-4">{obj.Prompt}</label>
                <div className="col-xs-8">
                  <input
                    className="form-control pro_input_pop custom-time-field"
                    type="checkbox"
                    checked={obj.Value}
                    onChange={this.handleTimesCustomTimeFields(i)}
                    disabled={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "CheckBox" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-xs-4">{obj.Prompt}</label>
                <div className="col-xs-8">
                  <input
                    className="form-control pro_input_pop custom-time-field"
                    type="checkbox"
                    checked={obj.Value}
                    onChange={this.handleTimesCustomTimeFields(i)}
                    disabled={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "Entry" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-xs-4">{obj.Prompt}</label>
                <div className="col-xs-8">
                  <input
                    className="form-control pro_input_pop custom-time-field"
                    type="text"
                    name=""
                    value={obj.Value ? obj.Value : ""}
                    onChange={this.handleTimesCustomTimeFields(i)}
                    readOnly={obj.readonly}
                    required={obj.required}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "Text" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-xs-4">{obj.Prompt}</label>
                <div className="col-xs-8">
                  <input
                    className="form-control pro_input_pop custom-time-field"
                    type="text"
                    name=""
                    value={obj.Value ? obj.Value : ""}
                    onChange={this.handleTimesCustomTimeFields(i)}
                    readOnly={obj.readonly}
                    required={obj.required}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "DropList" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-xs-4">{obj.Prompt}</label>
                <div className="col-xs-8">
                  <select
                    className="form-control pro_input_pop custom-time-field"
                    name=""
                    value={obj.Value}
                    onChange={this.handleTimesCustomTimeFields(i)}
                    readOnly={obj.readonly}
                    style={{
                      "pointer-events": obj.readonly === 1 ? "none" : "block",
                    }}
                    required={obj.required}
                  >
                    {this.renderDropdown(obj.defaultValue)}
                  </select>
                </div>
              </div>
            );
          }
        }.bind(this)
      );
    } else {
      row = TimesCustomTimeFields.map(
        function (obj, i) {
          if (obj.Type === "Checkbox" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-sm-4" htmlFor="Mobile">
                  {obj.Prompt}
                </label>
                <div className="col-sm-1 checkbox_popuptime">
                  <input
                    type="checkbox"
                    ClassName="custom-time-field"
                    checked={obj.Value}
                    onChange={this.handleTimesCustomTimeFields(i)}
                    disabled={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "CheckBox" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-sm-4" htmlFor="Mobile">
                  {obj.Prompt}
                </label>
                <div className="col-sm-1 checkbox_popuptime">
                  <input
                    type="checkbox"
                    className="custom-time-field"
                    checked={obj.Value}
                    onChange={this.handleTimesCustomTimeFields(i)}
                    disabled={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "Entry" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-sm-4">{obj.Prompt}</label>
                <div className="col-sm-8">
                  <input
                    className="form-control pro_input_pop custom-time-field"
                    type="text"
                    name=""
                    value={obj.Value ? obj.Value : ""}
                    onChange={this.handleTimesCustomTimeFields(i)}
                    readOnly={obj.readonly}
                    required={obj.required}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "Text" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-sm-4">{obj.Prompt}</label>
                <div className="col-sm-8">
                  <input
                    className="form-control pro_input_pop custom-time-field"
                    type="text"
                    name=""
                    value={obj.Value ? obj.Value : ""}
                    onChange={this.handleTimesCustomTimeFields(i)}
                    readOnly={obj.readonly}
                    required={obj.required}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "DropList" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-sm-4">{obj.Prompt}</label>
                <div className="col-sm-8">
                  <select
                    className="form-control pro_input_pop custom-time-field"
                    name=""
                    value={obj.Value}
                    onChange={this.handleTimesCustomTimeFields(i)}
                    readOnly={obj.readonly}
                    style={{
                      "pointer-events": obj.readonly === 1 ? "none" : "block",
                    }}
                    required={obj.required}
                  >
                    {this.renderDropdown(obj.defaultValue)}
                  </select>
                </div>
              </div>
            );
          }
        }.bind(this)
      );
    }

    return row;
  }

  renderCustomTimeCardFields(view) {
    var CustomTimeCardFields = this.state.CustomTimeCardFields;
    var row = "";
    if (view === "mobile") {
      row = CustomTimeCardFields.map(
        function (obj, i) {
          if (obj.Type === "Checkbox" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-xs-4">{obj.Prompt}</label>
                <div className="col-xs-8">
                  <input
                    className="form-control pro_input_pop custom-timecard-field"
                    type="checkbox"
                    checked={obj.Value}
                    onChange={this.handleCustomTimeCardFields(i)}
                    disabled={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "CheckBox" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-xs-4">{obj.Prompt}</label>
                <div className="col-xs-8">
                  <input
                    className="form-control pro_input_pop custom-timecard-field"
                    type="checkbox"
                    checked={obj.Value}
                    onChange={this.handleCustomTimeCardFields(i)}
                    disabled={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "Entry" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-xs-4">{obj.Prompt}</label>
                <div className="col-xs-8">
                  <input
                    className="form-control pro_input_pop custom-timecard-field"
                    type="text"
                    name=""
                    value={obj.Value}
                    onChange={this.handleCustomTimeCardFields(i)}
                    readOnly={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "Text" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-xs-4">{obj.Prompt}</label>
                <div className="col-xs-8">
                  <input
                    className="form-control pro_input_pop custom-timecard-field"
                    type="text"
                    name=""
                    value={obj.Value}
                    onChange={this.handleCustomTimeCardFields(i)}
                    readOnly={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "DropList" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-xs-4">{obj.Prompt}</label>
                <div className="col-xs-8">
                  <select
                    className="form-control pro_input_pop custom-timecard-field"
                    name=""
                    value={obj.Value}
                    onChange={this.handleCustomTimeCardFields(i)}
                    readOnly={obj.readonly}
                    readOnly={obj.readonly}
                    style={{
                      "pointer-events": obj.readonly === 1 ? "none" : "block",
                    }}
                    required={obj.required}
                  >
                    {this.renderDropdown(obj.defaultValue)}
                  </select>
                </div>
              </div>
            );
          }
        }.bind(this)
      );
    } else {
      row = CustomTimeCardFields.map(
        function (obj, i) {
          if (obj.Type === "Checkbox" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-sm-4 col-md-3">
                  {obj.Prompt}
                </label>
                <div className="col-sm-7 checkbox_popuptime">
                  <input
                    type="checkbox"
                    className="custom-timecard-field"
                    checked={obj.Value}
                    onChange={this.handleCustomTimeCardFields(i)}
                    disabled={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "CheckBox" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-sm-4 col-md-3">
                  {obj.Prompt}
                </label>
                <div className="col-sm-7 checkbox_popuptime">
                  <input
                    type="checkbox"
                    className="custom-timecard-field"
                    checked={obj.Value}
                    onChange={this.handleCustomTimeCardFields(i)}
                    disabled={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "Entry" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-sm-4 col-md-3">
                  {obj.Prompt}
                </label>
                <div className="col-sm-7">
                  <input
                    className="form-control pro_input_pop custom-timecard-field"
                    type="text"
                    name=""
                    value={obj.Value}
                    onChange={this.handleCustomTimeCardFields(i)}
                    readOnly={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "Text" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-sm-4 col-md-3">
                  {obj.Prompt}
                </label>
                <div className="col-sm-7">
                  <input
                    className="form-control pro_input_pop custom-timecard-field"
                    type="text"
                    name=""
                    value={obj.Value}
                    onChange={this.handleCustomTimeCardFields(i)}
                    readOnly={obj.readonly}
                    required={obj.required}
                  />
                </div>
              </div>
            );
          }
          if (obj.Type === "DropList" && obj.hide === 0) {
            return (
              <div key={i} className="form-group">
                <label className="control-label col-sm-4 col-md-3">
                  {obj.Prompt}
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control pro_input_pop custom-timecard-field"
                    name=""
                    value={obj.Value}
                    onChange={this.handleCustomTimeCardFields(i)}
                    readOnly={obj.readonly}
                    style={{
                      "pointer-events": obj.readonly === 1 ? "none" : "block",
                    }}
                    required={obj.required}
                  >
                    {this.renderDropdown(obj.defaultValue)}
                  </select>
                </div>
              </div>
            );
          }
        }.bind(this)
      );
    }

    return row;
  }

  renderSubmitView(view) {
    if (view === "mobile") {
      return (
        <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg_res">
          <div className="res_top_timecard">
            <div className="col-xs-2 chev_res_let">
              <a
                href="javascript:void(0)"
                onClick={this.handleSubView("Add", "")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="2398 1881 13 19.418"
                >
                  <path
                    id="ic_chevron_left_24px"
                    className="cls-1"
                    d="M21,8.282,18.526,6,8,15.709l10.526,9.709L21,23.136l-8.035-7.427Z"
                    transform="translate(2390 1875)"
                  ></path>
                </svg>
              </a>{" "}
            </div>
            <div className="col-xs-10 text-center">
              Submit Digital Timesheet
            </div>
            <div className="clear20"></div>
          </div>

          <div className="clear5"></div>
          <div className="submit_timesheet_box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="8655 7398 52 34.667"
            >
              <path
                id="ic_backup_24px"
                className="cls-1"
                d="M41.925,17.087a16.234,16.234,0,0,0-30.333-4.333A12.995,12.995,0,0,0,13,38.667H41.167a10.8,10.8,0,0,0,.758-21.58ZM30.333,23.5v8.667H21.667V23.5h-6.5L26,12.667,36.833,23.5Z"
                transform="translate(8655 7394)"
              />
            </svg>
            <div className="clear10"></div>
            <h2 className="heading_submit_timeshhet">
              Submit Digital Timesheet
            </h2>
            We will send PDF copy TimeCard via email
            <div className="clear20"></div>
            <div
              className={
                !this.state.SubmitEmailValid
                  ? "col-sm-12 p0 field_required"
                  : "col-sm-12 p0"
              }
            >
              <input
                name="SubmitEmail"
                className="frogot_input"
                value={this.state.SubmitEmail}
                type="text"
                onChange={this.handleChange}
              />
              <img
                src="images/ic_vpn_key_24px.svg"
                className="register_icon1"
                alt=""
                width="25px"
                height="15px"
              />
            </div>
            <div className="clear10"></div>
            <span className="blue_color">
              *By pressing submit I agree that my timesheet is true and correct
            </span>
            <div className="col-sm-6 text-right pull-right">
              <div className="clear20"></div>
              <input
                name=""
                className="btn_cancel_pro pull-right"
                value="Cancel"
                type="button"
                disabled={this.state.isLoading}
                onClick={this.handleSubView("Add")}
              />
              <input
                name=""
                className="btn_submit_time pull-right"
                type="button"
                value={!this.state.isLoading ? "Submit" : "Submiting.."}
                disabled={this.state.isLoading || !this.state.SubmitEmailValid}
                onClick={this.handleSubmit}
              />
            </div>
            <div className="clear10"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg ">
          <div className="clear50"></div>
          <div className="col-sm-12">
            <div className="col-sm-11 p0">
              <div className="submit_timesheet_box">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="8655 7398 52 34.667"
                >
                  <path
                    id="ic_backup_24px"
                    className="cls-1"
                    d="M41.925,17.087a16.234,16.234,0,0,0-30.333-4.333A12.995,12.995,0,0,0,13,38.667H41.167a10.8,10.8,0,0,0,.758-21.58ZM30.333,23.5v8.667H21.667V23.5h-6.5L26,12.667,36.833,23.5Z"
                    transform="translate(8655 7394)"
                  />
                </svg>
                <div className="clear10"></div>
                <h2 className="heading_submit_timeshhet">
                  Submit Digital Timesheet
                </h2>
                We will send PDF copy TimeCard via email
                <div className="clear20"></div>
                <div
                  className={
                    !this.state.SubmitEmailValid
                      ? "col-sm-12 p0 field_required"
                      : "col-sm-12 p0"
                  }
                >
                  <input
                    name="SubmitEmail"
                    className="frogot_input"
                    value={this.state.SubmitEmail}
                    type="text"
                    onChange={this.handleChange}
                  />
                  <img
                    src="images/ic_vpn_key_24px.svg"
                    className="register_icon1"
                    alt=""
                    width="25px"
                    height="15px"
                  />
                </div>
                <div className="clear10"></div>
                <span className="blue_color">
                  *By pressing submit I agree that my timesheet is true and
                  correct
                </span>
                <div className="col-sm-6 text-right pull-right">
                  <div className="clear20"></div>
                  <input
                    name=""
                    className="btn_cancel_pro pull-right"
                    value="Cancel"
                    type="button"
                    disabled={this.state.isLoading}
                    onClick={this.handleSubView("Add")}
                  />
                  <input
                    name=""
                    className="btn_submit_time pull-right"
                    type="button"
                    value={!this.state.isLoading ? "Submit" : "Submiting.."}
                    disabled={
                      this.state.isLoading || !this.state.SubmitEmailValid
                    }
                    onClick={this.handleSubmit}
                  />
                </div>
                <div className="clear10"></div>
              </div>
              <div className="clear40"></div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderList(timecards, view) {
    var list = "";

    if (view === "mobile") {
      list = timecards.map(
        function (timecard, i) {
          if (timecard.Type === "Draft") {
            var companyName =
              timecard.Company.length > 0 ? timecard.Company[0].Name : "N/A";
            return (
              <tr key={i}>
                <td align="center">
                  {String(new Date(timecard.PeriodEnding).getDate()).padStart(
                    2,
                    0
                  )}
                  /
                  {String(
                    new Date(timecard.PeriodEnding).getMonth() + 1
                  ).padStart(2, 0)}
                  /{new Date(timecard.PeriodEnding).getFullYear()}
                </td>
                <td align="center">{companyName}</td>
                <td
                  align="center"
                  style={{ paddingLeft: "0px", paddingRight: "2px" }}
                >
                  <div className="col-xs-8 p0 text-center timecard_dele">
                    <a
                      href="javascript:void(0)"
                      onClick={this.handleUpdate(timecard.Guid, i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        />
                      </svg>
                    </a>
                  </div>

                  <div className="col-xs-4 p0 pull-right text-center timecard_dele">
                    <a
                      href="javascript:void(0)"
                      onClick={this.handleDeleteBtn(timecard.Guid, i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1700 296 15 19.286"
                      >
                        <path
                          id="ic_delete_24px"
                          className="cls-1"
                          d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                          transform="translate(1695 293)"
                        />
                      </svg>
                    </a>
                  </div>
                </td>
              </tr>
            );
          }
        }.bind(this)
      );
    } else {
      list = timecards.map(
        function (timecard, i) {
          if (timecard.Type === "Draft") {
            var companyName =
              timecard.Company.length > 0 ? timecard.Company[0].Name : "N/A";
            return (
              <tr key={i}>
                <td align="center">
                  {String(new Date(timecard.PeriodEnding).getDate()).padStart(
                    2,
                    0
                  )}
                  /
                  {String(
                    new Date(timecard.PeriodEnding).getMonth() + 1
                  ).padStart(2, 0)}
                  /{new Date(timecard.PeriodEnding).getFullYear()}
                </td>
                <td align="center">{companyName}</td>
                <td align="center">{timecard.TotalHours}</td>
                <td align="center">
                  <div className="col-sm-6 p0 text-center timecard_edit6">
                    <a
                      href="javascript:void(0)"
                      onClick={this.handleUpdate(timecard.Guid, i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1656.776 299 17.515 18.003"
                      >
                        <path
                          id="ic_create_24px"
                          className="cls-1"
                          d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                          transform="translate(1653.776 296.002)"
                        />
                      </svg>
                    </a>
                  </div>

                  <div className="col-sm-6 p0 pull-right text-center timecard_dele">
                    <a
                      href="javascript:void(0)"
                      onClick={this.handleDeleteBtn(timecard.Guid, i)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="1700 296 15 19.286"
                      >
                        <path
                          id="ic_delete_24px"
                          className="cls-1"
                          d="M6.071,20.143a2.149,2.149,0,0,0,2.143,2.143h8.571a2.149,2.149,0,0,0,2.143-2.143V7.286H6.071ZM20,4.071H16.25L15.179,3H9.821L8.75,4.071H5V6.214H20Z"
                          transform="translate(1695 293)"
                        />
                      </svg>
                    </a>
                  </div>
                </td>
              </tr>
            );
          }
        }.bind(this)
      );
    }

    return list;
  }

  renderDailyTimesListImageSheet(DailyTimes) {
    var timesheet = "";
    timesheet = DailyTimes.map((time, i) => {
      return (
        <tr key={i}>
          <td>{time.Day}</td>
          <td className="text-center">
            {String(new Date(time.Date * 1000).getDate()).padStart(2, 0)}/
            {String(new Date(time.Date * 1000).getMonth() + 1).padStart(2, 0)}/
            {new Date(time.Date * 1000).getFullYear()}
          </td>
          <td className="text-left">{time.Start}</td>
          <td className="text-left">{time.Finish}</td>
          <td className="text-left">{time.TotalMBDed}</td>
          <td className="text-left">{time.TotalHours}</td>
          <td className="text-left"> </td>
        </tr>
      );
    });

    return (
      <tbody>
        {timesheet}
        <tr key="total">
          <td className="border-0"></td>
          <td className="border-0"></td>
          <td className="border-0"></td>
          <td className="border-0"></td>
          <td className="border-0 text-center">
            <strong>Total</strong>
          </td>
          <td className="no-line text-left">
            {this.state.PeriodEnding !== "" ? this.state.TotalHours : "00:00"}
          </td>
          <td className="border-0"></td>
        </tr>
      </tbody>
    );
  }

  renderDailyTimesList(DailyTimes, view) {
    //console.log("DailyTimes=>", DailyTimes);
    if (view === "mobile") {
      return DailyTimes.map((time, i) => (
        <tr
          key={i}
          style={{ cursor: "pointer" }}
          onClick={this.handleDayTime(i, "DayTimes")}
        >
          <td align="center">
            {String(new Date(time.Date * 1000).getDate()).padStart(2, 0)}/
            {String(new Date(time.Date * 1000).getMonth() + 1).padStart(2, 0)}/
            {new Date(time.Date * 1000).getFullYear()}
          </td>
          <td align="center">{time.Day}</td>
          <td align="center">{time.TotalHours}</td>
          <td align="center" style={{ paddingTop: "12px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-4690 1327 10 16.19"
            >
              <path
                id="ic_chevron_right_24px"
                className="cls-1"
                d="M10.493,6,8.59,7.9l6.181,6.193L8.59,20.288l1.9,1.9,8.1-8.1Z"
                transform="translate(-4698.59 1321)"
              ></path>
            </svg>
          </td>
        </tr>
      ));
    } else {
      return DailyTimes.map((time, i) => (
        <tr key={i}>
          <td align="center">
            {String(new Date(time.Date * 1000).getDate()).padStart(2, 0)}/
            {String(new Date(time.Date * 1000).getMonth() + 1).padStart(2, 0)}/
            {new Date(time.Date * 1000).getFullYear()}
          </td>
          <td align="center">{time.Day}</td>
          <td align="center" onClick={this.handleDayTimeInline(i, "Add")}>
            {this.state.TimeOption === "DropList" ? (
              <TimePicker
                allowClear={false}
                minuteStep={this.state.minuteStep ? this.state.minuteStep : 1}
                className="text_inline"
                inputReadOnly={this.state.inputReadOnly}
                showNow={this.state.showNow}
                allowEmpty={this.state.allowEmpty}
                value={moment(time.Start, this.state.format)}
                format={this.state.format}
                onChange={this.handleChangeDayTimeStartInline.bind(this)}
              />
            ) : (
              <input
                type="text"
                className="text_inline_note"
                value={time.Start == "00:00" ? "" : time.Start}
                placeholder="00:00"
                onChange={this.handleChangeDayTimeTypeInline(i, "Start")}
                onBlur={this.updateTimeTypeInline(i, "Start")}
              />
            )}
          </td>
          <td align="center" onClick={this.handleDayTimeInline(i, "Add")}>
            {this.state.TimeOption === "DropList" ? (
              <TimePicker
                allowClear={false}
                minuteStep={this.state.minuteStep ? this.state.minuteStep : 1}
                className="text_inline"
                inputReadOnly={this.state.inputReadOnly}
                showNow={this.state.showNow}
                allowEmpty={this.state.allowEmpty}
                value={moment(time.TotalMBDed, this.state.format)}
                format={this.state.format}
                onChange={this.handleChangeDayTimeTotalMBDedInline.bind(this)}
                disabled={
                  this.state.TotalMBDedDisabled || this.state.allowMealBreak
                }
              />
            ) : (
              <input
                type="text"
                className="text_inline_note"
                value={time.TotalMBDed == "00:00" ? "" : time.TotalMBDed}
                placeholder="00:00"
                onChange={this.handleChangeDayTimeTypeInline(i, "TotalMBDed")}
                onBlur={this.updateTimeTypeInline(i, "TotalMBDed")}
                disabled={
                  this.state.TotalMBDedDisabled || this.state.allowMealBreak
                }
              />
            )}
          </td>
          <td align="center" onClick={this.handleDayTimeInline(i, "Add")}>
            {this.state.TimeOption === "DropList" ? (
              <TimePicker
                allowClear={false}
                minuteStep={this.state.minuteStep ? this.state.minuteStep : 1}
                className="text_inline"
                inputReadOnly={this.state.inputReadOnly}
                showNow={this.state.showNow}
                allowEmpty={this.state.allowEmpty}
                value={moment(time.Finish, this.state.format)}
                format={this.state.format}
                onChange={this.handleChangeDayTimeFinishInline.bind(this)}
              />
            ) : (
              <input
                type="text"
                className="text_inline_note"
                value={time.Finish == "00:00" ? "" : time.Finish}
                placeholder="00:00"
                onChange={this.handleChangeDayTimeTypeInline(i, "Finish")}
                onBlur={this.updateTimeTypeInline(i, "Finish")}
              />
            )}
          </td>
          <td align="center">{time.TotalHours}</td>
          <td align="center">
            <select
              className="form-control pro_input_pop payas_list_inline"
              name="DayTimePayas"
              value={time.Payas}
              onChange={this.handleChangePayasInline(i)}
            >
              <option value="0">Select</option>
              {this.renderPayasDropdown()}
            </select>
          </td>
          <td align="center">
            <input
              type="text"
              className="text_inline_note"
              value={time.customNote ? time.customNote : ""}
              onChange={this.handleChangeNoteInline(i)}
              data-index={i}
            />
          </td>
          <td align="center">
            <div className="col-sm-12 p0 text-center timecard_edit6">
              <a
                href="javascript:void(0)"
                onClick={this.handleDayTime(i, "Add")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="1656.776 299 17.515 18.003"
                >
                  <path
                    id="ic_create_24px2"
                    className="cls-1"
                    d="M3,17.25V21H6.648L17.409,9.94,13.761,6.19ZM20.23,7.04a1.016,1.016,0,0,0,0-1.41L17.954,3.29a.95.95,0,0,0-1.372,0L14.8,5.12,18.45,8.87l1.78-1.83Z"
                    transform="translate(1653.776 296.002)"
                  />
                </svg>
              </a>
            </div>
          </td>
        </tr>
      ));
    }
  }

  renderListView(view) {
    if (view === "mobile") {
      return (
        <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg_res">
          <div className="res_top_timecard">
            <div className="col-xs-2 chev_res_let">
              <a href="/dashboard">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="2398 1881 13 19.418"
                >
                  <path
                    id="ic_chevron_left_24px"
                    className="cls-1"
                    d="M21,8.282,18.526,6,8,15.709l10.526,9.709L21,23.136l-8.035-7.427Z"
                    transform="translate(2390 1875)"
                  />
                </svg>
              </a>{" "}
            </div>
            <div className="col-xs-8 text-center">Digital Timesheet</div>
            <div className="col-xs-2">
              <button
                type="button"
                className="btn btn-primary pull-right btn_add_res_time"
                style={{ backgroundColor: "transparent" }}
                onClick={this.handleSubView("Add", "Yes")}
              >
                +
              </button>
            </div>
          </div>

          <div className="clear10"></div>

          <table className="table table-bordered table-sm timecard_table_res">
            <thead>
              <tr>
                <th width="30%" align="center">
                  Pay Ending
                </th>
                <th width="50%" align="center">
                  Production Name
                </th>
                <th width="20%" align="center">
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {!this.state.isLoading ? (
                this.state.timecards.length === 0 ? (
                  <tr key="empty">
                    <td align="center" colSpan="3">
                      No data found.
                    </td>
                  </tr>
                ) : (
                  this.renderList(this.state.timecards, view)
                )
              ) : (
                <tr key="empty">
                  <td align="center" colSpan="3">
                    <strong>Loading....</strong>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg">
          <button
            type="button"
            className="btn btn-primary pull-right plus_icon_table"
            onClick={this.handleSubView("Add", "Yes")}
          >
            +
          </button>

          <div className="clear10"></div>

          <table className="table table-bordered table-sm timecard_table">
            <tbody>
              <tr className="table_blue_hdr">
                <td width="30%" align="center">
                  Pay Ending
                </td>
                <td width="35%" align="center">
                  Production Name
                </td>
                <td width="25%" align="center">
                  Total Hours
                </td>
                <td width="10%" align="center">
                  &nbsp;
                </td>
              </tr>

              {!this.state.isLoading ? (
                this.state.timecards.length === 0 ? (
                  <tr key="empty">
                    <td align="center" colSpan="4">
                      No data found.
                    </td>
                  </tr>
                ) : (
                  this.renderList(this.state.timecards, view)
                )
              ) : (
                <tr key="empty">
                  <td align="center" colSpan="4">
                    <strong>Loading....</strong>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      );
    }
  }

  renderAddView(view) {
    if (view === "mobile") {
      return (
        <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg_res">
          <div className="res_top_timecard">
            <div className="col-xs-2 chev_res_let">
              <a
                href="javascript:void(0)"
                onClick={this.handleSubView("ListMobile", "Yes")}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="2398 1881 13 19.418"
                >
                  <path
                    id="ic_chevron_left_24px"
                    className="cls-1"
                    d="M21,8.282,18.526,6,8,15.709l10.526,9.709L21,23.136l-8.035-7.427Z"
                    transform="translate(2390 1875)"
                  ></path>
                </svg>
              </a>{" "}
            </div>
            <div className="col-xs-8 text-center">Digital Timesheet</div>
            <div className="clear20"></div>
          </div>

          <div className="clear5"></div>
          <div className="clear20"></div>

          <div className="col-xs-12 profile_setting_pop p0 profile_setting_pop_5">
            <div className="clear5"></div>

            <form className="form-horizontal">
              <div
                className={
                  !this.state.CompanyValid
                    ? "form-group field_required"
                    : "form-group"
                }
              >
                <label className="control-label col-xs-4" htmlFor="Company">
                  Production:
                </label>
                <div className="col-xs-8">
                  <select
                    className="form-control pro_input_pop"
                    name="CompanyIndex"
                    value={this.state.CompanyIndex}
                    onChange={this.handleChange}
                  >
                    <option value="">Select Production</option>
                    {this.renderCompanyDropdown()}
                  </select>
                  {this.state.companies.length === 0
                    ? "<span>Add company via companies menu.</span>"
                    : ""}
                </div>
              </div>
              <div className="form-group" style={{ display: "none" }}>
                <label className="control-label col-xs-4" htmlFor="Production">
                  Production:
                </label>
                <div className="col-xs-8">
                  <input
                    className="form-control pro_input_pop"
                    type="text"
                    name="Production"
                    value={this.state.Production}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-group">
                <label
                  className="control-label col-xs-5"
                  htmlFor="PeriodEnding"
                >
                  Period Ending:
                </label>
                <div className="col-xs-5">
                  <DatePicker
                    allowClear={false}
                    open={this.state.calendarPeriodEndingMobile}
                    className="calendarPeriodEndingMobile"
                    disabledDate={this.disabledDate}
                    value={this.state.PeriodEndingMoment}
                    format={this.state.dateFormat}
                    onChange={this.handlePeriodEnding.bind(this)}
                    onClick={this.toggleCalendar(
                      "calendarPeriodEndingMobile",
                      "click"
                    )}
                    onBlur={this.toggleCalendar(
                      "calendarPeriodEndingMobile",
                      "blur"
                    )}
                  />
                </div>
                <div className="col-xs-1 calendar_time2">
                  <a
                    href="javascript:void(0)"
                    onClick={this.toggleCalendar(
                      "calendarPeriodEndingMobile",
                      "click"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="2936.352 349.176 18.501 23.145"
                    >
                      <path
                        id="ic_date_range_24px"
                        className="cls-1"
                        d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                        transform="translate(2933.352 347.176)"
                      />
                    </svg>
                  </a>
                </div>
              </div>

              <div
                className={
                  this.state.DeptValid === false
                    ? "form-group field_required"
                    : "form-group"
                }
              >
                <label className="control-label col-xs-4" htmlFor="Dept">
                  Department:
                </label>

                {this.state.departments.length > 0 ? (
                  <div className="col-xs-8">
                    <select
                      className="form-control pro_input_pop"
                      name="Dept"
                      value={this.state.Dept} //index
                      onChange={this.handleChange}
                    >
                      <option value="">Select Department</option>
                      {this.renderDepartmentDropdown()}
                    </select>
                  </div>
                ) : (
                  <div className="col-xs-8">
                    <input
                      className="form-control pro_input_pop"
                      type="text"
                      name="Dept"
                      value={this.state.Dept}
                      onChange={this.handleChange}
                    />
                  </div>
                )}
              </div>

              <div
                className={
                  this.state.PositionValid === false
                    ? "form-group field_required"
                    : "form-group"
                }
              >
                <label className="control-label col-xs-4" htmlFor="Position">
                  Position:
                </label>
                <div className="col-xs-8">
                  <input
                    className="form-control pro_input_pop"
                    type="text"
                    name="Position"
                    value={this.state.Position}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              {this.state.CustomTimeCardFields.length > 0
                ? this.renderCustomTimeCardFields("mobile")
                : ""}

              <div className="clearfix"></div>
            </form>

            <div className="clear5"></div>
            <div className="col-xs-12 p0 btn_time_time2_svg">
              <input
                name=""
                className="btn_time_time2"
                value={
                  this.state.PeriodEnding !== ""
                    ? "Times " + this.state.TotalHours + " Hrs"
                    : "Times 00:00 Hrs"
                }
                type="button"
                onClick={this.handleSubView("WeekTimes")}
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-4690 1327 10 16.19"
              >
                <path
                  id="ic_chevron_right_24px"
                  className="cls-1"
                  d="M10.493,6,8.59,7.9l6.181,6.193L8.59,20.288l1.9,1.9,8.1-8.1Z"
                  transform="translate(-4698.59 1321)"
                />
              </svg>
            </div>
            <div className="clear5"></div>

            <div className="col-sm-12 p0">
              <div className="heading_1">Attachment</div>
              <div className="clear10"></div>

              <div className="col-sm-12 attchment_bottom_label p0">
                <div className="col-xs-12 p0">
                  <Dropzone
                    accept="image/jpg, image/jpeg, image/png, application/pdf"
                    onDrop={this.onDrop.bind(this)}
                    style={{ position: "relative", cursor: "pointer" }}
                  >
                    <div className="drag_drop_box">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="8655 7398 52 34.667"
                      >
                        <path
                          id="ic_backup_24px"
                          className="cls-1"
                          d="M41.925,17.087a16.234,16.234,0,0,0-30.333-4.333A12.995,12.995,0,0,0,13,38.667H41.167a10.8,10.8,0,0,0,.758-21.58ZM30.333,23.5v8.667H21.667V23.5h-6.5L26,12.667,36.833,23.5Z"
                          transform="translate(8655 7394)"
                        />
                      </svg>
                      <div className="clear10"></div>
                      Click to upload
                    </div>
                    <div className="clear20"></div>
                  </Dropzone>
                  <div className="clear20"></div>
                  <span className="doc_file_error">
                    {this.state.fileError ? this.state.fileError : ""}
                  </span>
                </div>
              </div>

              <input
                name=""
                className="btn_submit_res3 pull-right"
                type="button"
                value="Submit"
                disabled={this.state.onHold || this.state.isLoading}
                onClick={this.handleSubmitView}
              />
              <button
                className="btn_prview_res3 pull-right"
                disabled={
                  this.state.onHold ||
                  this.state.isLoading ||
                  this.state.isUploading
                }
                onClick={this.handlePreview}
              >
                <i
                  className={
                    this.state.previewLoading ? "fa fa-spinner fa-spin" : ""
                  }
                ></i>{" "}
                Preview
              </button>
              <input
                name=""
                id="SaveTimeCardMobile"
                className="btn_save_res3 pull-right"
                type="button"
                value={!this.state.isLoading ? "Save" : "Saving.."}
                disabled={this.state.onHold || this.state.isLoading}
                onClick={this.handleSubmit}
              />

              <div className="clear40"></div>
              <div className="clear20"></div>
            </div>
          </div>

          <div className="clear40"></div>
        </div>
      );
    } else {
      return (
        <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg">
          <div className="heading_1">Digital Timesheet</div>
          <div className="clear20"></div>

          <div className="col-sm-8 profile_setting_pop p0">
            <form className="form-horizontal">
              <div
                className={
                  !this.state.CompanyValid
                    ? "form-group field_required"
                    : "form-group"
                }
              >
                <label
                  className="control-label col-sm-4 col-md-3"
                  htmlFor="Company"
                >
                  Production:
                </label>
                <div className="col-sm-7">
                  <select
                    className="form-control pro_input_pop"
                    name="CompanyIndex"
                    value={this.state.CompanyIndex}
                    onChange={this.handleChange}
                  >
                    <option value="">Select Production</option>
                    {this.renderCompanyDropdown()}
                  </select>
                  {this.state.companies.length === 0
                    ? "Add company via companies menu."
                    : ""}
                </div>
              </div>
              <div className="form-group" style={{ display: "none" }}>
                <label
                  className="control-label col-sm-4 col-md-3"
                  htmlFor="Production"
                >
                  Production:
                </label>
                <div className="col-sm-7">
                  <input
                    className="form-control pro_input_pop"
                    type="text"
                    name="Production"
                    value={this.state.Production}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="form-group">
                <label
                  className="control-label col-sm-4 col-md-3"
                  htmlFor="PeriodEnding"
                >
                  Period Ending:
                </label>
                <div className="col-sm-7 col-md-3 period_ending">
                  <DatePicker
                    allowClear={false}
                    open={this.state.calendarPeriodEndingWeb}
                    className="calendarPeriodEndingWeb"
                    disabledDate={this.disabledDate}
                    value={this.state.PeriodEndingMoment}
                    format={this.state.dateFormat}
                    onChange={this.handlePeriodEnding.bind(this)}
                    onClick={this.toggleCalendar(
                      "calendarPeriodEndingWeb",
                      "click"
                    )}
                    onBlur={this.toggleCalendar(
                      "calendarPeriodEndingWeb",
                      "blur"
                    )}
                  />
                </div>
                <div className="col-sm-1 col-md-1 calendar_time2 timecard_cldr2">
                  <a
                    href="javascript:void(0)"
                    onClick={this.toggleCalendar(
                      "calendarPeriodEndingWeb",
                      "click"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="2936.352 349.176 18.501 23.145"
                    >
                      <path
                        id="ic_date_range_24px"
                        className="cls-1"
                        d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                        transform="translate(2933.352 347.176)"
                      />
                    </svg>
                  </a>
                </div>
              </div>

              {/* <div
                className={
                  !this.state.CompanyValid
                    ? "form-group field_required"
                    : "form-group"
                }
              >
                <label className="control-label col-xs-4" htmlFor="Company">
                  Production:
                </label>
                <div className="col-xs-8">
                  <select
                    className="form-control pro_input_pop"
                    name="CompanyIndex"
                    value={this.state.CompanyIndex}
                    onChange={this.handleChange}
                  >
                    <option value="">Select Production</option>
                    {this.renderCompanyDropdown()}
                  </select>
                  {this.state.companies.length === 0
                    ? "<span>Add company via companies menu.</span>"
                    : ""}
                </div>
              </div> */}

              <div
                className={
                  this.state.DeptValid === false
                    ? "form-group field_required"
                    : "form-group"
                }
              >
                <label
                  className="control-label col-sm-4 col-md-3"
                  htmlFor="Dept"
                >
                  Department:
                </label>
                {this.state.departments.length > 0 ? (
                  <div className="col-sm-7">
                    <select
                      className="form-control pro_input_pop"
                      name="Dept"
                      value={this.state.Dept} //index
                      onChange={this.handleChange}
                    >
                      <option value="">Select Department</option>
                      {this.renderDepartmentDropdown()}
                    </select>
                  </div>
                ) : (
                  <div className="col-sm-7">
                    <input
                      className="form-control pro_input_pop"
                      type="text"
                      name="Dept"
                      value={this.state.Dept}
                      onChange={this.handleChange}
                    />
                  </div>
                )}
              </div>

              <div
                className={
                  this.state.PositionValid === false
                    ? "form-group field_required"
                    : "form-group"
                }
              >
                <label
                  className="control-label col-sm-4 col-md-3"
                  htmlFor="Position"
                >
                  Position:
                </label>
                <div className="col-sm-7">
                  <input
                    className="form-control pro_input_pop"
                    type="text"
                    name="Position"
                    value={this.state.Position}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              {this.state.CustomTimeCardFields.length > 0
                ? this.renderCustomTimeCardFields("web")
                : ""}

              <div className="clear20"></div>
            </form>
          </div>
          <div className="clear10"></div>
          <table className="table table-bordered table-sm timecard2_table">
            <thead>
              <tr>
                <th width="15%" align="center">
                  Date
                </th>
                <th width="15%" align="center">
                  Day
                </th>
                <th width="10%" align="center">
                  Start
                </th>
                <th width="10%" align="center">
                  Meal
                </th>
                <th width="10%" align="center">
                  Finish
                </th>
                <th width="10%" align="center">
                  Hours
                </th>
                <th width="10%" align="center">
                  Payas
                </th>
                <th width="20%" align="center">
                  Note
                </th>
                <th width="10%" align="center">
                  &nbsp;
                </th>
              </tr>
            </thead>
            <tbody style={{ color: "#aaaaaa" }}>
              {this.state.PeriodEnding !== "" ? (
                this.state.DailyTimes.length === 0 ? (
                  <tr key="empty">
                    <td align="center" colSpan="8">
                      <strong>Please select period ending date.</strong>
                    </td>
                  </tr>
                ) : (
                  this.renderDailyTimesList(this.state.DailyTimes, view)
                )
              ) : (
                <tr key="empty">
                  <td align="center" colSpan="8">
                    <strong>Please select period ending date.</strong>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="clear10"></div>

          <div className="col-sm-6 p0">
            <button
              type="button"
              href="#"
              className="btn_copy_time"
              onClick={this.handleCopyLastWeekTimes(
                this.state.DailyTimesCopyIndex
              )}
            >
              <span>Copy Last Weeks Times</span>
            </button>
          </div>
          <div className="col-sm-6 p0">
            <div className="pull-right ">
              <button type="button" href="#" className="btn_price_time2">
                <span>
                  {this.state.PeriodEnding !== ""
                    ? this.state.TotalHours
                    : "00:00"}
                </span>
              </button>
            </div>
            <div className="pull-right label_timecard2">Total(Hrs):</div>
          </div>
          <div className="clear40"></div>

          <div className="col-sm-12">
            <div className="heading_1">Attachment</div>
            <div className="clear20"></div>
            <div className="col-sm-12 attchment_bottom_label">
              {this.state.FileAttachments.length > 0 ? (
                this.renderAttachments()
              ) : (
                <strong>No Records found</strong>
              )}
              <div className="clear20"></div>
              <div className="col-sm-8 p0">
                <Dropzone
                  accept="image/jpg, image/jpeg, image/png, application/pdf"
                  onDrop={this.onDrop.bind(this)}
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  <div className="drag_drop_box">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="8655 7398 52 34.667"
                    >
                      <path
                        id="ic_backup_24px"
                        className="cls-1"
                        d="M41.925,17.087a16.234,16.234,0,0,0-30.333-4.333A12.995,12.995,0,0,0,13,38.667H41.167a10.8,10.8,0,0,0,.758-21.58ZM30.333,23.5v8.667H21.667V23.5h-6.5L26,12.667,36.833,23.5Z"
                        transform="translate(8655 7394)"
                      />
                    </svg>
                    <div className="clear10"></div>
                    Drag files in or click to upload
                  </div>
                </Dropzone>
                <div className="clear40"></div>
                <span className="doc_file_error">
                  {this.state.fileError ? this.state.fileError : ""}
                </span>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="btn_cance_save">
                <input
                  className="btn_save_pro"
                  type="button"
                  value="Save"
                  value={!this.state.isLoading ? "Save" : "Saving.."}
                  disabled={
                    this.state.onHold ||
                    this.state.isLoading ||
                    this.state.isUploading
                  }
                  onClick={this.handleSubmit}
                />
                <input
                  className="btn_cancel_pro"
                  value="Cancel"
                  type="button"
                  onClick={this.handleSubView("List", "Yes")}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="btn_cance_save pull-right">
                <input
                  name=""
                  className="btn_submit_time"
                  type="button"
                  value="Submit"
                  disabled={
                    this.state.onHold ||
                    this.state.isLoading ||
                    this.state.isUploading
                  }
                  onClick={this.handleSubmitView}
                />
                <button
                  className="btn_prview_time"
                  disabled={
                    this.state.onHold ||
                    this.state.isLoading ||
                    this.state.isUploading
                  }
                  onClick={this.handlePreview}
                >
                  <i
                    className={
                      this.state.previewLoading ? "fa fa-spinner fa-spin" : ""
                    }
                  ></i>{" "}
                  Preview
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderDayTimes(view) {
    return (
      <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg_res">
        <div className="res_top_timecard">
          <div className="col-xs-2 chev_res_let">
            <a
              href="javascript:void(0)"
              onClick={this.handleSubView("WeekTimes")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="2398 1881 13 19.418"
              >
                <path
                  id="ic_chevron_left_24px"
                  className="cls-1"
                  d="M21,8.282,18.526,6,8,15.709l10.526,9.709L21,23.136l-8.035-7.427Z"
                  transform="translate(2390 1875)"
                ></path>
              </svg>
            </a>
          </div>
          <div className="col-xs-8 text-center">Digital Timesheet</div>
          <div className="clear20"></div>
        </div>
        <div className="clear5"></div>
        <div className="clear20"></div>

        <div className="col-xs-12 profile_setting_pop p0 profile_setting_pop_5">
          <div className="clear5"></div>
          <div className="col-sm-12 p0">
            <div className="col-sm-6 p0">
              <button
                type="button"
                className="btn_copy_time"
                onClick={this.handleCopyYesterdaysTimes(
                  this.state.DayTimeCopyIndex
                )}
              >
                <span>Copy Yesterdays Times</span>
              </button>
            </div>
            <div className="clear20"></div>

            <form className="form-horizontal">
              <div className="form-group">
                <label
                  className="control-label col-xs-4"
                  htmlFor="DayTimePayas"
                >
                  Pay As
                </label>
                <div className="col-xs-8">
                  <select
                    className="form-control pro_input_pop"
                    name="DayTimePayas"
                    value={this.state.DayTimePayas}
                    onChange={this.handleChangeDayTime}
                  >
                    <option value="0">Select</option>
                    {this.renderPayasDropdown()}
                  </select>
                  <span
                    className="doc_file_error"
                    style={{ position: "initial" }}
                  >
                    {this.state.SaveDayTimeError
                      ? this.state.SaveDayTimeError
                      : ""}
                  </span>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Email">
                  Date
                </label>
                <div className="col-xs-6">
                  <DatePicker
                    allowClear={false}
                    className="calendarDayTimeDateMobile"
                    value={this.state.DayTimeDateMoment}
                    format={this.state.dateFormat}
                    onChange={this.handleDayTimeDate.bind(this)}
                    disabled
                  />
                </div>
                <div className="col-xs-1 calendar_time2">
                  <a
                    href="javascript:void(0)"
                    onClick={this.handleCalendar(".calendarDayTimeDateMobile")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="2936.352 349.176 18.501 23.145"
                    >
                      <path
                        id="ic_date_range_24px"
                        className="cls-1"
                        d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                        transform="translate(2933.352 347.176)"
                      />
                    </svg>
                  </a>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Production">
                  Day
                </label>
                <div className="col-xs-8" style={{ paddingTop: "5px" }}>
                  {this.state.DayTimeDay}
                </div>
              </div>

              {!this.state.TravelToHide ? (
                <div className="form-group">
                  <label className="control-label col-xs-4" htmlFor="Mobile">
                    Travel To
                  </label>
                  <div className="col-xs-8">
                    <TimePicker
                      allowClear={false}
                      minuteStep={
                        this.state.minuteStep ? this.state.minuteStep : 1
                      }
                      inputReadOnly={this.state.inputReadOnly}
                      showNow={this.state.showNow}
                      allowEmpty={this.state.allowEmpty}
                      popupClassName={this.state.popupClassName}
                      value={this.state.DayTimeTravel2}
                      format={this.state.format}
                      onSelect={(value) => {
                        const timeString = moment(value).format("HH:mm");
                        this.handleChangeDayTimeTravel2(value, timeString);
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Production">
                  Start Work
                </label>
                <div className="col-xs-8">
                  <TimePicker
                    allowClear={false}
                    minuteStep={
                      this.state.minuteStep ? this.state.minuteStep : 1
                    }
                    inputReadOnly={this.state.inputReadOnly}
                    showNow={this.state.showNow}
                    allowEmpty={this.state.allowEmpty}
                    popupClassName={this.state.popupClassName}
                    value={this.state.DayTimeStart}
                    format={this.state.format}
                    onSelect={(value) => {
                      const timeString = moment(value).format("HH:mm");
                      this.handleChangeDayTimeStart(value, timeString);
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-5" htmlFor="Email">
                  Meal Break
                </label>
                <div className="col-xs-7">
                  <div className="col-xs-12 p0 btn_time_time2_svg">
                    <TimePicker
                      allowClear={false}
                      minuteStep={
                        this.state.minuteStep ? this.state.minuteStep : 1
                      }
                      inputReadOnly={this.state.inputReadOnly}
                      showNow={this.state.showNow}
                      allowEmpty={this.state.allowEmpty}
                      popupClassName={this.state.popupClassName}
                      value={this.state.DayTimeTotalMBDed}
                      format={this.state.format}
                      onSelect={(value) => {
                        const timeString = moment(value).format("HH:mm");
                        this.handleChangeDayTimeTotalMBDed(value, timeString);
                      }}
                      disabled={
                        this.state.TotalMBDedDisabled ||
                        this.state.allowMealBreak
                      }
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-4690 1327 10 16.19"
                      style={{ top: "11", cursor: "pointer" }}
                      onClick={this.handleSubView("BreakTimes")}
                    >
                      <path
                        id="ic_chevron_right_24px"
                        className="cls-1"
                        d="M10.493,6,8.59,7.9l6.181,6.193L8.59,20.288l1.9,1.9,8.1-8.1Z"
                        transform="translate(-4698.59 1321)"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Email">
                  Finish Work:
                </label>
                <div className="col-xs-8">
                  <TimePicker
                    allowClear={false}
                    minuteStep={
                      this.state.minuteStep ? this.state.minuteStep : 1
                    }
                    inputReadOnly={this.state.inputReadOnly}
                    showNow={this.state.showNow}
                    allowEmpty={this.state.allowEmpty}
                    popupClassName={this.state.popupClassName}
                    value={this.state.DayTimeFinish}
                    format={this.state.format}
                    onSelect={(value) => {
                      const timeString = moment(value).format("HH:mm");
                      this.handleChangeDayTimeFinish(value, timeString);
                    }}
                  />
                </div>
              </div>

              {!this.state.TravelFromHide ? (
                <div className="form-group">
                  <label className="control-label col-xs-4" htmlFor="Email">
                    Travel From:
                  </label>
                  <div className="col-xs-8">
                    <TimePicker
                      allowClear={false}
                      minuteStep={
                        this.state.minuteStep ? this.state.minuteStep : 1
                      }
                      inputReadOnly={this.state.inputReadOnly}
                      showNow={this.state.showNow}
                      allowEmpty={this.state.allowEmpty}
                      popupClassName={this.state.popupClassName}
                      value={this.state.DayTimeTravel1}
                      format={this.state.format}
                      onSelect={(value) => {
                        const timeString = moment(value).format("HH:mm");
                        this.handleChangeDayTimeTravel1(value, timeString);
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Production">
                  Total Hours
                </label>
                <div className="col-xs-8" style={{ paddingTop: "5px" }}>
                  {this.state.DayTimeTotalHours}
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Email">
                  Note:
                </label>
                <div className="col-xs-8">
                  <input
                    className="form-control pro_input_pop"
                    placeholder=""
                    type="text"
                    name="DayTimeNote"
                    value={this.state.DayTimeNote}
                    onChange={this.handleChangeDayTime}
                  />
                </div>
              </div>
              {this.state.TimesCustomTimeFields.length > 0
                ? this.renderCustomTimeFields("mobile")
                : ""}
              <div className="clear20"></div>
              <div
                className="col-sm-6 pull-right"
                style={{ paddingRight: "0" }}
              >
                <input
                  name=""
                  className="btn_save_pro btn_save_pro_5"
                  value="Save"
                  type="button"
                  onClick={this.handleSaveDayTime(
                    this.state.DayTimeIndex,
                    "WeekTimes"
                  )}
                  disabled={this.state.isLoading}
                />
                <input
                  name=""
                  className="btn_cancel_pro nn"
                  value="Cancel"
                  type="button"
                  onClick={this.handleSubView("WeekTimes")}
                />

                <div className="clear40"></div>
              </div>

              <div className="clearfix"></div>
            </form>

            <div className="clear5"></div>

            <div className="clear40"></div>
            <div className="clear20"></div>
          </div>
        </div>
      </div>
    );
  }

  renderBreakTimes(view) {
    return (
      <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg_res">
        <div className="res_top_timecard">
          <div className="col-xs-2 chev_res_let">
            <a
              href="javascript:void(0)"
              onClick={this.handleSubView("DayTimes")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="2398 1881 13 19.418"
              >
                <path
                  id="ic_chevron_left_24px"
                  className="cls-1"
                  d="M21,8.282,18.526,6,8,15.709l10.526,9.709L21,23.136l-8.035-7.427Z"
                  transform="translate(2390 1875)"
                ></path>
              </svg>
            </a>
          </div>
          <div className="col-xs-8 text-center">Meal Break Times</div>
          <div className="clear20"></div>
        </div>
        <div className="clear5"></div>
        <div className="clear20"></div>

        <div className="col-xs-12 profile_setting_pop p0 profile_setting_pop_5">
          <div className="clear5"></div>
          <div className="col-sm-12 p0">
            <form className="form-horizontal">
              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Production">
                  Start MB1
                </label>
                <div className="col-xs-8">
                  <TimePicker
                    allowClear={false}
                    minuteStep={
                      this.state.minuteStep ? this.state.minuteStep : 1
                    }
                    inputReadOnly={this.state.inputReadOnly}
                    showNow={this.state.showNow}
                    allowEmpty={this.state.allowEmpty}
                    popupClassName={this.state.popupClassName}
                    value={this.state.DayTimeStartMeal}
                    format={this.state.format}
                    onSelect={(value) => {
                      const timeString = moment(value).format("HH:mm");
                      this.handleChangeDayTimeStartMeal(value, timeString);
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Production">
                  Finish MB1
                </label>
                <div className="col-xs-8">
                  <TimePicker
                    allowClear={false}
                    minuteStep={
                      this.state.minuteStep ? this.state.minuteStep : 1
                    }
                    inputReadOnly={this.state.inputReadOnly}
                    showNow={this.state.showNow}
                    allowEmpty={this.state.allowEmpty}
                    popupClassName={this.state.popupClassName}
                    value={this.state.DayTimeFinishMeal1}
                    format={this.state.format}
                    onSelect={(value) => {
                      const timeString = moment(value).format("HH:mm");
                      this.handleChangeDayTimeFinishMeal1(value, timeString);
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Production">
                  Start MB2
                </label>
                <div className="col-xs-8">
                  <TimePicker
                    allowClear={false}
                    minuteStep={
                      this.state.minuteStep ? this.state.minuteStep : 1
                    }
                    inputReadOnly={this.state.inputReadOnly}
                    showNow={this.state.showNow}
                    allowEmpty={this.state.allowEmpty}
                    popupClassName={this.state.popupClassName}
                    value={this.state.DayTimeStartMeal2}
                    format={this.state.format}
                    onSelect={(value) => {
                      const timeString = moment(value).format("HH:mm");
                      this.handleChangeDayTimeStartMeal2(value, timeString);
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Production">
                  Finish MB2
                </label>
                <div className="col-xs-8">
                  <TimePicker
                    allowClear={false}
                    minuteStep={
                      this.state.minuteStep ? this.state.minuteStep : 1
                    }
                    inputReadOnly={this.state.inputReadOnly}
                    showNow={this.state.showNow}
                    allowEmpty={this.state.allowEmpty}
                    popupClassName={this.state.popupClassName}
                    value={this.state.DayTimeFinishMeal2}
                    format={this.state.format}
                    onSelect={(value) => {
                      const timeString = moment(value).format("HH:mm");
                      this.handleChangeDayTimeFinishMeal2(value, timeString);
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Production">
                  Start MB3
                </label>
                <div className="col-xs-8">
                  <TimePicker
                    allowClear={false}
                    minuteStep={
                      this.state.minuteStep ? this.state.minuteStep : 1
                    }
                    inputReadOnly={this.state.inputReadOnly}
                    showNow={this.state.showNow}
                    allowEmpty={this.state.allowEmpty}
                    popupClassName={this.state.popupClassName}
                    value={this.state.DayTimeStartMeal3}
                    format={this.state.format}
                    onSelect={(value) => {
                      const timeString = moment(value).format("HH:mm");
                      this.handleChangeDayTimeStartMeal3(value, timeString);
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-4" htmlFor="Production">
                  Finish MB3
                </label>
                <div className="col-xs-8">
                  <TimePicker
                    allowClear={false}
                    minuteStep={
                      this.state.minuteStep ? this.state.minuteStep : 1
                    }
                    inputReadOnly={this.state.inputReadOnly}
                    showNow={this.state.showNow}
                    allowEmpty={this.state.allowEmpty}
                    popupClassName={this.state.popupClassName}
                    value={this.state.DayTimeFinishMeal3}
                    format={this.state.format}
                    onSelect={(value) => {
                      const timeString = moment(value).format("HH:mm");
                      this.handleChangeDayTimeFinishMeal3(value, timeString);
                    }}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-6" htmlFor="Production">
                  Non Deductible MB1
                </label>
                <div className="col-xs-6" style={{ paddingTop: "10px" }}>
                  <input
                    type="checkbox"
                    name="DayTimeMB1ND"
                    onChange={this.handleChangeDayTime}
                    checked={this.state.DayTimeMB1ND}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-6" htmlFor="Production">
                  Non Deductible MB2
                </label>
                <div className="col-xs-6" style={{ paddingTop: "10px" }}>
                  <input
                    type="checkbox"
                    name="DayTimeMB2ND"
                    onChange={this.handleChangeDayTime}
                    checked={this.state.DayTimeMB2ND}
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-xs-6" htmlFor="Production">
                  Non Deductible MB3
                </label>
                <div className="col-xs-6" style={{ paddingTop: "10px" }}>
                  <input
                    type="checkbox"
                    name="DayTimeMB3ND"
                    onChange={this.handleChangeDayTime}
                    checked={this.state.DayTimeMB3ND}
                  />
                </div>
              </div>

              <div className="clear20"></div>
              <div
                className="col-sm-6 pull-right"
                style={{ paddingRight: "0" }}
              >
                <input
                  name=""
                  className="btn_save_pro btn_save_pro_5"
                  value="Save"
                  type="button"
                  onClick={this.handleSaveDayTime(
                    this.state.DayTimeIndex,
                    "DayTimes"
                  )}
                  onClick={this.handleSubView("DayTimes")}
                  disabled={this.state.isLoading}
                />
                <input
                  name=""
                  className="btn_cancel_pro nn"
                  value="Cancel"
                  type="button"
                  onClick={this.handleSubView("DayTimes")}
                />

                <div className="clear40"></div>
              </div>

              <div className="clearfix"></div>
            </form>

            <div className="clear5"></div>

            <div className="clear40"></div>
            <div className="clear20"></div>
          </div>
        </div>
      </div>
    );
  }

  renderWeekTimes(view) {
    return (
      <div className="col-xs-12 col-sm-12 col-md-10 col-md-offset-1 time_table_mrg_res">
        <div className="res_top_timecard">
          <div className="col-xs-2 chev_res_let">
            <a href={null} onClick={this.handleSubView("Add", "No")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="2398 1881 13 19.418"
              >
                <path
                  id="ic_chevron_left_24px"
                  className="cls-1"
                  d="M21,8.282,18.526,6,8,15.709l10.526,9.709L21,23.136l-8.035-7.427Z"
                  transform="translate(2390 1875)"
                ></path>
              </svg>
            </a>
          </div>
          <div className="col-xs-8 text-center">Digital Timesheet</div>
          <div className="clear20"></div>
        </div>
        <div className="clear5"></div>
        <div className="clear20"></div>

        <div className="col-xs-12 profile_setting_pop p0">
          <div className="clear5"></div>
          <div className="col-sm-12 p0">
            <div className="col-sm-6 p0">
              <button
                type="button"
                href="#"
                className="btn_copy_time"
                onClick={this.handleCopyLastWeekTimes(
                  this.state.DailyTimesCopyIndex
                )}
              >
                <span>Copy Last Weeks Times</span>
              </button>
            </div>
            <div className="clear20"></div>

            <table className="table table-bordered table-sm timecard2_table res_table_time_svg5">
              <thead>
                <tr>
                  <th width="20%" align="center">
                    Date
                  </th>
                  <th width="20%" align="center">
                    Day
                  </th>
                  <th width="10%" align="center">
                    Hours
                  </th>
                  <th width="10%" align="center">
                    &nbsp;
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.PeriodEnding !== "" ? (
                  this.state.DailyTimes.length === 0 ? (
                    <tr key="empty">
                      <td align="center" colSpan="4">
                        <strong>Please select period ending date.</strong>
                      </td>
                    </tr>
                  ) : (
                    this.renderDailyTimesList(this.state.DailyTimes, view)
                  )
                ) : (
                  <tr key="empty">
                    <td align="center" colSpan="4">
                      <strong>Please select period ending date.</strong>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="clear5"></div>
            <div className="col-sm-6 p0">
              <div className="pull-right ">
                <div className="pull-right label_timecard2">
                  <span style={{ paddingLeft: "20px" }}>
                    {this.state.PeriodEnding !== ""
                      ? this.state.TotalHours
                      : "00:00"}
                  </span>
                </div>
              </div>
              <div className="pull-right label_timecard2">
                Total(Hrs):&nbsp;
              </div>
            </div>
            <div className="clear20"></div>
            <div className="col-sm-6 pull-right" style={{ paddingRight: "0" }}>
              <input
                name=""
                className="btn_save_pro btn_save_pro_5"
                value="Save"
                type="button"
                onClick={this.handleSubView("Add", "No")}
                disabled={this.state.isLoading}
              />
              <input
                name=""
                className="btn_cancel_pro nn"
                value="Cancel"
                type="button"
                onClick={this.handleSubView("Add", "No")}
              />

              <div className="clear40"></div>
            </div>
            <div className="clear40"></div>
          </div>
        </div>
      </div>
    );
  }

  //render main content
  render() {
    //console.log("this.state", this.state);
    return (
      <div
        className="col-xs-12  col-sm-9 col-md-10 pull-right mrg_dashboard_right"
        style={{ background: "#ffffff", color: "#707070" }}
      >
        {this.state.isLoading ? <div className="is-loading"></div> : ""}
        <div className="clear40"></div>

        {this.state.SubView === "List" ? this.renderListView("web") : ""}
        {this.state.SubView === "Add" ? this.renderAddView("web") : ""}
        {this.state.SubView === "Submit" ? this.renderSubmitView("web") : ""}

        {/* Web view End */}

        {this.state.SubView === "List" ? this.renderListView("mobile") : ""}
        {this.state.SubView === "Add" ? this.renderAddView("mobile") : ""}
        {this.state.SubView === "WeekTimes"
          ? this.renderWeekTimes("mobile")
          : ""}
        {this.state.SubView === "DayTimes" ? this.renderDayTimes("mobile") : ""}
        {this.state.SubView === "BreakTimes"
          ? this.renderBreakTimes("mobile")
          : ""}
        {this.state.SubView === "Submit" ? this.renderSubmitView("mobile") : ""}

        <div className="clearfix"></div>

        <div
          className="modal fade"
          id="exampleModalDelete"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modla_error_timecard"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header modal_header_register">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="exampleModalDeleteClose"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body register_suc register_popup">
                <div className="clear20"></div>
                <div className="col-sm-12 p0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="8680 5966 90 77.727"
                  >
                    <path
                      id="ic_warning_24px"
                      className="cls-1"
                      d="M1,79.727H91L46,2ZM50.091,67.455H41.909V59.273h8.182Zm0-16.364H41.909V34.727h8.182Z"
                      transform="translate(8679 5964)"
                    />
                  </svg>
                  <div className="clear20"></div>
                  Do you want to delete the Timecard?
                  <div className="clear40"></div>
                  <div className="col-sm-12">
                    <div className="center_btn_pop3">
                      <button
                        type="button"
                        className="btn_cancel_pro"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        Cancel
                      </button>

                      <button
                        className="btn_delete_error"
                        onClick={this.handleDelete}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>

                <div className="clear10"></div>
              </div>
            </div>
          </div>
        </div>

        {/*WeekTimesModalCenter Start*/}
        <div
          className="modal fade"
          id="WeekTimesModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered timecard_2popup"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header modal_header_register">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="WeekTimesModalCenterClose"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body register_suc timecard2_popup register_suc_scrol">
                <button
                  type="button"
                  className="btn_copytime"
                  onClick={this.handleCopyYesterdaysTimes(
                    this.state.DayTimeCopyIndex
                  )}
                >
                  <span>Copy Yesterdays Times</span>
                </button>

                <div className="clear20"></div>

                <div className="col-sm-12 profile_setting_pop">
                  <form className="form-horizontal" action="/action_page.php">
                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="Pay As"
                      >
                        Pay As
                      </label>
                      <div className="col-sm-8">
                        <select
                          className="form-control pro_input_pop"
                          name="DayTimePayas"
                          value={this.state.DayTimePayas}
                          onChange={this.handleChangeDayTime}
                        >
                          <option value="0">Select</option>
                          {this.renderPayasDropdown()}
                        </select>
                      </div>
                    </div>
                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="Last Name"
                      >
                        Date
                      </label>
                      <div
                        className="col-sm-4 period_ending_popup3"
                        style={{ textAlign: "left" }}
                      >
                        <DatePicker
                          allowClear={false}
                          className="calendarDayTimeDateWeb"
                          value={this.state.DayTimeDateMoment}
                          format={this.state.dateFormat}
                          onChange={this.handleDayTimeDate.bind(this)}
                          disabled
                        />
                      </div>
                      <div className="col-sm-2 time_card_popup3">
                        <a
                          href="javascript:void(0)"
                          onClick={this.handleCalendar(
                            ".calendarDayTimeDateWeb"
                          )}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="2936.352 349.176 18.501 23.145"
                          >
                            <path
                              id="ic_date_range_24px"
                              className="cls-1"
                              d="M9.167,12.415H7.111V14.73H9.167Zm4.111,0H11.223V14.73h2.056Zm4.111,0H15.334V14.73H17.39Zm2.056-8.1H18.418V2H16.362V4.314H8.139V2H6.084V4.314H5.056A2.188,2.188,0,0,0,3.01,6.629L3,22.83a2.2,2.2,0,0,0,2.056,2.314h14.39A2.2,2.2,0,0,0,21.5,22.83V6.629A2.2,2.2,0,0,0,19.446,4.314Zm0,18.516H5.056V10.1h14.39Z"
                              transform="translate(2933.352 347.176)"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="control-label col-sm-4" htmlFor="Email">
                        Day
                      </label>
                      <div
                        className="col-sm-8 text-left"
                        style={{ paddingTop: "7px" }}
                      >
                        {this.state.DayTimeDay}
                      </div>
                    </div>
                    {!this.state.TravelToHide ? (
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          htmlFor="Mobile"
                        >
                          Travel To
                        </label>
                        <div className="col-sm-3" style={{ textAlign: "left" }}>
                          {this.state.TimeOption === "DropList" ? (
                            <TimePicker
                              allowClear={false}
                              minuteStep={
                                this.state.minuteStep
                                  ? this.state.minuteStep
                                  : 1
                              }
                              inputReadOnly={this.state.inputReadOnly}
                              showNow={this.state.showNow}
                              allowEmpty={this.state.allowEmpty}
                              value={this.state.DayTimeTravel2}
                              format={this.state.format}
                              onChange={this.handleChangeDayTimeTravel2.bind(
                                this
                              )}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control pro_input_pop custom-time-field"
                              value={
                                this.state.DayTimeTravel2Type == "00:00"
                                  ? ""
                                  : this.state.DayTimeTravel2Type
                              }
                              placeholder="00:00"
                              onChange={this.handleChangeDayTimeType("Travel2")}
                              onBlur={this.updateTimeType("Travel2")}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="Mobile"
                      >
                        Start Work
                      </label>
                      <div className="col-sm-3" style={{ textAlign: "left" }}>
                        {this.state.TimeOption === "DropList" ? (
                          <TimePicker
                            allowClear={false}
                            minuteStep={
                              this.state.minuteStep ? this.state.minuteStep : 1
                            }
                            inputReadOnly={this.state.inputReadOnly}
                            showNow={this.state.showNow}
                            allowEmpty={this.state.allowEmpty}
                            value={this.state.DayTimeStart}
                            format={this.state.format}
                            onChange={this.handleChangeDayTimeStart.bind(this)}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control pro_input_pop custom-time-field"
                            value={
                              this.state.DayTimeStartType == "00:00"
                                ? ""
                                : this.state.DayTimeStartType
                            }
                            placeholder="00:00"
                            onChange={this.handleChangeDayTimeType("Start")}
                            onBlur={this.updateTimeType("Start")}
                          />
                        )}
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="Mobile"
                      >
                        Meal Break
                      </label>
                      <div className="col-sm-3">
                        {this.state.TimeOption === "DropList" ? (
                          <TimePicker
                            allowClear={false}
                            minuteStep={
                              this.state.minuteStep ? this.state.minuteStep : 1
                            }
                            inputReadOnly={this.state.inputReadOnly}
                            showNow={this.state.showNow}
                            showNow={this.state.showNow}
                            allowEmpty={this.state.allowEmpty}
                            value={this.state.DayTimeTotalMBDed}
                            format={this.state.format}
                            onChange={this.handleChangeDayTimeTotalMBDed.bind(
                              this
                            )}
                            disabled={
                              this.state.TotalMBDedDisabled ||
                              this.state.allowMealBreak
                            }
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control pro_input_pop custom-time-field"
                            value={
                              this.state.DayTimeTotalMBDedType == "00:00"
                                ? ""
                                : this.state.DayTimeTotalMBDedType
                            }
                            placeholder="00:00"
                            onChange={this.handleChangeDayTimeType(
                              "TotalMBDed"
                            )}
                            onBlur={this.updateTimeType("TotalMBDed")}
                            disabled={
                              this.state.TotalMBDedDisabled ||
                              this.state.allowMealBreak
                            }
                          />
                        )}
                      </div>

                      <div className="col-sm-2 p0">
                        <div
                          className="panel-group"
                          id="accordion"
                          role="tablist"
                          aria-multiselectable="true"
                          style={{ width: "37px" }}
                        >
                          <div
                            className="panel panel-default"
                            style={{ boxShadow: "none", height: "34px" }}
                          >
                            <span
                              className="side-tab"
                              data-target="#tab2"
                              data-toggle="tab"
                              role="tab"
                              aria-expanded="false"
                            >
                              <div
                                className="panel-heading"
                                role="tab"
                                id="headingTwo"
                                data-toggle="collapse"
                                data-parent="#accordion"
                                href="#collapseTwo"
                                aria-expanded="false"
                                aria-controls="collapseTwo"
                                style={{ paddingTop: "7px" }}
                              >
                                <h4 className="panel-title collapsed">
                                  <img
                                    src="images/popup_down.png"
                                    id="chevronUp"
                                    style={{
                                      marginLeft: "-3px",
                                      marginTop: "2px",
                                    }}
                                    width="10"
                                    alt=""
                                  />
                                  <img
                                    src="images/popup_top.png"
                                    id="chevronDown"
                                    style={{
                                      marginLeft: "-3px",
                                      marginTop: "2px",
                                      display: "none",
                                    }}
                                    width="10"
                                    alt=""
                                  />
                                </h4>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      id="collapseTwo"
                      className="panel-collapse collapse box_pop_time5"
                      role="tabpanel"
                      aria-labelledby="headingTwo"
                    >
                      <div className="panel-body">
                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            htmlFor="Mobile"
                          >
                            Start MB1
                          </label>
                          <div
                            className="col-sm-4"
                            style={{ textAlign: "left" }}
                          >
                            {this.state.TimeOption === "DropList" ? (
                              <TimePicker
                                allowClear={false}
                                minuteStep={
                                  this.state.minuteStep
                                    ? this.state.minuteStep
                                    : 1
                                }
                                inputReadOnly={this.state.inputReadOnly}
                                showNow={this.state.showNow}
                                allowEmpty={this.state.allowEmpty}
                                value={this.state.DayTimeStartMeal}
                                format={this.state.format}
                                onChange={this.handleChangeDayTimeStartMeal.bind(
                                  this
                                )}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control pro_input_pop custom-time-field"
                                value={
                                  this.state.DayTimeStartMealType == "00:00"
                                    ? ""
                                    : this.state.DayTimeStartMealType
                                }
                                placeholder="00:00"
                                onChange={this.handleChangeDayTimeType(
                                  "StartMeal"
                                )}
                                onBlur={this.updateTimeType("StartMeal")}
                              />
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            htmlFor="Mobile"
                          >
                            Finish MB1
                          </label>
                          <div
                            className="col-sm-4"
                            style={{ textAlign: "left" }}
                          >
                            {this.state.TimeOption === "DropList" ? (
                              <TimePicker
                                allowClear={false}
                                minuteStep={
                                  this.state.minuteStep
                                    ? this.state.minuteStep
                                    : 1
                                }
                                inputReadOnly={this.state.inputReadOnly}
                                showNow={this.state.showNow}
                                allowEmpty={this.state.allowEmpty}
                                value={this.state.DayTimeFinishMeal1}
                                format={this.state.format}
                                onChange={this.handleChangeDayTimeFinishMeal1.bind(
                                  this
                                )}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control pro_input_pop custom-time-field"
                                value={
                                  this.state.DayTimeFinishMeal1Type == "00:00"
                                    ? ""
                                    : this.state.DayTimeFinishMeal1Type
                                }
                                placeholder="00:00"
                                onChange={this.handleChangeDayTimeType(
                                  "FinishMeal1"
                                )}
                                onBlur={this.updateTimeType("FinishMeal1")}
                              />
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            htmlFor="Mobile"
                          >
                            Start MB2
                          </label>
                          <div
                            className="col-sm-4"
                            style={{ textAlign: "left" }}
                          >
                            {this.state.TimeOption === "DropList" ? (
                              <TimePicker
                                allowClear={false}
                                minuteStep={
                                  this.state.minuteStep
                                    ? this.state.minuteStep
                                    : 1
                                }
                                inputReadOnly={this.state.inputReadOnly}
                                showNow={this.state.showNow}
                                allowEmpty={this.state.allowEmpty}
                                value={this.state.DayTimeStartMeal2}
                                format={this.state.format}
                                onChange={this.handleChangeDayTimeStartMeal2.bind(
                                  this
                                )}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control pro_input_pop custom-time-field"
                                value={
                                  this.state.DayTimeStartMeal2Type == "00:00"
                                    ? ""
                                    : this.state.DayTimeStartMeal2Type
                                }
                                placeholder="00:00"
                                onChange={this.handleChangeDayTimeType(
                                  "StartMeal2"
                                )}
                                onBlur={this.updateTimeType("StartMeal2")}
                              />
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            htmlFor="Mobile"
                          >
                            Finish MB2
                          </label>
                          <div
                            className="col-sm-4"
                            style={{ textAlign: "left" }}
                          >
                            {this.state.TimeOption === "DropList" ? (
                              <TimePicker
                                allowClear={false}
                                minuteStep={
                                  this.state.minuteStep
                                    ? this.state.minuteStep
                                    : 1
                                }
                                inputReadOnly={this.state.inputReadOnly}
                                showNow={this.state.showNow}
                                allowEmpty={this.state.allowEmpty}
                                value={this.state.DayTimeFinishMeal2}
                                format={this.state.format}
                                onChange={this.handleChangeDayTimeFinishMeal2.bind(
                                  this
                                )}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control pro_input_pop custom-time-field"
                                value={
                                  this.state.DayTimeFinishMeal2Type == "00:00"
                                    ? ""
                                    : this.state.DayTimeFinishMeal2Type
                                }
                                placeholder="00:00"
                                onChange={this.handleChangeDayTimeType(
                                  "FinishMeal2"
                                )}
                                onBlur={this.updateTimeType("FinishMeal2")}
                              />
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            htmlFor="Mobile"
                          >
                            Start MB3
                          </label>
                          <div
                            className="col-sm-4"
                            style={{ textAlign: "left" }}
                          >
                            {this.state.TimeOption === "DropList" ? (
                              <TimePicker
                                allowClear={false}
                                minuteStep={
                                  this.state.minuteStep
                                    ? this.state.minuteStep
                                    : 1
                                }
                                inputReadOnly={this.state.inputReadOnly}
                                showNow={this.state.showNow}
                                allowEmpty={this.state.allowEmpty}
                                value={this.state.DayTimeStartMeal3}
                                format={this.state.format}
                                onChange={this.handleChangeDayTimeStartMeal3.bind(
                                  this
                                )}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control pro_input_pop custom-time-field"
                                value={
                                  this.state.DayTimeStartMeal3Type == "00:00"
                                    ? ""
                                    : this.state.DayTimeStartMeal3Type
                                }
                                placeholder="00:00"
                                onChange={this.handleChangeDayTimeType(
                                  "StartMeal3"
                                )}
                                onBlur={this.updateTimeType("StartMeal3")}
                              />
                            )}
                          </div>
                        </div>
                        <div className="form-group">
                          <label
                            className="control-label col-sm-4"
                            htmlFor="Mobile"
                          >
                            Finish MB3
                          </label>
                          <div
                            className="col-sm-4"
                            style={{ textAlign: "left" }}
                          >
                            {this.state.TimeOption === "DropList" ? (
                              <TimePicker
                                allowClear={false}
                                minuteStep={
                                  this.state.minuteStep
                                    ? this.state.minuteStep
                                    : 1
                                }
                                inputReadOnly={this.state.inputReadOnly}
                                showNow={this.state.showNow}
                                allowEmpty={this.state.allowEmpty}
                                value={this.state.DayTimeFinishMeal3}
                                format={this.state.format}
                                onChange={this.handleChangeDayTimeFinishMeal3.bind(
                                  this
                                )}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control pro_input_pop custom-time-field"
                                value={
                                  this.state.DayTimeFinishMeal3Type == "00:00"
                                    ? ""
                                    : this.state.DayTimeFinishMeal3Type
                                }
                                placeholder="00:00"
                                onChange={this.handleChangeDayTimeType(
                                  "FinishMeal3"
                                )}
                                onBlur={this.updateTimeType("FinishMeal3")}
                              />
                            )}
                          </div>
                        </div>

                        <div className="form-group">
                          <label
                            className="control-label col-sm-7"
                            htmlFor="Mobile"
                          >
                            Non Deductible MB1:
                          </label>
                          <div className="col-sm-1 checkbox_popuptime">
                            <input
                              type="checkbox"
                              name="DayTimeMB1ND"
                              onChange={this.handleChangeDayTime}
                              checked={this.state.DayTimeMB1ND}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label
                            className="control-label col-sm-7"
                            htmlFor="Mobile"
                          >
                            Non Deductible MB2:
                          </label>
                          <div className="col-sm-1 checkbox_popuptime">
                            <input
                              type="checkbox"
                              name="DayTimeMB2ND"
                              onChange={this.handleChangeDayTime}
                              checked={this.state.DayTimeMB2ND}
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label
                            className="control-label col-sm-7"
                            htmlFor="Mobile"
                          >
                            Non Deductible MB3:
                          </label>
                          <div className="col-sm-1 checkbox_popuptime">
                            <input
                              type="checkbox"
                              name="DayTimeMB3ND"
                              onChange={this.handleChangeDayTime}
                              checked={this.state.DayTimeMB3ND}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="Mobile"
                      >
                        Finish Work
                      </label>
                      <div className="col-sm-3" style={{ textAlign: "left" }}>
                        {this.state.TimeOption === "DropList" ? (
                          <TimePicker
                            allowClear={false}
                            minuteStep={
                              this.state.minuteStep ? this.state.minuteStep : 1
                            }
                            inputReadOnly={this.state.inputReadOnly}
                            showNow={this.state.showNow}
                            allowEmpty={this.state.allowEmpty}
                            value={this.state.DayTimeFinish}
                            format={this.state.format}
                            onChange={this.handleChangeDayTimeFinish.bind(this)}
                          />
                        ) : (
                          <input
                            type="text"
                            className="form-control pro_input_pop custom-time-field"
                            value={
                              this.state.DayTimeFinishType == "00:00"
                                ? ""
                                : this.state.DayTimeFinishType
                            }
                            placeholder="00:00"
                            onChange={this.handleChangeDayTimeType("Finish")}
                            onBlur={this.updateTimeType("Finish")}
                          />
                        )}
                      </div>
                    </div>
                    {!this.state.TravelFromHide ? (
                      <div className="form-group">
                        <label
                          className="control-label col-sm-4"
                          htmlFor="Mobile"
                        >
                          Travel From
                        </label>
                        <div className="col-sm-3" style={{ textAlign: "left" }}>
                          {this.state.TimeOption === "DropList" ? (
                            <TimePicker
                              allowClear={false}
                              minuteStep={
                                this.state.minuteStep
                                  ? this.state.minuteStep
                                  : 1
                              }
                              inputReadOnly={this.state.inputReadOnly}
                              showNow={this.state.showNow}
                              allowEmpty={this.state.allowEmpty}
                              value={this.state.DayTimeTravel1}
                              format={this.state.format}
                              onChange={this.handleChangeDayTimeTravel1.bind(
                                this
                              )}
                            />
                          ) : (
                            <input
                              type="text"
                              className="form-control pro_input_pop custom-time-field"
                              value={
                                this.state.DayTimeTravel1Type == "00:00"
                                  ? ""
                                  : this.state.DayTimeTravel1Type
                              }
                              placeholder="00:00"
                              onChange={this.handleChangeDayTimeType("Travel1")}
                              onBlur={this.updateTimeType("Travel1")}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="Mobile"
                      >
                        Total Hours
                      </label>
                      <div
                        className="col-sm-8 text-left"
                        style={{ paddingLeft: "27px", paddingTop: "7px" }}
                      >
                        {this.state.DayTimeTotalHours}
                      </div>
                    </div>

                    <div className="form-group">
                      <label
                        className="control-label col-sm-4"
                        htmlFor="Mobile"
                      >
                        Note
                      </label>
                      <div className="col-sm-8">
                        <textarea
                          name="DayTimeNote"
                          className="form-control pro_input_pop"
                          rows=""
                          onChange={this.handleChangeDayTime}
                          value={
                            this.state.DayTimeNote ? this.state.DayTimeNote : ""
                          }
                        ></textarea>
                      </div>
                    </div>

                    {this.state.TimesCustomTimeFields.length > 0
                      ? this.renderCustomTimeFields("web")
                      : ""}

                    <div className="clear20"></div>
                    <div className="btn_cance_save">
                      <input
                        name=""
                        className="btn_save_pro"
                        value="Save"
                        type="button"
                        onClick={this.handleSaveDayTime(
                          this.state.DayTimeIndex,
                          "Add"
                        )}
                      />
                      <input
                        name=""
                        className="btn_cancel_pro"
                        data-dismiss="modal"
                        aria-label="Close"
                        value="Cancel"
                        type="button"
                      />
                    </div>
                  </form>

                  <div className="btn_cance_save2">
                    <input
                      name=""
                      type="button"
                      className="btn_save_pro"
                      value="Save"
                    />
                    <input
                      name=""
                      type="button"
                      className="btn_cancel_pro"
                      value="Cancel"
                    />
                  </div>
                </div>
                <div className="clear10"></div>
                <span
                  className="doc_file_error"
                  style={{ position: "initial" }}
                >
                  {this.state.SaveDayTimeError
                    ? this.state.SaveDayTimeError
                    : ""}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/*WeekTimesModalCenter END*/}

        {/*SubmitSuccessModal Start*/}
        <div
          className="modal fade"
          id="SubmitSuccessModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog-centered modla_submit_timesheet"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header modal_header_register">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  style={{ display: "none" }}
                  id="SubmitSuccessModalClose"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body register_suc register_popup">
                <img
                  src="images/ic_check_circle_24px.svg"
                  width="47"
                  height="47"
                  alt=""
                />
                <div className="clearfix"></div>

                <h2> You have submitted the Timesheet successfully</h2>

                <div className="clear2"></div>
                <a
                  href="javascript:void(0)"
                  onClick={this.handleSubmitSuccessModal}
                  className="btn_ok_reg"
                >
                  OK
                </a>
              </div>
            </div>
          </div>
        </div>
        {/*SubmitSuccessModal End*/}

        {/*modal-fullscreen Start*/}
        <div
          className="modal fade modal-fullscreen"
          id="modal-fullscreen"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content box_shadow-none_pdf">
              <div className="modal-header" style={{ borderBottom: "none" }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                  style={{ fontSize: "50" }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body" id="pdf-content"></div>
            </div>
          </div>
        </div>
        {/*modal-fullscreen End*/}
        <button
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#SubmitSuccessModal"
          id="SubmitSuccessModalBtn"
          style={{ display: "none" }}
        >
          Show Modal
        </button>
        <button
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#WeekTimesModalCenter"
          id="WeekTimesModalCenterBtn"
          style={{ display: "none" }}
        >
          Show Modal
        </button>
        <button
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#exampleModalDelete"
          id="exampleModalDeleteBtn"
          style={{ display: "none" }}
        >
          Delete Modal
        </button>
        <button
          data-dismiss="modal"
          data-toggle="modal"
          data-target="#exampleModalDeleteClose"
          id="exampleModalDeleteCloseBtn"
          style={{ display: "none" }}
        >
          Close Delete Modal
        </button>
        <input
          name=""
          className="btn_prview_time"
          value="Preview"
          type="button"
          data-toggle="modal"
          data-target="#modal-fullscreen"
          data-backdrop="static"
          data-keyboard="false"
          id="modalFullScreenBtn"
          style={{ display: "none" }}
        />
      </div>
    );
  }
}
