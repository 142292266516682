import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from '@sentry/browser'
import App from "./App";
//import * as serviceWorker from './serviceWorker';
import "./index.css";
import { Auth } from "aws-amplify";
import Amplify from "aws-amplify";
//import config from "./config";
//import awsmobile from './aws-exports';
//Amplify.configure(awsmobile);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: process.env.REACT_APP_RedirectSignIn,
      redirectSignOut: process.env.REACT_APP_RedirectSignOut,
      responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
    federationTarget: "COGNITO_USER_POOLS"
  },
  Storage: {
    region: process.env.REACT_APP_S3_REGION,
    bucket: process.env.REACT_APP_S3_BUCKET
  },
  API: {
    endpoints: [
      {
        name: "freelancer-portal",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION
      }
    ]
  }
});

Sentry.init({dsn: "https://28e09296517547f5b3d0ecc8e301740c@sentry.io/1795748"});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();
