import React, { Component, Fragment } from "react";
import Routes from "./Routes";
import { Auth, API } from "aws-amplify";
import { withRouter } from "react-router-dom";
//default imports...
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class App extends Component {
	constructor(props)
	{
		super(props);
		this.state = {
			isAuthenticated: false,
			isAuthenticating: true,
      		waitingWorker: {},
		};
	}

	onServiceWorkerUpdate = (registration) => {
	    //const { enqueueSnackbar } = this.props;
	    this.setState({
	      waitingWorker: registration && registration.waiting
	    },()=>{
	      toast.success(this.refreshAction(), {
	        position: "bottom-left",
	        autoClose: false,
	        hideProgressBar: true,
	        closeOnClick: false,
	        pauseOnHover: true,
	        draggable: false,
	        progress: undefined,
	        toastId: 'sw-msg-01'
	      });
	    });
	};

	updateServiceWorker = () => {
	    const { waitingWorker } = this.state;
	    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
	    window.location.reload();
	};

	refreshAction = (key) => { //render the snackbar button
	    return (
	      <Fragment>
	        <div className="sw-msg"><i className="fas fa-check-circle"></i> A new version was released
	          <button className="sw-refresh-btn" onClick={this.updateServiceWorker}>
	            {"refresh"}
	          </button>
	        </div>
	      </Fragment>
	    );
	};
	
	async componentDidMount(){
		if (process.env.NODE_ENV === 'production') {
    		serviceWorkerRegistration.register({ onUpdate: this.onServiceWorkerUpdate });
		}

		try{
			if (await Auth.currentSession()){
				this.userHasAuthenticated(true);
			}
		}catch(e){
			if (e !== 'No current user'){
				alert(e);
			}
		}
		this.setState({ isAuthenticating: false });
	}
	
	userHasAuthenticated = authenticated => {
		this.setState({ isAuthenticated: authenticated });
	}

	postRequest = async (path, body) => {
        const myInit = {
        	headers: { 
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
            body: body
        }
        return API.post("freelancer-portal", path, myInit);
    }

	getRequest = async (path, body) => {
        const myInit = {
        	headers: { 
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
            body: body
        }
        return API.get("freelancer-portal", path, myInit);
    }
	
	render(){
		//Analytics.record('appRender');
		const childProps = {
			isAuthenticated: this.state.isAuthenticated,
			userHasAuthenticated: this.userHasAuthenticated,
			getRequest: this.getRequest,
			postRequest: this.postRequest
		};

		return (
			!this.state.isAuthenticating &&
			<Fragment>
                <Routes childProps={childProps} />
                <ToastContainer />
            </Fragment>
		);
	}
}
export default withRouter(App);